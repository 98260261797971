import {
    IonButton,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { eye, eyeOff } from 'ionicons/icons';
import { useContext, useEffect, useState } from "react";
import { color } from "style-value-types";
import { resetPassword } from '../api/index';
import { AuthContext } from "../contexts/AuthContext";

export const ForgotPasswordAccess = () => {

    const { user, setUser } = useContext(AuthContext);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(user.forgot_password);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [isConfirmPasswordTouched, setIsConfirmPasswordTouched] = useState(false);
    const [message, setMessage] = useState("");
    const [step, setStep] = useState(1);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        setMessage("");
        setNewPassword("");
        setConfirmPassword("");
        setPasswordMatch(true);
        setIsConfirmPasswordTouched(false);
        setStep(1);
    }, [showResetPasswordModal]);

    const closeResetPasswordModal = () => {
        setShowResetPasswordModal(false);
    };

    const handleConfirmPasswordChange = (value) => {
        setConfirmPassword(value);
        setIsConfirmPasswordTouched(true);
        setPasswordMatch(newPassword === value && newPassword !== "");
    };

    const handleSubmit = async () => {
        if (newPassword.length < 8 || confirmPassword.length < 8) {
            setMessage("La password deve essere lunga almeno 8 caratteri");
            return;
        }
        if (newPassword === confirmPassword && newPassword.length !== 0 && confirmPassword.length !== 0) {
            let result = await resetPassword(user.email, newPassword)
            // Get new token
            let new_token = result.data.New_session_key
            // Update the session_id before closing the modal
            localStorage.setItem('sessionId', new_token); setStep(step + 1)
        }
        else if (newPassword.length === 0 && confirmPassword.length === 0) {
            setMessage("Tutti e due i campi sono vuoti");
        }
        else if (newPassword.length === 0 || confirmPassword.length === 0) {
            setMessage("Un campo e vuoto");
        }
        else {
            setMessage("Le password non combaciano");
        }
    };

    const handleNextStep = () => {
        if (step === 2) {
            handleSubmit();
        }
        else {
            setStep(step + 1);
        }
    };

    const handlePrevStep = () => {
        setStep(step - 1);
    };


    return (
        <IonModal isOpen={showResetPasswordModal} backdrop-dismiss={false}>
            <IonHeader>
                <IonToolbar className={"ion-text-center"}>
                    <IonTitle mode="md">Modifica password</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-text-center">
                <img src="/assets/img/reset_password.png" alt="Reset Password" style={{ width: '150px', height: '200px', objectFit: 'contain' }} />
                {step === 1 && (
                    <>
                        <h2><b>Bentornato su Home-J!</b></h2><br />
                        <p>Clicca su <b>Avanti</b> <br />
                            per impostare<br />la tua nuova password</p>
                        <IonButton color="primary" onClick={handleNextStep}>Avanti</IonButton>
                    </>
                )}
                {step === 2 && (
                    <>
                        <IonItem lines="none" style={{ marginBottom: '10px' }}>
                            <IonLabel><b>Nuova Password</b></IonLabel>
                        </IonItem>
                        <IonItem style={{ display: 'block', margin: 'auto', border: '1px solid #ccc', borderRadius: '10px', marginBottom: '10px' }}>
                            <IonInput
                                type={showNewPassword ? 'text' : 'password'}
                                value={newPassword}
                                onIonChange={(e) => setNewPassword(e.detail.value)}
                                style={{ padding: '10px', width: 'calc(100% - 40px)', border: 'none', borderBottom: '1px solid #ccc' }}
                            />
                            <IonButton slot="end" fill="clear" onClick={() => setShowNewPassword(!showNewPassword)}>
                                <IonIcon icon={showNewPassword ? eyeOff : eye} />
                            </IonButton>
                        </IonItem>
                        <IonItem lines="none" style={{ marginBottom: '10px' }}>
                            <IonLabel><b>Conferma Nuova Password</b></IonLabel>
                        </IonItem>
                        <IonItem style={{ display: 'block', margin: 'auto', border: '1px solid #ccc', borderRadius: '10px', marginBottom: '10px' }}>
                            <IonInput
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onIonChange={(e) => handleConfirmPasswordChange(e.detail.value)}
                                style={{ padding: '10px', width: 'calc(100% - 40px)', border: 'none', borderBottom: '1px solid #ccc' }}
                            />
                            <IonButton slot="end" fill="clear" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                <IonIcon icon={showConfirmPassword ? eyeOff : eye} />
                            </IonButton>
                        </IonItem>
                        {
                            <div style={{ color: "gray", marginTop: "8px" }}>
                                {message}
                            </div>
                        }
                        <IonButton onClick={handlePrevStep} style={{ marginRight: '50px' }}>Indietro</IonButton>
                        <IonButton disabled={!newPassword || !confirmPassword} color="primary" onClick={handleNextStep}>Avanti</IonButton>

                    </>
                )}
                {step === 3 && (
                    <>
                        <h2><b>Complimenti!</b></h2>
                        <p>La tua nuova password <b><br />è stata aggiornata <br />con successo.</b></p>
                        <p><i>Riceverai una mail di conferma<br />Per l'avvenuta modifica.</i></p>
                        <IonButton color="primary" onClick={closeResetPasswordModal} style={{ bottom: '20px', right: '20px' }}>Torna Alla Home</IonButton>
                    </>
                )}
            </IonContent>
        </IonModal>
    );
};
