import {
    IonButton,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { eye, eyeOff } from "ionicons/icons";
import { useContext, useState } from "react";
import { resetPassword } from '../api/index';
import { AuthContext } from "../contexts/AuthContext";

export const FirstTimeAccess = () => {
    const { user, setUser } = useContext(AuthContext);
    const [currentPage, setCurrentPage] = useState(0);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [wrongPassword, setWrongPassword] = useState(false);
    const [shortPassword, setShortPassword] = useState(false);
    const [dontMatch, setDontMatch] = useState(false);
    const [showModal, setShowModal] = useState(user.primo_accesso);
    const [confirmNewPasswordBorderColor, setConfirmNewPasswordBorderColor] = useState("transparent");
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);



    const handleConfirmNewPasswordChange = (value) => {
        setConfirmNewPassword(value);
        setConfirmNewPasswordBorderColor(newPassword === value ? "transparent" : "var(--ion-color-danger)");
    };

    const handleSubmit = async () => {
        let hasValidationError = false;

        if (newPassword.length < 8) {
            setShortPassword(true);
            hasValidationError = true;
        }
        else {
            setShortPassword(false);
        }
        if (newPassword && confirmNewPassword && newPassword !== confirmNewPassword) {
            setDontMatch(true)
            hasValidationError = true
        }
        else {
            setDontMatch(false)
        }
        if (oldPassword !== "%6hf77Gr9") {
            setWrongPassword(true)
        }
        else {
            setWrongPassword(false)
        }

        if (newPassword && confirmNewPassword && newPassword === confirmNewPassword && !hasValidationError) {
            if (oldPassword === "%6hf77Gr9") {
                let result = await resetPassword(user.email, newPassword)
                // Get new token
                let new_token = result.data.New_session_key
                // Update the session_id before closing the modal
                localStorage.setItem('sessionId', new_token);
                setWrongPassword(false);
                setShowModal(false);
            } else {
                setWrongPassword(true);
                hasValidationError = true;
            }
        } else {
            setConfirmNewPasswordBorderColor("var(--ion-color-danger)");
            hasValidationError = true;
        }
        if (hasValidationError) {
            return;
        }
    };

    return (
        <IonModal isOpen={showModal} backdrop-dismiss={false}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Modifica password</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {currentPage === 0 && (
                    <div style={{ textAlign: "center", fontSize: "1.5rem", marginTop: "20px", padding: "10px" }}>
                        <p>Ciao, benvenuto su Home-J!</p>
                        <p>è la prima volta che accedi alla nostra App, quindi ti chiediamo di cambiare la password assegnata.</p>
                        <p>Clicca su "Avanti" e segui i passaggi per aggiornare la password</p>
                    </div>
                )}
                {currentPage >= 1 && (
                    <>
                        <IonItem lines="none" style={{ marginBottom: '10px' }}>
                            <IonLabel><b>Vecchia Password</b></IonLabel>
                        </IonItem>
                        <IonItem style={{ display: 'block', margin: 'auto', border: '1px solid #ccc', borderRadius: '10px', marginBottom: '10px' }}>
                            <IonInput
                                type={showOldPassword ? "text" : "password"}
                                value={oldPassword}
                                onIonChange={(e) => setOldPassword(e.detail.value)}
                                style={{ padding: '10px', width: 'calc(100% - 40px)', border: 'none', borderBottom: '1px solid #ccc' }}

                            />
                            <IonButton slot="end" fill="clear" onClick={() => setShowOldPassword(!showOldPassword)}>
                                <IonIcon icon={showOldPassword ? eyeOff : eye} />
                            </IonButton>
                        </IonItem>

                        {wrongPassword && <p style={{ color: "red", marginTop: "5px", marginLeft: "5px" }}>Password sbagliata</p>}

                        <IonItem lines="none" style={{ marginBottom: '10px' }}>
                            <IonLabel><b>Nuova password</b></IonLabel>
                        </IonItem>
                        <IonItem style={{ display: 'block', margin: 'auto', border: '1px solid #ccc', borderRadius: '10px', marginBottom: '10px' }}>
                            <IonInput
                                type={showNewPassword ? "text" : "password"}
                                value={newPassword}
                                onIonChange={(e) => setNewPassword(e.detail.value)}
                                style={{ padding: '10px', width: 'calc(100% - 40px)', border: 'none', borderBottom: '1px solid #ccc' }}

                            />
                            <IonButton slot="end" fill="clear" onClick={() => setShowNewPassword(!showNewPassword)}>
                                <IonIcon icon={showNewPassword ? eyeOff : eye} />
                            </IonButton>
                        </IonItem>

                        <IonItem lines="none" style={{ marginBottom: '10px' }}>
                            <IonLabel><b>Conferma la nuova password</b></IonLabel>
                        </IonItem>
                        <IonItem style={{ display: 'block', margin: 'auto', border: '1px solid #ccc', borderRadius: '10px', marginBottom: '10px' }}>
                            <IonInput
                                type={showConfirmNewPassword ? "text" : "password"}
                                value={confirmNewPassword}
                                onIonChange={(e) => handleConfirmNewPasswordChange(e.detail.value)}
                                style={{ padding: '10px', width: 'calc(100% - 40px)', border: 'none', borderBottom: '1px solid #ccc' }}
                            />
                            <IonButton slot="end" fill="clear" onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}>
                                <IonIcon icon={showConfirmNewPassword ? eyeOff : eye} />
                            </IonButton>
                        </IonItem>
                        {shortPassword && <p style={{ color: "red", marginTop: "5px", marginLeft: "5px" }}>La nuova password é troppo corta</p>}
                        {dontMatch && <p style={{ color: "red", marginTop: "5px", marginLeft: "5px" }}>Le nuove password non sono uguali.</p>}

                    </>
                )}

            </IonContent>
            <IonFooter>
                <IonToolbar>
                    {currentPage > 0 && (
                        <IonButton onClick={() => setCurrentPage(currentPage - 1)}>Indietro</IonButton>
                    )}
                    {currentPage === 0 && (
                        <IonButton color="primary" slot="end" onClick={() => setCurrentPage(currentPage + 1)}>Avanti</IonButton>
                    )}
                    {currentPage === 1 && (
                        <IonButton color="primary" slot="end" onClick={handleSubmit} disabled={!oldPassword || !newPassword || !confirmNewPassword}>
                            Salva
                        </IonButton>
                    )}
                </IonToolbar>
            </IonFooter>
        </IonModal>
    );
};
