import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";

export const SelectProfile = ({ open = false, onClose }) => {

    const { user } = useContext(AuthContext);
    const [invitation, setInvitation] = useState();
    const { isOpenChangeProfile, setIsOpenChangeProfile } = useContext(AuthContext);

    useEffect(() => {

        setInvitation({ ...JSON.parse(localStorage.getItem("invitation")) });

        if (user?.invitations?.length > 0 && !invitation) {
            if (user.invitations.length === 1) {
                localStorage.setItem("invitation", JSON.stringify(user.invitations[0]));
                setInvitation({ ...user.invitations[0] })
                return;
            }
        }
    }, [open, isOpenChangeProfile]);


    return (

        <IonModal isOpen={open}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Seleziona l'account che vuoi visualizzare</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={onClose}>
                            Chiudi
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                {
                    user?.invitations?.length > 1 &&
                    <>
                        <IonList lines="full">
                            {
                                user?.invitations?.map((i, index) =>

                                    <IonItem key={index} onClick={() => {
                                        localStorage.setItem("invitation", JSON.stringify(i));
                                        window.location.reload()
                                    }}>
                                        <IonLabel>{i?.host?.name}</IonLabel>
                                        {
                                            invitation?.id === i.id &&
                                            <>
                                                <IonIcon icon={checkmarkCircleOutline} slot={'end'}></IonIcon>
                                            </>
                                        }
                                    </IonItem>)
                            }
                        </IonList>
                    </>
                }

            </IonContent>
        </IonModal>

    );
}
