import { IonContent, IonPage } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { api, retrieveSuppliersByCategory } from "../../../api";
import { Header } from "../../../components/Header";
import LocationCard from "../../../components/location-card";
import { AuthContext } from "../../../contexts/AuthContext";
import { brandService } from "../../../services/brandService";

export default function TagsCards({ match }) {


    const residenceId = match.params.residenceId;
    const apartmentId = match.params.apartmentId;
    const tagId = match.params.tagId;

    const [categories, setCategories] = useState(null)

    const { apartment, setApartment } = useContext(AuthContext);

    const session_id = localStorage.getItem('sessionId');

    const history = useHistory();

    useEffect(() => {
        const sessionId = localStorage.getItem('sessionId');

        const url = '/mcd_apartment_api/private/apartment/' + apartmentId + '?session_id=' + sessionId;

        api.get(url, { withCredentials: true })
            .then(({ data: apartment }) => {
                brandService.setData(apartment.residence);
                setApartment(apartment);
            })

    }, []);


    const [suppliers, setSuppliers] = useState(null);


    useEffect(() => {
        const retrieveSuppliers = async () => {
            try {
                const { data: { rows: suppliers } } = await retrieveSuppliersByCategory(tagId);
                setSuppliers(suppliers);
            } catch (err) {
                setSuppliers([]);
                console.log(err);
            }
        };
        retrieveSuppliers();
    }, []);


    return <IonPage>
        <Header />
        <IonContent color="light">

            <div className="p-3">

                <div className="dashboard-grid">
                    {
                        !!suppliers?.length ?
                            suppliers && suppliers.map(({ id, name, image, home_j_description }, i) =>
                                <div onClick={() => history.push("/residence/" + residenceId + "/apartment/" + apartment.id + "/tua-casa/" + tagId + "/supplier/" + id)}>
                                    <LocationCard name={home_j_description}
                                        image={process.env.REACT_APP_API + "/mcd_profile_api/private/image/" + id + "?session_id=" + session_id} />
                                </div>
                            )
                            :
                            <div className={"text-center"}>Nessun fornitore presente.</div>
                    }
                </div>

            </div>

        </IonContent>

    </IonPage>

}
