import { IonContent, IonPage } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { api, retrieveCataloguesBySupplier } from "../../../api";
import { Header } from "../../../components/Header";
import LocationCard from "../../../components/location-card";
import { AuthContext } from "../../../contexts/AuthContext";
import { brandService } from "../../../services/brandService";

export default function SupplierCards({ match }) {

    const apartmentId = match.params.apartmentId;
    const supplierId = match.params.supplierId;
    const { apartment, setApartment } = useContext(AuthContext);
    const session_id = localStorage.getItem('sessionId');

    useEffect(() => {
        const sessionId = localStorage.getItem('sessionId');

        const url = '/mcd_apartment_api/private/apartment/' + apartmentId + '?session_id=' + sessionId;

        api.get(url, { withCredentials: true })
            .then(({ data: apartment }) => {
                brandService.setData(apartment.residence);
                setApartment(apartment);
            })

    }, []);


    const [suppliers, setSuppliers] = useState(null);


    useEffect(() => {
        const retrieveSuppliers = async () => {
            try {
                const { data: { rows: suppliers } } = await retrieveCataloguesBySupplier(supplierId);
                setSuppliers(suppliers);
            } catch (err) {
                setSuppliers([]);
                console.log(err);
            }
        };
        retrieveSuppliers();
    }, []);


    return <IonPage>
        <Header />
        <IonContent color="light">

            <div className="p-3">
                <div className="dashboard-grid">
                    {
                        !!suppliers?.length ?
                            suppliers && suppliers.map(({ id, name, image }, i) =>

                                <a target={"_blank"} href={process.env.REACT_APP_API + "/mcd_residence_api/private/catalogue/" + id + '?session_id=' + session_id} rel="noreferrer">
                                    <LocationCard name={name} image={image} />
                                </a>
                            )
                            :
                            <div className={"text-center"}>Nessun fornitore presente.</div>
                    }
                </div>
            </div>
        </IonContent>
    </IonPage>

}
