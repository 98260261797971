import { IonContent, IonPage, useIonAlert, useIonViewDidLeave } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { retrieveSurvey, sendSurveyData } from "../api";
import { Header } from "../components/Header";
import { IonBtn } from "../components/IonBtn";
import { IconRange } from "../components/surveyFileds/IconRange";
import { SurveyMatrix } from "../components/surveyFileds/SurveyMatrix";
import { SurveyMultipleChoice } from "../components/surveyFileds/SurveyMultipleChoice";
import { SurveyMultipleChoiceSlider } from "../components/surveyFileds/SurveyMultipleChoiceSlider";
import { SurveyTextBox } from "../components/surveyFileds/SurveyTextBox";

export const Survey = () => {
    const { surveyId, saleOrderId, ticketId } = useParams();
    const [currSlide, setCurrSlide] = useState(0);
    const [present] = useIonAlert();
    const history = useHistory();
    const [survey, setSurvey] = useState(null);
    const [surveyValues, setSurveyValues] = useState([]);
    const [answeredQuestionCount, setAnsweredQuestionCount] = useState(0);
    const [surveyStartTime, setSurveyStartTime] = useState(new Date());
    const [selectedImages, setSelectedImages] = useState({});
    const surveyEndedRef = useRef(false);
    const contentRef = useRef(null);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);


    const LOCAL_STORAGE_KEY = "surveyValues";

    const handleExitSurvey = async () => {

        const surveyEndTime = new Date()
        const duration = Math.round((surveyEndTime - surveyStartTime) / 1000);

        const surveyData = {
            survey_id: parseInt(surveyId),
            answered_question_count: answeredQuestionCount,
            survey_completed: false,
            survey_end_time: surveyEndTime,
            survey_start_time: surveyStartTime,
            duration: duration
        };


        let valueSurvey = { sale_order_id: parseInt(saleOrderId), answers: surveyValues };
        if (ticketId) {
            valueSurvey.ticket_id = parseInt(ticketId);
        }

        valueSurvey.survey_data = surveyData;

        try {
            await sendSurveyData(surveyId, valueSurvey);
            localStorage.removeItem("surveyValues");
        } catch (error) {
            console.error(error);
            localStorage.removeItem("surveyValues");
        }
    };

    useEffect(() => {
        setSurveyValues([]);
        setCurrSlide(0);
        setSurveyStartTime(new Date());
    }, [surveyId, history]);

    useEffect(() => {
        const retrieveAllSurvey = async () => {
            try {
                const { data } = await retrieveSurvey(surveyId, saleOrderId);
                localStorage.removeItem("surveyValues");
                setSurvey(data || null);
            } catch (err) {
                setSurvey(null);
                console.log(err);
            }
        };
        retrieveAllSurvey();
    }, [saleOrderId, surveyId]);

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.scrollToTop(0);
        }
    }, [currSlide]);

    useIonViewDidLeave(() => {
        if (!surveyEndedRef.current) {
            handleExitSurvey();
        }
    }, [answeredQuestionCount, surveyValues]);

    const checkTriggeredQuestions = (answeredQuestionId, answeredValue) => {
        const currentSlideQuestions = survey.groups_question[currSlide]?.questions;

        if (currentSlideQuestions) {
            currentSlideQuestions.forEach((question) => {
                if (
                    question.is_conditional &&
                    question.triggering_question_id === answeredQuestionId &&
                    question.triggering_answer_id === answeredValue
                ) {
                    if (currSlide + 1 < survey.groups_question.length) {
                        setCurrSlide((curr) => curr + 1);
                    }
                }
            });
        }
    };

    const getVisibleQuestions = () => {
        const currentSlideQuestions = survey.groups_question[currSlide]?.questions;

        return currentSlideQuestions?.filter((question) => {
            const isTriggered =
                !question.is_conditional ||
                !question.triggering_question_id ||
                !question.triggering_answer_id ||
                surveyValues[currSlide]?.some(
                    (value) => value.id === question.triggering_question_id && value.value === question.triggering_answer_id
                );

            return !question.is_conditional || isTriggered;
        });
    };

    const endSurvey = async () => {

        setIsSubmitDisabled(true);

        present({
            header: "Grazie",
            message: "Feedback inviato con successo",
            buttons: [
                {
                    text: "Torna alla home",
                    handler: () => {
                        const regex = /\/residence\/surveys\/\d+\/\d+$/;
                        const currentPath = history.location.pathname;

                        if (regex.test(currentPath)) {
                            const newPath = currentPath.replace(regex, "/residence/surveys");
                            const newUrl = window.location.origin + newPath;
                            window.history.replaceState(null, '', newUrl);
                            history.replace(newPath);
                        }
                    }
                },
            ],
            backdropDismiss: false
        });


        if (surveyValues.length === 0) {
            return;
        }

        const surveyEndTime = new Date()
        const duration = Math.round((surveyEndTime - surveyStartTime) / 1000);

        const surveyData = {
            survey_id: parseInt(surveyId),
            answered_question_count: answeredQuestionCount,
            survey_completed: true,
            survey_end_time: surveyEndTime,
            survey_start_time: surveyStartTime,
            duration: duration
        };

        try {
            let valueSurvey = { sale_order_id: parseInt(saleOrderId), answers: surveyValues };
            if (ticketId) {
                valueSurvey.ticket_id = parseInt(ticketId);
            }
            valueSurvey.survey_data = surveyData;

            await sendSurveyData(surveyId, valueSurvey);

            setCurrSlide(0);
            setSurveyValues([]);
            setSurvey(null)



            surveyEndedRef.current = true;
            localStorage.removeItem("surveyValues");
        } catch (error) {
            console.error(error);
            present({
                header: "Errore",
                message: "Impossibile inviare il feedback al server.",
                buttons: ["OK"],
            });
            localStorage.removeItem("surveyValues");
        }
    };

    const saveSurveyValuesToLocalStorage = (values) => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(values));
    };

    const setStepValue = (value) => {
        const updatedSurveyValues = [...surveyValues];
        if (!Array.isArray(updatedSurveyValues[currSlide])) {
            updatedSurveyValues[currSlide] = [];
        }
        let checkExists = updatedSurveyValues[currSlide].findIndex((element) => element.id === value.id);

        if (checkExists === -1) {
            updatedSurveyValues[currSlide].push(value);
            setAnsweredQuestionCount((count) => count + 1);
            checkTriggeredQuestions(value.id, value.value);
            setSelectedImages((prevSelectedImages) => ({
                ...prevSelectedImages,
                [value.id]: value.value,
            }));
        } else {
            updatedSurveyValues[currSlide][checkExists] = value;
        }

        const filteredSurveyValues = updatedSurveyValues.map(slideValues => Array.isArray(slideValues) ? slideValues.filter(value => value !== null) : []);
        setSurveyValues(filteredSurveyValues);
        saveSurveyValuesToLocalStorage(filteredSurveyValues);
    };

    const areRequiredQuestionsAnswered = () => {
        const currentSlideQuestions = survey.groups_question[currSlide]?.questions || [];

        for (const question of currentSlideQuestions) {
            if (question.required) {
                const currentSlideValues = Array.isArray(surveyValues[currSlide]) ? surveyValues[currSlide] : [];
                const answer = currentSlideValues.find((value) => value.id === question.id);

                if (!answer || (typeof answer.value === 'string' && answer.value.trim() === "")) {
                    return false;
                }
            }
        }
        return true;
    };

    return (
        <IonPage>
            <Header defaultHref={"/residence/surveys"} backPath={"/residence/surveys"} />
            <IonContent color="light" ref={contentRef}>
                <div className="p-3">
                    {survey && (
                        <>
                            <div className="rounded-pill position-relative pt-1 bg-dark bg-opacity-10">
                                <div
                                    className="position-absolute bg-warning top-0 left-0 h-100 rounded-pill"
                                    style={{
                                        transition: "width 0.15s",
                                        width: ((currSlide + 1) * (100 / survey.groups_question.length)) + "%",
                                    }}
                                />
                            </div>
                            <div className="text-uppercase text-sm fw-bold text-muted mt-2">
                                Sezione {currSlide + 1} di {survey.groups_question.length}
                            </div>

                            {survey.groups_question.length ? (
                                <>
                                    {survey.groups_question.map((data, i) => (
                                        <div key={i}>
                                            {currSlide === i && (
                                                <>
                                                    <div className="my-3">
                                                        <h5 className="fw-bold">{data.title}</h5>
                                                    </div>
                                                    {getVisibleQuestions().map((question, j) => (
                                                        <div key={j}>
                                                            {question.question_type === "matrix" && (
                                                                <SurveyMatrix {...question} setStepValue={setStepValue} />
                                                            )}
                                                            {question.question_type === "simple_choice" &&
                                                                question.column_nb === 1 &&
                                                                !question.allow_value_image && (
                                                                    <SurveyMultipleChoice
                                                                        id={question.id}
                                                                        title={question.title}
                                                                        answers={question.answers}
                                                                        setStepValue={setStepValue}
                                                                        showImages={survey.groups_question.some(group =>
                                                                            group.questions.some(innerQuestion =>
                                                                                innerQuestion.answers.some(answer => answer.image_answer && answer.image_name)
                                                                            )
                                                                        )}
                                                                    />
                                                                )}
                                                            {question.question_type === "simple_choice" &&
                                                                question.column_nb > 1 &&
                                                                !question.allow_value_image && (
                                                                    <SurveyMultipleChoiceSlider {...question} setStepValue={setStepValue} />
                                                                )}
                                                            {question.question_type === "simple_choice" && question.allow_value_image && (
                                                                <IconRange {...question} setStepValue={setStepValue} />
                                                            )}
                                                            {question.question_type === "text_box" && (
                                                                <SurveyTextBox {...question} setStepValue={setStepValue} />
                                                            )}
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <p className="opacity-75 text-center text-sm">Nessuna survey disponibile</p>
                            )}

                            <div className="d-flex justify-content-between">
                                <IonBtn
                                    disabled={currSlide === 0}
                                    color="light"
                                    onClick={() => setCurrSlide((curr) => curr - 1)}
                                >
                                    Indietro
                                </IonBtn>
                                {currSlide + 1 === survey.groups_question.length ? (
                                    <IonBtn
                                        onClick={endSurvey}
                                        color={"primary"}
                                        disabled={!areRequiredQuestionsAnswered() || isSubmitDisabled}
                                    >
                                        Invia feedback
                                    </IonBtn>
                                ) : (
                                    <IonBtn
                                        color="dark"
                                        disabled={!areRequiredQuestionsAnswered()}
                                        onClick={() => setCurrSlide((curr) => curr + 1)}
                                    >
                                        Avanti
                                    </IonBtn>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </IonContent>
        </IonPage>
    );
};
