import { Browser } from "@capacitor/browser";
import { motion } from "framer-motion";
import { useState } from "react";

export const Collapsable = ({ title, content, documentUrl, loading, openedDocument, canSingDocument }) => {

    const [open, setOpen] = useState(false);
    const viewDocument = async () => {
        await Browser.open({ url: 'https://docs.google.com/gview?embedded=true&url=' + process.env.REACT_APP_API + documentUrl });
        setOpen(true)
        if (openedDocument) {
            openedDocument(true);
        }
    }
    return (

        <div
            className={"my-2 w-100 text-left overflow-hidden " + (documentUrl ? "bg-light px-3 py-2" : "btn btn-light cursor-pointer")}>
            <div className={"d-flex align-items-center justify-content-between"}>
                <div className="pe-3">
                    <span className={"font-weight-bold"}>{title}</span>
                </div>
                {
                    documentUrl &&
                    <div>
                        <div>

                            {
                                <button onClick={() => viewDocument()} className={"btn btn-dark"}>
                                    {open ? "Apri" : "Apri"}
                                </button>

                            }
                        </div>
                    </div>
                }
            </div>
            {canSingDocument && <>
                <motion.div
                    className={"overflow-hidden"}
                    key="content"
                    initial="collapsed"
                    animate={open ? "open" : "collapsed"}
                    variants={{
                        open: { opacity: 1, height: "auto", transition: { duration: 0.3 } },
                        collapsed: { opacity: 0, height: 0, transition: { duration: 0.3 } }
                    }}>
                    {
                        documentUrl ?
                            <div className={"mt-3"}>{content}</div>
                            :
                            <div className={"mt-2"} dangerouslySetInnerHTML={{ __html: content }} />

                    }
                </motion.div>
            </>}
        </div>
    )
}
