import { useEffect, useState } from "react";
import { HomeIcon } from "./IconRange";

export const SurveyMultipleChoiceSlider = ({
    id,
    title,
    answers,
    setStepValue
}) => {
    const [val, setVal] = useState(null);
    const [active, setActive] = useState(null);

    useEffect(() => {
        const storedValues = JSON.parse(localStorage.getItem("surveyValues")) || [];
        const flattenedValues = storedValues.flat().filter(value => value !== null);
        const storedAnswer = flattenedValues.find((value) => value.id === id);

        if (storedAnswer && storedAnswer.value !== null) {
            const answerIndex = answers.findIndex(answer => answer.id === storedAnswer.value);
            if (answerIndex !== -1) {
                setActive(storedAnswer.value);
                setVal(answers[answerIndex].value);
            }
        }
    }, [id, answers]);

    const changedValue = (index) => {
        const answerId = answers[index].id;
        setActive(answerId);
        setVal(answers[index].value);

        setTimeout(() => {
            setStepValue({ id, value: answerId });
        }, 0);
    };

    return (
        <div className="mb-2 pb-3 border-bottom">
            <span className={"text-md fw-bold"}>{title}</span>
            <div className="rounded bg-white py-3 px-1 shadow-sm mt-2">
                <div className="text-dark text-md fw-bold d-flex justify-content-center">
                    <span>{val || <span className="opacity-50 fw-normal">Nessuna selezione</span>}</span>
                </div>
                <div className="mb-2 pb-3 border-bottom text-center">
                    <div>
                        <div
                            className="d-flex justify-content-around mt-2 pt-1"
                            style={{ color: "#ffc900" }}
                        >
                            {answers.map((answer, index) => (
                                <HomeIcon
                                    key={index}
                                    isDot
                                    onClick={() => changedValue(index)}
                                    active={active !== null && answers.findIndex(a => a.id === active) >= index}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="px-2 mx-1 text-sm d-flex justify-content-between align-items-center opacity-75">
                    <span>{answers[0].value}</span>
                    <span className="ion-text-right">
                        {answers[answers.length - 1].value}
                    </span>
                </div>
            </div>
        </div>
    );
};
