import {
    IonBadge,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
} from '@ionic/react';
import {
    copyOutline, copySharp,
    documentOutline,
    documentSharp,
    informationCircleOutline,
    informationCircleSharp,
    listOutline,
    listSharp,
    logOutOutline,
    logOutSharp,
    notificationsOutline,
    notificationsSharp,
    personOutline,
    personSharp,
    starOutline,
    starSharp
} from 'ionicons/icons';
import { useContext, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { retrieveDocumentsToBeSigned, retrieveNotifications, retrieveSurveys } from "../api";
import { AuthContext } from "../contexts/AuthContext";
import { documentToSignData } from "../services/documentToSignData";
import { notificationsData } from "../services/notificationData";
import { surveyData } from "../services/sharedDataService";
import './Menu.css';


import { useHistory } from 'react-router-dom';

const SideMenu = () => {

    const { user, setUser, setShowTutorial, setCstBrandData } = useContext(AuthContext);
    const [surveysNumber, setSurveysNumber] = useState(0);
    const [documentsNumber, setDocumentsNumber] = useState(0);
    const [notificationsNumber, setNotificationsNumber] = useState(0);
    const [firstLoad, setFirstLoad] = useState(0);
    const location = useLocation();
    const history = useHistory();


    useEffect(() => {
        surveyData.getData().subscribe((number) => {
            setSurveysNumber(number)
        })


        documentToSignData.getData().subscribe((number) => {
            setDocumentsNumber(number)
        })

        notificationsData.getData().subscribe((number) => {
            setNotificationsNumber(number)
        })
        if (firstLoad === 0) {
            retrieveSurveys();
            retrieveDocumentsToBeSigned();
            retrieveNotifications();
            setFirstLoad(1);
        }
    }, [firstLoad, setFirstLoad])


    const logout = () => {
        document.getElementById("cstBrandStyles") && document.getElementById("cstBrandStyles").remove();
        localStorage.removeItem("cstBrand");
        setCstBrandData(null);

        localStorage.removeItem('sessionId');
        setUser({});
    }

    const handleSetResidence = () => {
        history.replace('/residence')
    };

    const appPages = [
        {
            title: 'Le mie residenze',
            url: '/residence',
            iosIcon: listOutline,
            mdIcon: listSharp,
            onClick: handleSetResidence,
        },
        {
            title: 'Il mio profilo',
            url: '/residence/profile',
            iosIcon: personOutline,
            mdIcon: personSharp
        },
        {
            title: 'Faq',
            url: '/residence/faq',
            iosIcon: informationCircleOutline,
            mdIcon: informationCircleSharp
        },
        {
            title: 'Surveys',
            url: '/residence/surveys',
            iosIcon: starOutline,
            mdIcon: starSharp,
            number: true,
            type: 'survey',
            forHost: true,
            removeBlocked: true
        },
        {
            title: 'Documenti da firmare',
            url: '/residence/documents',
            iosIcon: copyOutline,
            mdIcon: copySharp,
            number: true,
            type: 'documentToSign',
            removeBlocked: true
        },
        {
            title: 'Notifiche',
            url: '/residence/notifications',
            iosIcon: notificationsOutline,
            mdIcon: notificationsSharp,
            number: true,
            type: 'notifications',
            removeBlocked: true
        },
        /*{
             title: 'Gestisci ospiti',
             url: '/residence/guests',
             iosIcon: peopleOutline,
             mdIcon: peopleSharp,
             forHost: true,
             removeBlocked: true
         }*/
    ];

    return (
        <IonMenu contentId="main" style={{ "--ion-background-color": "#222222" }}>
            <IonContent forceOverscroll={false}>
                <IonList id="inbox-list">
                    <IonListHeader className="text-white">Home-J</IonListHeader>
                    <IonNote>{user?.email}</IonNote>
                    {appPages.map((appPage, index) => {
                        const guest = JSON.parse(localStorage.getItem('invitation'));
                        if (appPage.forHost && guest) return null;
                        if (appPage.removeBlocked && guest?.guest_blocked) return null;

                        return (
                            <IonMenuToggle key={index} autoHide={false}>
                                <IonItem
                                    color="dark"
                                    className={location.pathname === appPage.url ? 'selected' : ''}
                                    routerLink={appPage.url}
                                    routerDirection="none"
                                    lines="none"
                                    detail={false}
                                    onClick={() => {
                                        if (appPage.onClick) {
                                            appPage.onClick();
                                        }
                                    }}
                                >

                                    <IonIcon color="white" slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                                    <IonLabel className="text-white">{appPage.title}</IonLabel>
                                    {appPage.number && appPage.type === 'survey' && surveysNumber > 0 && (
                                        <IonBadge slot="end" color={'white'}>
                                            {surveysNumber}
                                        </IonBadge>
                                    )}
                                    {appPage.number && appPage.type === 'documentToSign' && documentsNumber > 0 && (
                                        <IonBadge slot="end" color={'white'}>
                                            {documentsNumber}
                                        </IonBadge>
                                    )}
                                    {appPage.number && appPage.type === 'notifications' && notificationsNumber > 0 && (
                                        <IonBadge slot="end" color={'white'}>
                                            {notificationsNumber}
                                        </IonBadge>
                                    )}
                                </IonItem>
                            </IonMenuToggle>
                        );
                    })}
                    <IonItem color="dark" lines="none" detail={false} onClick={() => setShowTutorial(true)}>
                        <IonIcon color="white" slot="start" ios={documentOutline} md={documentSharp} />
                        <IonLabel className="text-white">Tutorial</IonLabel>
                    </IonItem>
                </IonList>

                <IonItem color="dark" lines="none" detail={false} onClick={logout}>
                    <IonIcon color="white" slot="start" ios={logOutOutline} md={logOutSharp} />
                    <IonLabel className="text-white">Logout</IonLabel>
                </IonItem>

            </IonContent>
        </IonMenu>
    );

};

export default SideMenu;
