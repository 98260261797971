import {IonContent, IonPage} from "@ionic/react";
import Faq from "../components/faq";
import {api} from "../api";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../contexts/AuthContext";
import {Header} from "../components/Header";

export const FaqPage = () => {

    const { user } = useContext(AuthContext);
    const [faqs, setFaqs] = useState(null);


    useEffect(() => {
        if (user && (user.id || user.uid)) {
            getFaqs();
        }
    }, [user]);

    const getFaqs = async () => {
        try {
            let session_id = localStorage.getItem('sessionId');
            const url = '/mcd_faq_api/private/faq/';
            const {data: {rows: faqs}} = await api.get(url + '?session_id=' + session_id, {withCredentials: true})
            setFaqs(faqs);
        } catch (err) {
            console.log(err);
        }
    }


    return(
        <IonPage>
            <Header/>
            <IonContent color="light">


                <div className="p-3">
                    <h3>
                        Faq
                    </h3>

                    <Faq faqs={faqs}/>
                </div>
            </IonContent>
        </IonPage>
    )
}
