import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonModal,
    IonPage, IonSelect, IonSelectOption,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import { api } from "../../../api";
import { Collapsable } from "../../../components/Collapsable";
import { Header } from "../../../components/Header";
import { AuthContext } from "../../../contexts/AuthContext";
import { brandService } from "../../../services/brandService";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const Documenti = ({ match }) => {


    const residenceId = match.params.residenceId;
    const apartmentId = match.params.apartmentId;

    const { apartment, setApartment } = useContext(AuthContext)


    useEffect(() => {
        const sessionId = localStorage.getItem('sessionId');

        let invitation = localStorage.getItem('invitation');
        let invitationParams = '';
        if (invitation) {
            invitation = JSON.parse(invitation);
            invitationParams = '&host_id=' + invitation.host.id + '&guest_id=' + invitation.id
        }


        const url = '/mcd_apartment_api/private/apartment/' + apartmentId + '?session_id=' + sessionId + '&exclude_signed_document=1' + invitationParams;

        api.get(url, { withCredentials: true })
            .then(({ data: apartment }) => {
                brandService.setData(apartment.residence);
                setApartment(apartment);
            })

    }, []);

    const [filterableOrders, setFilterableOrders] = useState(null);

    useEffect(() => {
        if (apartment && apartment.sale_order) {

            let filterableOrders = {};
            for (let sale_order of apartment.sale_order) {
                if (sale_order.documents && Object.keys(sale_order.documents).length > 0) {
                    filterableOrders[sale_order.id] = {
                        name: sale_order.home_j_type,
                        so: sale_order.name,
                        show: true
                    }
                }
            }
            setFilterableOrders(filterableOrders);
        }

    }, [apartment]);

    const toggleOrder = (key) => {
        const tmpOrders = { ...filterableOrders };
        tmpOrders[key].show = !tmpOrders[key].show;
        setFilterableOrders(tmpOrders);
    }

    const [orderToShow, setOrderToShow] = useState("all");
    const [openCategory, setOpenCategory] = useState(false)

    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        let saleOrder = params.get('sale_order');
        if (saleOrder) {
            setOrderToShow(saleOrder);
        }
        let categoryToShow = params.get('category');
        if (categoryToShow) {
            setOpenCategory(categoryToShow)
        }
    })
    return (
        <IonPage>
            <Header />
            <IonContent color="light">


                <div className="p-3">

                    <h2 className="mb-2">Documenti</h2>

                    <div className={"mb-5"}>

                        <div className="w-50 pe-2 mb-4">
                            <span className={"text-md fw-bold"}>Fornitura</span>
                            <IonSelect size="small" className="bg-white rounded shadow-sm" placeholder="Seleziona"
                                value={orderToShow} onIonChange={e => setOrderToShow(e.detail.value)}>

                                <IonSelectOption value="all">Tutte</IonSelectOption>
                                {
                                    filterableOrders &&
                                    Object.keys(filterableOrders).map((key, index) =>
                                        <IonSelectOption value={key} key={index}>
                                            {filterableOrders[key].name.trim() === "Extra Furniture" ? "Arredo Extra" : filterableOrders[key].name} {
                                                filterableOrders[key].name.trim() !== "Scelta Finiture" &&
                                                <>
                                                    - {filterableOrders[key].so}
                                                </>
                                            }

                                        </IonSelectOption>
                                    )
                                }
                            </IonSelect>
                        </div>


                        {
                            !!apartment?.sale_order?.length && apartment?.sale_order.map(({
                                id,
                                name,
                                documents,
                                home_j_type
                            }, i) =>
                                <AnimatePresence key={i}>
                                    {
                                        (documents && Object.keys(documents).length > 0 && filterableOrders && ["all", String(id)].indexOf(orderToShow) !== -1) &&
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1, transition: { duration: 0.15 } }}
                                            exit={{ opacity: 0, transition: { duration: 0.15 } }}
                                            key={i} className={"pb-5"}>

                                            <p className={"pb-2 border-bottom"}>Ordine <strong> {home_j_type.trim() === "Extra Furniture" ? "Arredo Extra" : home_j_type} {home_j_type.trim() !== "Scelta Finiture" && <>- {name}</>}</strong>
                                            </p>

                                            {
                                                documents ?
                                                    <div className={"row"}>
                                                        {
                                                            Object.keys(documents).map((document, k) =>

                                                                <DocumentCard key={k}
                                                                    name={documents[document].label === 'Specifiche mobili' ? 'Scelta finiture' : documents[document].label}
                                                                    catalogues={documents[document].category_documents}
                                                                    openCategory={openCategory}
                                                                />
                                                            )

                                                        }
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                        </motion.div>
                                    }
                                </AnimatePresence>
                            )
                        }


                    </div>

                </div>
            </IonContent>
        </IonPage>
    )
}


const DocumentCard = ({ name, image, catalogues, openCategory }) => {

    const [showModal, setShowModal] = useState(false);
    const [automaticallyOpen, setAutomaticallyOpen] = useState(false);
    useEffect(() => {
        if (openCategory && !automaticallyOpen && catalogues[0].category === openCategory) {
            setShowModal(true)
            setAutomaticallyOpen(true)
        }
    })
    return (
        <>
            <div className={"col-6 col-md-4 mb-4"} onClick={() => setShowModal(true)}>
                <div
                    className="d-flex flex-column bg-white rounded border-lg px-3 pb-3 pt-4 cursor-pointer shadow rounded-xl h-100"
                    style={{ height: '100%' }}>
                    <div className={"px-md-4 pt-2"}>
                        <img onError={(e) => {
                            e.target.src = "/assets/img/icons/documenti/altro.png"
                        }}
                            className="max-w-100"
                            width="200"
                            height="150"
                            style={{ objectFit: "contain" }}
                            src={'/assets/img/icons/documenti/' + name + '.png'} />
                    </div>
                    <div
                        className={"d-flex justify-content-center flex-fill align-items-center mt-2 px-3"}>
                        <div
                            className="text-center font-weight-bold">{name}</div>
                    </div>
                </div>
            </div>

            {
                catalogues &&
                <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>


                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>
                                {'Documenti ' + name}
                            </IonTitle>

                            <IonButtons slot="end">
                                <IonButton onClick={() => setShowModal(false)}>
                                    Chiudi
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>

                    {
                        catalogues.map(({ id, name, youtube_video_id }) => (
                            <DocumentViewer key={id} name={name} youtubeId={youtube_video_id}
                                pdfUrl={"/mcd_residence_api/private/document/" + id} />
                        ))
                    }
                </IonModal>

            }
        </>
    )

}

export const DocumentViewer = ({ id, name, pdfUrl, youtubeId }) => {
    const sessionId = localStorage.getItem('sessionId');

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [showDocument, setShowDocument] = useState(false);

    const [loading, setLoading] = useState(false);

    const [activePdf, setActivePdf] = useState();


    useEffect(() => {
        if (pdfUrl && !youtubeId) {
            setLoading(true);
            api.get((pdfUrl + '?session_id=' + sessionId), { withCredentials: true, responseType: "arraybuffer" }, true)
                .then(res => {


                    if (res && res.data) {
                        let pdf = res.data;
                        if (typeof pdf === "string") {
                            pdf = new Blob([pdf], { type: 'application/pdf' });
                        }
                        setActivePdf(pdf);
                        setLoading(false);
                    }
                })
                .catch(err => console.log(err));
        }
    }, [pdfUrl]);


    function onDocumentLoadSuccess({ numPages }) {
        setLoading(false);
        setNumPages(numPages);
    }

    return (
        <div>
            <Collapsable loading={loading}
                documentUrl={youtubeId ? ("https://www.youtube.com/watch?v=" + youtubeId) : (pdfUrl + "?session_id=" + sessionId)}
                title={name} />
        </div>
    )
}
