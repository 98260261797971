import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getUserData, retrieveSurveys } from "../api";

export const AuthContext = React.createContext(null);
export const AuthProvider = ({children}) => {

    const [user, setUser] = useState();

    const [apartment, setApartment] = useState();

    const [showTutorial, setShowTutorial] = useState(false);
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);

    const [cstBrandData, setCstBrandData] = useState();
    const [surveyNumber,setSurveysNumber] = useState(0);
    const history = useHistory();
    const [isOpenChangeProfile, setIsOpenChangeProfile] = useState(false);


    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("cstBrand")) {
            setCstBrandData(JSON.parse(localStorage.getItem("cstBrand")));
        }
    }, []);

    useEffect(() => {
        const storageSessionId = localStorage.getItem('sessionId');
        if (storageSessionId) {
            getUserData(storageSessionId).then(res => setUser(res));
            retrieveSurveys().then((res) => {
                if (res && res.data && res.data.rows && res.data.rows.length > 0) {
                    setSurveysNumber(res.data.rows.length)
                }
            })
        } else {
            setUser({});
        }

    }, [setSurveysNumber]);


    useEffect(() => {
        if (user?.id) {
            if (!user.is_promoter && !user.privacy_accepted) {
                setShowPrivacyModal(true);
            }
        }
    }, [user]);

    useEffect(() => {

        if (cstBrandData) {
            if (!localStorage.getItem("cstBrand")) {
                localStorage.setItem("cstBrand", JSON.stringify(cstBrandData));
            }

            let {app_color_brand: colorToApply} = cstBrandData;
            applyColor(colorToApply);
        } else {
            applyColor();
        }
    }, [cstBrandData]);

    const contextValue = {
        user,
        setUser,
        apartment,
        setApartment,
        showTutorial,
        setShowTutorial,
        showPrivacyModal,
        setShowPrivacyModal,
        cstBrandData,
        setCstBrandData,
        showResetPasswordModal,
        setShowResetPasswordModal,
    }

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
}


export const applyColor = (colorToApply) => {

    if (!colorToApply?.length) colorToApply = " ffc409";
    else if (colorToApply.charAt(0) === "#") colorToApply = colorToApply.substring(1);

    var c = colorToApply;      // strip #
    var rgb = parseInt(c, 16);   // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff;  // extract red
    var g = (rgb >> 8) & 0xff;  // extract green
    var b = (rgb >> 0) & 0xff;  // extract blue

    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    document.head.innerHTML = document.head.innerHTML + "<style id='cstBrandStyles'>" +
        ".border-primary {border-color: #" + colorToApply + " !important;}" +
        ".bg-primary{background-color: #" + colorToApply + " !important;}" +
        (luma < 100 ? ".bg-primary{color: #fff !important;}" : "") +
        ".yellow-curve{background-color: #" + colorToApply + " !important;}" +
        ".text-primary{color: #" + colorToApply + " !important}" +
        "button.button-native{color: #" + colorToApply + " !important}" +
        "ion-badge{background-color: #" + colorToApply + " !important}" +
        ".button-solid{--background: #" + colorToApply + " !important}" +
        "ion-checkbox{" +
        "--background-checked:  #" + colorToApply + " !important;" +
        "--border-color-checked:  #" + colorToApply + " !important;}" +
        ".alert-button{color: #" + colorToApply + " !important}" +
        ".item-has-focus .label-stacked{color: #" + colorToApply + " !important}" +
        ".item-interactive.ion-focused, .item-interactive.item-has-focus" +
        "{--highlight-background: #" + colorToApply + " !important}" +
        "</style>"

}
