import { Capacitor, CapacitorHttp } from "@capacitor/core";
import { isPlatform } from '@ionic/react';
import Axios from "axios";
import { documentToSignData } from "../services/documentToSignData";
import { notificationsData } from "../services/notificationData";
import { surveyData } from "../services/sharedDataService";

export const debug = true;

export const api = Capacitor.isNativePlatform() ? {
    get: async (url, obj, isArrayBuffer) => {
        const options = {
            url: process.env.REACT_APP_API + url,
            headers: {
                Accept: "application/json",
                "Content-Type": " multipart/form-data",
                "Cache-Control": "no-store",
            },
            cache: false
        };

        if (isArrayBuffer) {
            options['responseType'] = 'arraybuffer';
            options.headers.Accept = 'application/pdf';
        }

        const response = await CapacitorHttp.request({ ...options, method: 'GET' })
        if (response.data && typeof response.data === "string" && !isArrayBuffer) {
            response.data = JSON.parse(response.data);
        }
        return response;

    },
    post: async (url, body) => {

        const options = {
            url: process.env.REACT_APP_API + url,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            data: body,
        };

        return await CapacitorHttp.request({ ...options, method: 'POST' })
    },
    put: async (url, body) => {

        const options = {
            url: process.env.REACT_APP_API + url,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            data: body,
        };

        return await CapacitorHttp.request({ ...options, method: 'POST' })
    },
}
    :
    Axios.create({
        baseURL: process.env.REACT_APP_API,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });


export const getUserData = async (sessionId) => {
    try {
        const { data: user } = await api.get('/mcd_profile_api/private/profile/?session_id=' + sessionId, { withCredentials: true })
        if (user?.invitations?.length > 0 && localStorage.getItem('invitation') === null) {
            const invitation = user.invitations[0];
            localStorage.setItem('invitation', JSON.stringify(invitation));
        } else {
            localStorage.removeItem('invitation')
        }
        if (isPlatform('cordova')) {
            window.plugins.OneSignal.setExternalUserId(user.id.toString(), (results) => {
                // The results will contain push and email success statuses
                console.log('Results of setting external user id');
                console.log(results);

                // Push can be expected in almost every situation with a success status, but
                // as a pre-caution its good to verify it exists
                // if (results.push && results.push.success) {
                //     console.log('Results of setting external user id push status:');
                //     console.log(results.push.success);
                // }

                // Verify the email is set or check that the results have an email success status
                // if (results.email && results.email.success) {
                //     console.log('Results of setting external user id email status:');
                //     console.log(results.email.success);
                // }

                // Verify the number is set or check that the results have an sms success status
                // if (results.sms && results.sms.success) {
                //     console.log('Results of setting external user id sms status:');
                //     console.log(results.sms.success);
                // }
            });
        }
        return user;
    } catch (err) {
        console.log('errore!', err);
        localStorage.removeItem('sessionId');
        return {};
    }
}

export const retrievePrivacyPolicy = async () => {
    try {
        let session_id = localStorage.getItem('sessionId');
        const { data: privacy_policy } = await api.get('/mcd_profile_api/private/privacy_policy/?session_id=' + session_id, { withCredentials: true })
        return privacy_policy;
    } catch (err) {
        console.log('errore!', err);
        return {};
    }
}


export const privacyPolicyAccepted = async (user_id) => {
    try {
        let session_id = localStorage.getItem('sessionId');
        const { data: privacy } = await api.put('/mcd_profile_api/private/profile/' + user_id + '?session_id=' + session_id, { policy_accepted: true }, { withCredentials: true })
        return privacy;
    } catch (err) {
        console.log('errore!', err);
        return {};
    }
}

export const retrieveHomeTrackingBySaleOrder = async (saleOrderId) => {

    if (!saleOrderId) return;
    let url = '/mcd_apartment_api/private/home-tracker/' + saleOrderId;
    try {
        let session_id = localStorage.getItem('sessionId');
        return await api.get(url + '?session_id=' + session_id, { withCredentials: true })
    } catch (e) {
        console.log(e)
    }
}

export const createTicket = async (data) => {
    if (!data) return;
    let url = '/mcd_helpdesk_api/private/helpdesk/create';
    try {
        let session_id = localStorage.getItem('sessionId');
        return await api.post(url + '?session_id=' + session_id, data, { withCredentials: true })
    } catch (e) {
        console.log(e)
    }
}

export const createPostPurchaseTicket = async (data) => {
    if (!data) return;
    let url = '/mcd_helpdesk_api/private/v2/helpdesk-post-purchase/create';
    try {
        let session_id = localStorage.getItem('sessionId');
        return await api.post(url + '?session_id=' + session_id, data, { withCredentials: true })
    } catch (e) {
        console.log(e)
    }
}

export const retrieveCategories = async () => {

    let url = '/mcd_residence_api/private/supplier_catalogue/';

    try {
        let session_id = localStorage.getItem('sessionId');
        return await api.get(url + '?session_id=' + session_id, { withCredentials: true })
    } catch (e) {
        console.log(e)
    }
}


export const retrieveSuppliersByCategory = async (tagId) => {

    if (!tagId) return;
    let url = '/mcd_residence_api/private/supplier_catalogue/?tag_id=' + tagId;

    try {
        let session_id = localStorage.getItem('sessionId');
        return await api.get(url + '&session_id=' + session_id, { withCredentials: true })
    } catch (e) {
        console.log(e)
    }
}


export const retrieveCataloguesBySupplier = async (supplierId) => {

    if (!supplierId) return;
    let url = '/mcd_residence_api/private/supplier_catalogue/?supplier_id=' + supplierId;
    try {
        let session_id = localStorage.getItem('sessionId');
        return await api.get(url + '&session_id=' + session_id, { withCredentials: true })
    } catch (e) {
        console.log(e)
    }
}


export const retrieveCapitolati = async (residenceId = false) => {

    if (!residenceId) return;
    let session_id = localStorage.getItem('sessionId');
    let url = '/mcd_residence_api/private/specifications/?residence_id=' + residenceId + '&session_id=' + session_id;

    try {
        return await api.get(url, { withCredentials: true })
    } catch (e) {
        console.log(e)
    }
}

export const retrieveNotifications = async (update = false) => {

    let session_id = localStorage.getItem('sessionId');
    let url = '/mcd_notification_api/private/retrieve/?session_id=' + session_id;
    if (update === true) {
        url += '&update=true';
    }
    try {
        const notifications = await api.get(url, { withCredentials: true })

        let countToRead = 0;
        if (notifications?.data.rows.length > 0 && update === false) {
            countToRead = notifications.data.rows.filter(obj => {
                if (obj.is_visualize === false) {
                    return true;
                }
                return false;
            }).length;
        }
        notificationsData.setData(countToRead)
        return notifications;
    } catch (e) {
        console.log(e)
    }
}

export const retrieveGuests = async () => {

    let session_id = localStorage.getItem('sessionId');
    let url = '/mcd_guest_api/private/guest/?session_id=' + session_id;

    try {
        const guests = await api.get(url, { withCredentials: true })
        return guests;
    } catch (e) {
        console.log(e)
    }
}


export const createGuest = async (formData) => {

    let session_id = localStorage.getItem('sessionId');
    let url = '/mcd_guest_api/private/guest/?session_id=' + session_id;

    try {
        const guest = await api.post(url, formData, { withCredentials: true })
        return guest;
    } catch (e) {
        console.log(e)
    }
}

export const updateGuestCapability = async (formData, capabilityId) => {

    let session_id = localStorage.getItem('sessionId');
    let url = '/mcd_guest_api/private/guest-capability/' + capabilityId + '/?session_id=' + session_id;

    try {
        return await api.post(url, formData, { withCredentials: true });
    } catch (e) {
        console.log(e)
    }
}


export const updateGuestDetail = async (guestId, formData) => {

    let session_id = localStorage.getItem('sessionId');
    let url = '/mcd_guest_api/private/guest/' + guestId + '/?session_id=' + session_id;

    try {
        return await api.put(url, formData, { withCredentials: true });
    } catch (e) {
        console.log(e)
    }
}


export const deleteGuestCapability = async (capabilityId) => {

    let session_id = localStorage.getItem('sessionId');
    let url = '/mcd_guest_api/private/guest-capability/' + capabilityId + '/?session_id=' + session_id;

    try {
        return await api.delete(url, { withCredentials: true });
    } catch (e) {
        console.log(e)
    }
}


export const addApartmentGuestCapability = async (formData) => {

    let session_id = localStorage.getItem('sessionId');
    let url = '/mcd_guest_api/private/guest-capability/?session_id=' + session_id;

    try {
        return await api.post(url, formData, { withCredentials: true });
    } catch (e) {
        console.log(e)
    }
}

export const retrieveDocumentsToBeSigned = async (residenceId = false) => {

    let session_id = localStorage.getItem('sessionId');
    let invitation = localStorage.getItem('invitation');
    let invitationParams = '';
    if (invitation) {
        invitation = JSON.parse(invitation);
        invitationParams = '&host_id=' + invitation.host.id + '&guest_id=' + invitation.id;
    }


    let url = '/mcd_document_api/private/document/?session_id=' + session_id + invitationParams;
    try {
        const documents = await api.get(url, { withCredentials: true })
        documentToSignData.setData(documents?.data.rows.length || 0)
        return documents;
    } catch (e) {
        console.log(e)
    }
}


export const retrieveSurveys = async (residenceId = false) => {

    let session_id = localStorage.getItem('sessionId');
    let url = '/mcd_survey_api/private/retrieve/?session_id=' + session_id;
    try {
        const surveys = await api.get(url, { withCredentials: true })
        let countToAnswer = 0;
        if (surveys?.data.rows.length > 0) {
            countToAnswer = surveys.data.rows.filter(obj => {
                if (obj.sui_id === 0) {
                    return true;
                }
                return false;
            }).length;
        }
        surveyData.setData(countToAnswer)
        return surveys;
    } catch (e) {
        console.log(e)
    }
}

export const retrieveSurvey = async (surveyId, saleOrderId) => {

    let session_id = localStorage.getItem('sessionId');
    let url = '/mcd_survey_api/private/retrieve/' + surveyId + '?session_id=' + session_id + '&so=' + saleOrderId
    try {
        return await api.get(url, { withCredentials: true })
    } catch (e) {
        console.log(e)
    }
}

export const sendSurveyData = async (surveyId, data, ticketId = false) => {

    let session_id = localStorage.getItem('sessionId');
    let url = '/mcd_survey_api/private/retrieve/' + surveyId + '?session_id=' + session_id;
    if (ticketId) {
        url += '&ticket_id=' + ticketId;
    }
    try {
        return await api.put(url, data)
    } catch (e) {
        console.log(e)
    }
}

export const uploadSignature = async (document_id, body) => {

    let session_id = localStorage.getItem('sessionId');
    let url = '/mcd_document_api/private/document/' + document_id + '?session_id=' + session_id;
    try {
        return await api.post(url, body)
    } catch (e) {
        console.log(e)
    }
}

export const retrieveResidenceFromSaleOrder = async (soId) => {

    let session_id = localStorage.getItem('sessionId');
    let url = '/mcd_residence_api/private/sale-order/' + soId + '?session_id=' + session_id;
    try {
        return await api.get(url, { withCredentials: true })
    } catch (e) {
        console.log(e)
    }
}

export const resetPassword = async (email, newPassword) => {
    const body = {
        email: email,
        new_password: newPassword
    };
    let session_id = localStorage.getItem('sessionId');
    const url = '/mcd_password_modification/private/retrieve/?session_id=' + session_id;

    try {
        let result = await api.post(url, body);
        return result
    } catch (error) {
        console.error('Error resetting password:', error);
        throw error;
    }
};


export const forgotPassword = async (userEmail) => {

    const url = '/mcd_password_modification/private/forgot_password'
    const body = {
        email: userEmail
    };

    try {
        let response = await api.post(url, body);
        return response.data.success
    } catch (error) {
        console.error('Error sending request:', error);
        throw error;
    }
}

