import { IonContent, IonPage } from "@ionic/react";
import { useContext } from "react";
import { Header } from "../components/Header";
import { IonBtn } from "../components/IonBtn";
import { AuthContext } from "../contexts/AuthContext";

export const Profile = () => {

    const { user, setShowPrivacyModal, setShowResetPasswordModal } = useContext(AuthContext);

    return (
        <IonPage>
            <Header />
            <IonContent color="light">

                {
                    user &&
                    <div className="p-3">

                        <div className="d-flex align-items-center justify-content-between">
                            <div className="h6 text-uppercase pb-2  text-muted">
                                Profilo
                            </div>
                            <IonBtn
                                color="dark"
                                className="mt-0"
                                style={{ paddingBottom: "8px" }}
                                onClick={() => setShowResetPasswordModal(true)}
                            >
                                Reimposta la password
                            </IonBtn>
                        </div>

                        <div className={"d-grid g-1-1-1-1 border-top"}>
                            <div className={"my-2"}>Nome</div>
                            <div className={"my-2"}>{user.name}</div>
                        </div>
                        <div className={"h6 text-uppercase mt-4 pb-2 border-bottom text-muted"}>Dati Di Residenza</div>
                        <div className={"d-grid g-1-1-1-1"}>
                            <div className={"my-2"}>Indirizzo Residenza</div>
                            <div className={"my-2"}>{user.street}</div>
                            <div className={"my-2"}>Città</div>
                            <div className={"my-2"}>{(user && user.state) && user.state.name}</div>
                            <div className={"my-2"}>Paese</div>
                            <div className={"my-2"}>{(user && user.country) && user.country.name}</div>
                        </div>
                        <div className={"h6 text-uppercase mt-4 pb-2 border-bottom text-muted"}>Contatti</div>
                        <div className={"d-grid g-1-1-1-1"}>
                            <div className={"my-2"}>Telefono</div>
                            <div className={"my-2"}>{user.phone}</div>
                            <div className={"my-2"}>Cellulare</div>
                            <div className={"my-2"}>{user.mobile}</div>
                            <div className={"my-2"}>Indirizzo Email</div>
                            <div className={"my-2"}>{user.email}</div>
                        </div>
                        <IonBtn color="dark" className="mt-3" onClick={() => setShowPrivacyModal(true)}>
                            Informativa privacy
                        </IonBtn>
                    </div>
                }
            </IonContent>
        </IonPage>
    )
}
