import React, {useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "../../../contexts/AuthContext";
import {
    IonAvatar, IonBadge,
    IonButton, IonButtons, IonCheckbox,
    IonCol,
    IonContent,
    IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList,
    IonModal,
    IonPage, IonRow,
    IonSelect,
    IonSelectOption, IonSpinner, IonTextarea,
    IonTitle,
    IonToolbar, useIonAlert
} from "@ionic/react";
import {IonBtn} from "../../../components/IonBtn";
import {
    camera, checkmarkOutline,
    chevronForward,
    createOutline,
    informationCircleOutline, trashOutline,
} from "ionicons/icons";
import {useHistory} from "react-router";
import {api} from "../../../api";
import {Header} from "../../../components/Header";
import {brandService} from "../../../services/brandService";
import { Camera, CameraResultType } from '@capacitor/camera';



export const Comunicazioni = ({match}) => {


    const residenceId = match.params.residenceId;
    const apartmentId = match.params.apartmentId;
    const sessionId = localStorage.getItem('sessionId');

    const {apartment} = useContext(AuthContext);

    const history = useHistory();

    const [tickets, setTickets] = useState(null)
    const [consultants, setConsultants] = useState(null)
    const [capability, setCapability] = useState(false);


    const takePicture = async () => {
        const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: true,
            resultType: CameraResultType.DataUrl
        });

        setNewTicketBase64(image.dataUrl)

    };


    useEffect(() => {
        if (apartmentId) {

            let invitation = localStorage.getItem('invitation');
            if (invitation) {
                invitation = JSON.parse(invitation);
                let capabilityIndex = invitation.capabilities?.findIndex((capability) => capability.apartment_id == apartmentId);
                if (capabilityIndex >= 0) {
                    setCapability({...invitation.capabilities[capabilityIndex]});
                }
            }


            retrieveAllTickets().then(({data}) => {
                const {rows: tickets} = data;
                if (tickets.length > 0) {
                    brandService.setData(tickets[0].residence);
                    setTickets(tickets);
                }
            });

            retrieveAllConsultants().then(data => {
                const {data: {consultants}} = data;
                setConsultants(consultants);
            });
        }
    }, [apartmentId]);

    const retrieveAllTickets = async () => {
        let url = '/mcd_helpdesk_api/private/v2/helpdesk/?apartment_id=' + apartmentId;
        let invitation = localStorage.getItem('invitation');
        let invitationParams = '';
        if (invitation) {
            invitation = JSON.parse(invitation);
            invitationParams = '&host_id=' +  invitation.host.id + '&guest_id=' + invitation.id;
        }
        try {
            return await api.get(url + '&session_id=' + sessionId + invitationParams, {withCredentials: true})
        } catch (e) {
            console.log(e)
            return e;
        }
    };

    const retrieveAllConsultants = async () => {
        let url = '/mcd_apartment_api/private/consultants/?apartment_id=' + apartmentId;
        let invitation = localStorage.getItem('invitation');
        let invitationParams = '';
        if (invitation) {
            invitation = JSON.parse(invitation);
            invitationParams = '&host_id=' +  invitation.host.id + '&guest_id=' + invitation.id;
        }

        try {
            return await api.get(url + '&session_id=' + sessionId + invitationParams, {withCredentials: true})
        } catch (e) {
            console.log(e)
            return e;
        }
    };

    const [saleOrderId, setSaleOrderId] = useState();
    const [consultantId, setConsultantId] = useState();
    const [followers, setFollowers] = useState();
    const [emailCc, setEmailCc] = useState([]);
    const [newEmail, setNewEmail] = useState();
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [creatingTicket, setCreatingTicket] = useState(false);
    const [newTicketBase64, setNewTicketBase64] = useState(null);

    const createTicket = async () => {

        setCreatingTicket(true);

        const body = {
            //team_id: 3,
            residence_id: Number(residenceId),
            real_estate_units_id: Number(apartmentId),
            so_id: saleOrderId,
            user_id: consultantId,
            followers: followers && followers.length > 0 ? followers : null,
            email_cc: emailCc.join(','),
            name,
            description
        }

        if (newTicketBase64) {
            body.files = [newTicketBase64.replace(/^data:image\/[a-z]+;base64,/, "").replace(/^data:application\/[a-z]+;base64,/, "")];
        }

        let url = '/mcd_helpdesk_api/private/helpdesk/create';
        try {
            await api.post(url + '?session_id=' + sessionId, body, {withCredentials: true})

            setShowModal(false);

            retrieveAllTickets().then(data => {
                const {data: {rows: tickets}} = data;
                setTickets(tickets);
            });
        } catch (e) {
            console.log(e)
        } finally {
            setCreatingTicket(false);
        }


    }

    const [showModal, setShowModal] = useState(false);
    const [showConsultantModal, setShowConsultantModal] = useState(false);
    const [showFollowerModal, setShowFollowerModal] = useState(false);
    const [showEmailCcModal, setShowEmailCcModal] = useState(false);

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const handleNewTicketInput = async (e) => {
        setNewTicketBase64(await toBase64(e));
    }
    const newTicketInput = useRef();

    const [present] = useIonAlert();

    const checkChecked = (id) => {
        let status = false
        if (followers && followers.length > 0) {
            for (const follower of followers) {
                if (follower.value === id) {
                    status = true;
                    break;
                }
            }
        }
        return status
    }


    const addRemoveFollower = (e) => {
        let followerToAdd = [];
        const consultant = consultants[e.detail.value]
        if (followers && followers.length > 0) {
            for (const follower of followers) {
                if (follower.value === consultant.id) {
                    continue;
                }
                followerToAdd.push(follower)
            }
        }
        if (e.detail.checked) followerToAdd.push({value: consultant.id, label: consultant.name})
        setFollowers(followerToAdd)
    }


    const addRemoveEmail = (email, index = false) => {
        if (index !== false) {
            let newMail = []
            for (const e of emailCc) {
                if (e === email) continue;
                newMail.push(e)
            }
            setEmailCc([...newMail])
        } else {
            if (emailCc.indexOf(email) <= -1) {
                setEmailCc([...emailCc, email])
            }
        }
    }


    return (

        <IonPage>
            <Header/>
            <IonContent color="light">
                <div className="p-3">

                    <h2 className="mb-3">Comunicazioni</h2>
                    {
                        (!capability || capability.communications === 'all') &&
                            <IonBtn color="dark" expand="block" className={'mb-3'} onClick={() => {
                                setShowModal(true);
                                setConsultantId(false);
                                setFollowers(false)
                            }}>
                                <IonIcon mode="ios" icon={createOutline} className="me-1"/> Nuova comunicazione
                            </IonBtn>
                    }
                    {
                        tickets ?
                            !!tickets.length && tickets.map(({
                                                                 id,
                                                                 name,
                                                                 image,
                                                                 consultant = {name: 'Assistenza post vendita'}
                                                             }, index) =>

                                <IonBtn key={id}
                                        onClick={() => history.push("/residence/" + residenceId + "/apartment/" + apartmentId + "/comunicazioni/" + id)}
                                        color="white" expand="block" className="d-flex shadow-sm mb-3 px-0 h-100">
                                    <div
                                        className="d-flex justify-content-between ion-text-left w-100 my-3 d-block align-items-center">
                                        <div className="d-flex align-items-center">
                                            <img

                                                src={process.env.REACT_APP_API + "/mcd_profile_api/private/image/" + consultant.id + "?session_id=" + sessionId}
                                                onError={(e) => {
                                                    e.target.src = "/assets/img/icons/user-placeholder.png"
                                                }}
                                                className="rounded-circle" width="40" height="40"/>

                                            <div className="px-3 ion-text-wrap">
                                                <div className={"h5 font-weight-bold mb-1"}>
                                                    {name}
                                                </div>
                                                <p className="mb-0 opacity-50">{consultant.name}</p>
                                            </div>
                                        </div>
                                        <IonIcon icon={chevronForward} className="opacity-50" slot="end"/>
                                    </div>
                                </IonBtn>
                            )
                            :
                            <IonSpinner/>

                    }


                    <IonModal key={'form-modal'} isOpen={showModal}
                              onDidDismiss={() => setShowModal(false)}>
                        <IonHeader>
                            <IonToolbar>
                                <IonTitle>Nuova comunicazione</IonTitle>
                                <IonButtons slot="end">
                                    <IonButton onClick={() => setShowModal(false)}>
                                        Chiudi
                                    </IonButton>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>
                        <IonContent className="pb-3">

                            <IonList>
                                <IonItem>
                                    <IonLabel>Fornitura<span className="text-primary fw-bold">*</span></IonLabel>
                                    <IonSelect onIonChange={e => setSaleOrderId(e.detail.value)}
                                               placeholder="Seleziona...">

                                        {
                                            !!apartment?.sale_order?.length && apartment.sale_order.map(({
                                                                                                             id,
                                                                                                             name,
                                                                                                             home_j_type
                                                                                                         }, index) => {
                                                    return home_j_type !== 'Scelta Finiture' &&
                                                        <>
                                                            <IonSelectOption value={id} key={id}>
                                                                {home_j_type.trim() === "Extra Furniture" ? "Arredo Extra" : home_j_type}
                                                            </IonSelectOption>
                                                        </>
                                                }
                                            )
                                        }
                                    </IonSelect>
                                </IonItem>
                                <IonItem onClick={() => {
                                    setShowConsultantModal(true)
                                }}>
                                    <IonLabel>Destinatario<span className="text-primary fw-bold">*</span></IonLabel>
                                    {
                                        consultantId &&
                                        <>
                                            <IonBadge
                                                slot={'end'}>{consultants.find(consultant => consultant.id === consultantId).name}</IonBadge>

                                        </>
                                    }
                                </IonItem>
                                <IonItem onClick={() => {
                                    setShowFollowerModal(true)
                                }}>
                                    <IonLabel>CC</IonLabel>
                                    <ion-badge
                                        slot="end">{followers && followers.length > 0 ? followers.length : 0}</ion-badge>
                                </IonItem>
                                <IonItem onClick={() => {
                                    setShowEmailCcModal(true)
                                }}>
                                    <IonLabel>Altre email</IonLabel>
                                    <ion-badge
                                        slot="end">{emailCc && emailCc.length > 0 ? emailCc.length : 0}</ion-badge>
                                </IonItem>
                                <IonItem>
                                    <IonLabel position="stacked">Oggetto<span className="text-primary fw-bold">*</span></IonLabel>
                                    <IonInput onIonChange={e => setName(e.detail.value)} type="text"
                                              placeholder="Titolo della comunicazione"/>
                                </IonItem>
                                <IonItem>
                                    <IonLabel position="stacked">Testo del messaggio<span
                                        className="text-primary fw-bold">*</span></IonLabel>
                                    <IonTextarea onIonChange={e => setDescription(e.detail.value)} type="text"
                                                 placeholder="Scrivi il messaggio"/>
                                </IonItem>
                            </IonList>
                            {
                                /*
                            <div className="px-3 my-3">
                                <IonButton color="light" expand="block" onClick={() => setShowModal(false)}>
                                    <IonIcon icon={attach}/>
                                    Allega immagine o PDF
                                </IonButton>

                            </div>

                                 */
                            }


                            <div className="mx-3 mt-2  border-bottom">
                                <IonLabel>Allegato</IonLabel>

                                <div className={"d-flex align-items-center mb-3"}>
                                    <IonBtn size="small" onClick={() => takePicture()}
                                            className={"mr-1"}>
                                        <IonIcon icon={camera} className={"me-2"}/>
                                        {
                                            newTicketBase64 ?
                                                "1 File selezionato"
                                                :
                                                "Allega immagine o PDF"
                                        }
                                    </IonBtn>
                                    <input type={"file"} onChange={handleNewTicketInput} className={"d-none"}
                                           ref={newTicketInput}/>
                                    {
                                        newTicketBase64 &&
                                        <div className={"btn btn-link"}
                                             onClick={() => setNewTicketBase64(null)}>Rimuovi</div>
                                    }
                                </div>
                            </div>


                            <div className="mx-3 text-md my-3 bg-light rounded p-2 d-flex align-items-center">
                                <span className="d-flex" style={{fontSize: 20}}>
                                    <IonIcon icon={informationCircleOutline} className="me-1"/>
                                </span>
                                I campi contrassegnati da <span className="text-primary fw-bold mx-2">*</span> sono
                                obbligatori
                            </div>
                            <div className="mx-3 text-md my-3 bg-light rounded p-2 d-flex align-items-center">
                                <span className="d-flex" style={{fontSize: 20}}>
                                    <IonIcon icon={informationCircleOutline} className="me-1"/>
                                </span>
                                Le comunicazioni presenti in questa sezione sono
                                accessibili a Contract District Group e alla committenza della Residenza
                            </div>

                            <IonRow className="px-2">
                                <IonCol size="4" className="pb-0">
                                    <IonBtn color="light" expand="block"
                                            onClick={() => setShowModal(false)}>Annulla</IonBtn>
                                </IonCol>
                                <IonCol size="8" className="pb-0" onClick={() =>
                                    (!(saleOrderId && consultantId && name && description)) && present({
                                        header: 'Attenzione',
                                        message: 'Assicurati di aver compilato tutti i campi obbligatori (contrassegnati da *) prima di continuare',
                                        buttons: [
                                            {text: 'Ok', handler: (d) => console.log('ok pressed')},
                                        ],
                                    })
                                }>
                                    <IonBtn
                                        disabled={!(saleOrderId && consultantId && name && description) || creatingTicket}
                                        color="dark" expand="block" onClick={createTicket}>

                                        {
                                            creatingTicket ?
                                                <IonSpinner/>
                                                :
                                                <span>
                                                    Invia comunicazione
                                                </span>
                                        }
                                    </IonBtn>
                                </IonCol>
                            </IonRow>
                            <IonModal isOpen={showConsultantModal} swipeToClose={true}
                                      onDidDismiss={() => setShowConsultantModal(false)}>
                                <IonHeader>
                                    <IonToolbar>
                                        <IonTitle text-wrap>Destinatario</IonTitle>
                                        <IonButtons slot="end">
                                            <IonButton onClick={() => setShowConsultantModal(false)}>
                                                Salva
                                            </IonButton>
                                        </IonButtons>
                                    </IonToolbar>
                                </IonHeader>
                                <IonContent key={'select-consultant-modal'} className="ion-padding">
                                    <IonList>
                                        {
                                            !!consultants?.length && consultants.map(({
                                                                                          id,
                                                                                          name,
                                                                                          role,
                                                                                          partner_id
                                                                                      }, index) =>

                                                <IonItem onClick={() => setConsultantId(id)}>
                                                    <IonAvatar slot="start">
                                                        <img

                                                            src={process.env.REACT_APP_API + "/mcd_profile_api/private/image/" + partner_id + "?session_id=" + sessionId}
                                                            onError={(e) => {
                                                                e.target.src = "/assets/img/icons/user-placeholder.png"
                                                            }}
                                                            className="rounded-circle" width="40" height="40"/>

                                                    </IonAvatar>
                                                    <IonLabel>
                                                        <h2>{name}</h2>
                                                        <p>{role}</p>
                                                    </IonLabel>
                                                    {
                                                        consultantId && consultantId === id &&
                                                        <>
                                                            <IonIcon icon={checkmarkOutline} slot={'end'}></IonIcon>
                                                        </>
                                                    }
                                                </IonItem>
                                            )
                                        }
                                    </IonList>
                                </IonContent>
                            </IonModal>

                            <IonModal isOpen={showFollowerModal}>
                                <IonHeader>
                                    <IonToolbar>
                                        <IonTitle text-wrap>Follower</IonTitle>
                                        <IonButtons slot="end">
                                            <IonButton onClick={() => setShowFollowerModal(false)}>
                                                Salva
                                            </IonButton>
                                        </IonButtons>
                                    </IonToolbar>
                                </IonHeader>
                                <IonContent className="ion-padding">
                                    <IonList>
                                        {
                                            !!consultants?.length && consultants.map(({
                                                                                          id,
                                                                                          name,
                                                                                          role,
                                                                                          partner_id
                                                                                      }, index) => {
                                                    return !consultantId || consultantId !== id &&
                                                        <IonItem>
                                                            <IonAvatar slot="start">
                                                                <img

                                                                    src={process.env.REACT_APP_API + "/mcd_profile_api/private/image/" + partner_id + "?session_id=" + sessionId}
                                                                    onError={(e) => {
                                                                        e.target.src = "/assets/img/icons/user-placeholder.png"
                                                                    }}
                                                                    className="rounded-circle" width="40" height="40"/>

                                                            </IonAvatar>
                                                            <IonLabel>
                                                                <h2>{name}</h2>
                                                                <p>{role}</p>

                                                            </IonLabel>
                                                            <IonCheckbox slot="end" value={index}
                                                                         onIonChange={(e) => addRemoveFollower(e)}
                                                                         checked={checkChecked(id)}
                                                            ></IonCheckbox>
                                                        </IonItem>
                                                }
                                            )
                                        }
                                    </IonList>
                                </IonContent>
                            </IonModal>

                            <IonModal isOpen={showEmailCcModal}>
                                <IonHeader>
                                    <IonToolbar>
                                        <IonTitle text-wrap>Email CC</IonTitle>
                                        <IonButtons slot="end">
                                            <IonButton onClick={() => setShowEmailCcModal(false)}>
                                                Chiudi
                                            </IonButton>
                                        </IonButtons>
                                    </IonToolbar>
                                </IonHeader>
                                <IonContent className="ion-padding">
                                    <IonList>
                                        <IonItem>
                                            <IonLabel position="stacked">Inserisci email</IonLabel>
                                            <IonRow>
                                                <IonCol size={8}>
                                                    <IonInput
                                                        type="email"
                                                        placeholder="email@esempio.com"
                                                        onIonInput={(e) => setNewEmail(e.target.value)}

                                                    ></IonInput>
                                                </IonCol>
                                                <IonCol size={4}>
                                                    <IonButton onClick={() => {
                                                        addRemoveEmail(newEmail)
                                                    }}>Aggiungi</IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </IonItem>
                                        {
                                            emailCc && !!emailCc?.length && emailCc.map((e, index) =>

                                                <IonItem>
                                                    {e}
                                                    <IonIcon icon={trashOutline} slot={'end'} onClick={() => {
                                                        addRemoveEmail(e, index)
                                                    }}></IonIcon>
                                                </IonItem>
                                            )
                                        }
                                    </IonList>
                                </IonContent>
                            </IonModal>


                        </IonContent>

                    </IonModal>


                </div>
            </IonContent>
        </IonPage>
    )

}
