import React, {useEffect, useState} from "react";
import {api} from "../api";
import {IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar} from "@ionic/react";
import {DocumentViewer} from "../pages/residence/apartment/documenti";

const LocationCard = ({name, image, catalogues, imageUrl, isCapitolato, basePath = '/mcd_residence_api/private/catalogue/'}) => {


    const [showModal, setShowModal] = useState(false);

    const [imageSrc, setImageSrc] = useState(image);

    useEffect(() => {



        const getImage = async (imageUrl) => {

            const sessionId = localStorage.getItem('sessionId');

            try {
                const {data: imageSrc} = await api.get(image + '?session_id=' + sessionId, {withCredentials: true});
                setImageSrc(imageSrc);
            } catch (e) {
                console.log(e)
            }
        }
        if (image) getImage(image);
    }, [image]);



    return (
        <>
            <div onClick={() => setShowModal(true)}
                 className="btn btn-white bg-white w-100 h-100 shadow-lg p-3 cursor-pointer rounded-xl">
                <div className="px-2 px-md-5 pt-4">
                    {
                        imageUrl ?
                            <div className="border border-primary rounded-circle mx-auto d-block p-square"
                                 style={{background: "url('" + imageUrl + "') center/cover no-repeat"}}/>
                            :
                            <div className="border border-primary rounded-circle mx-auto d-block p-square"
                                 style={{background: "url(" + (imageUrl || imageSrc ? imageSrc : (isCapitolato ? "/assets/img/capitolato.png" : "/assets/img/yellow-placeholder.png")) + ") center/" + (imageSrc ? "contain" : "cover") + " no-repeat"}}/>
                    }

                </div>
                <div style={{height: 55}} className="overflow-hidden h5 text-center font-weight-bold my-4 pt-2">
                    {name}
                </div>
            </div>
            {
                catalogues &&
                <IonModal title={'Documenti ' + name} isOpen={showModal} onDidDismiss={() => setShowModal(false)}>

                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>
                                Dettagli
                            </IonTitle>

                            <IonButtons slot="end" >
                                <IonButton onClick={() => setShowModal(false)}>
                                    Chiudi
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen>
                        {
                            catalogues.map(({id, name, youtube_video_id, customPdfUrl}, index) => (
                                <DocumentViewer key={id} name={name} youtubeId={youtube_video_id}
                                                pdfUrl={
                                                    customPdfUrl ?
                                                        customPdfUrl
                                                        :
                                                        basePath + id
                                                }/>
                            ))
                        }
                    </IonContent>

                </IonModal>

            }
        </>
    )
}
export default LocationCard;
