import React, {useEffect, useState} from "react";
import {
    IonContent,
    IonIcon,
    IonPage, useIonToast, useIonViewWillEnter,
} from "@ionic/react";
import {chevronForward, timeOutline, timerSharp} from "ionicons/icons";
import {IonBtn} from "../components/IonBtn";
import {useHistory} from "react-router";
import {retrieveNotifications} from "../api";
import {Header} from "../components/Header";

export const Notifications = () => {

    const [notifications, setNotifications] = useState(null);

    useIonViewWillEnter(() => {
        const retrieveAllNotification = async () => {
            try {
                const {data} = await retrieveNotifications(true );
                setNotifications(data?.rows || []);
            } catch (err) {
                setNotifications([]);
                console.log(err);
            }
        };
        retrieveAllNotification();
    }, []);


    return (
        <IonPage>
            <Header/>
            <IonContent color="light">

                <div className="p-3">
                    <h1>Notifiche</h1>


                    {
                        notifications ?
                            <>
                                {
                                    !!notifications.length ?
                                        <>{


                                            notifications.map((data, i) =>
                                                <NotificationCard key={i} {...data} />
                                            )
                                        }
                                        </>
                                        :
                                        <p className="opacity-75 text-center text-sm">Nessuna notifica disponibile</p>
                                }
                            </>
                            :
                            <p className="opacity-75 text-center text-sm">Carico...</p>
                    }

                </div>


            </IonContent>
        </IonPage>

    )
}

const NotificationCard = ({
                              title,
                              description,
                              app_url_link,
                              create_date,
                              residence_name,
                              real_estate_unit_name,
                              redirectToURL,
                              is_visualize
                          }) => {

    /*const notification_date = create_date.split("T")[0].split("-");
    const months = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"]
*/
    /*const endTime = endTimeToParse.split(" ")[0];
    const endHours = endTimeToParse.split(" ")[1];

    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    startDate.setHours(startHours.split(":")[0], startHours.split(":")[1]);
    endDate.setHours(endHours.split(":")[0], endHours.split(":")[1]);*/



    const history = useHistory();
    const [present] = useIonToast();

    const viewNotification = () => {
        if (app_url_link && app_url_link !== "") {
            return history.push(app_url_link)
        }
        return;
    }
    return (
        <IonBtn handleClick={() => viewNotification()} expand="block" color={(is_visualize ? "light" : "white")}
                className={"shadow-sm h-auto mb-3" + (is_visualize ? " is_visualize" : "")}>
            <div
                className="d-flex align-items-center justify-content-between w-100 py-3 text-start flex-fill text-wrap">
                <div>
                    <span className="text-uppercase text-muted text-md">
                        {
                            !is_visualize &&
                            <span className="text-danger pe-2">
                                •
                            </span>
                        }
                        { residence_name && <>{residence_name}</>}
                        { real_estate_unit_name && <> - {real_estate_unit_name} </>}
                    </span>

                    <div className="h6 my-1">
                        {description}
                    </div>

                    <span className="text-uppercase text-muted text-sm mb-3">
                        <IonIcon icon={timeOutline}/>
                        {create_date}
                    </span>

                </div>

                <div className="flex-shrink-0">
                    <IonIcon icon={chevronForward} className="opacity-50" slot="end"/>
                </div>

            </div>

        </IonBtn>

    )
}
