import { IonContent, IonPage, IonSpinner } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { api, retrieveCategories } from "../../../api";
import { Header } from "../../../components/Header";
import { IonBtn } from "../../../components/IonBtn";
import { AuthContext } from "../../../contexts/AuthContext";
import { brandService } from "../../../services/brandService";

export default function TuaCasa({ match }) {


    const residenceId = match.params.residenceId;
    const apartmentId = match.params.apartmentId;

    const [categories, setCategories] = useState(null)

    const history = useHistory();

    const { apartment, setApartment } = useContext(AuthContext)
    useEffect(() => {
        const sessionId = localStorage.getItem('sessionId');

        const url = '/mcd_apartment_api/private/apartment/' + apartmentId + '?session_id=' + sessionId;

        api.get(url, { withCredentials: true })
            .then(({ data: apartment }) => {
                brandService.setData(apartment.residence);
                setApartment(apartment);
            })

    }, []);


    useEffect(() => {
        const retrieveAllCategories = async () => {
            try {
                const { data: { rows: categories } } = await retrieveCategories();
                setCategories(categories);
            } catch (err) {
                setCategories([]);
                console.log(err);
            }
        };

        retrieveAllCategories();

    }, []);

    if (!apartment || !categories) return (
        <IonPage>
            <IonContent>
                <IonSpinner />
            </IonContent>
        </IonPage>
    )

    return (
        <IonPage>
            <Header />
            <IonContent>

                <div className="p-3 dashboard-grid">
                    {
                        categories && categories.map(({ id, name, image }, i) =>
                            <IonBtn
                                onClick={() => history.push("/residence/" + residenceId + "/apartment/" + apartmentId + "/tua-casa/" + id)}
                                key={i} expand="block" color="white" className="shadow h-100">
                                <div className="d-flex flex-column align-items-center py-3 w-100 h-100">
                                    <img src={"/assets/img/" + name + ".jpg"} width="100" height="100"
                                        className="border border-primary rounded-pill thick" />
                                    <span
                                        className="ion-text-wrap pt-2 flex-fill d-flex flex-column justify-content-center"
                                        style={{ lineHeight: 1.3 }}>
                                        <span>{name}</span>
                                    </span>
                                </div>
                            </IonBtn>
                        )
                    }
                </div>
            </IonContent>
        </IonPage>
    )
}
