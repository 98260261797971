import {
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonPage,
    IonTitle,
    IonToolbar,
    useIonToast,
} from "@ionic/react";
import { useContext, useState } from "react";
import { api, getUserData } from "../api";
import { forgotPassword } from '../api/index';
import { IonBtn } from "../components/IonBtn";
import { AuthContext } from "../contexts/AuthContext";
import "../theme/index.scss";

export const Login = () => {
    const { setUser } = useContext(AuthContext);
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [present, dismiss] = useIonToast();
    const [password, setPsw] = useState("");
    const [email, setEmail] = useState("");

    const [forgetPasswordEmail, setForgetPasswordEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const login = async () => {
        setLoading(true);

        let jsonRpcBody = {
            "db": process.env.REACT_APP_MCD_ODOO_DB,
            "login": email,
            "password": password,
        };

        try {
            const { data } = await api.post('/session/auth/login', jsonRpcBody);
            if (data && data.session) {
                localStorage.setItem('sessionId', data.session.sid);
                const userData = await getUserData(data.session.sid);
                if (data.primo_accesso !== undefined) {
                    userData.primo_accesso = data.primo_accesso;
                }
                setUser(userData);
            } else {
                present({
                    position: 'top',
                    duration: 4000,
                    color: 'danger',
                    buttons: [{ text: 'Chiudi', handler: () => dismiss() }],
                    message: 'Errore nel login',
                });
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const handleForgotPasswordClick = () => {
        setShowForgotPasswordModal(true);
    };

    const closeForgotPasswordModal = () => {
        setShowForgotPasswordModal(false);
    };

    const handleSubmit = async () => {
        if (!forgetPasswordEmail) {
            setEmailError("Campo obbligatorio");
        } else if (!forgetPasswordEmail.includes('@') || !forgetPasswordEmail.includes('.')) {
            setEmailError("Inserisci un indirizzo email valido");
        } else {
            setEmailError("");
            let response = await forgotPassword(forgetPasswordEmail);
            if (!response) {
                setEmailError("L'email inserita non esiste");
            } else {
                setShowForgotPasswordModal(false);
            }
        }
    };


    return (
        <IonPage>
            <IonContent color="light">
                <div className="p-5 text-center text-dark">
                    <img height="30" src="/assets/home_j-logo.svg" className="mx-auto d-block" />
                    <p className="mt-5 mb-1 h4">Benvenuto</p>
                    <p className="opacity-75 mb-5">Effettua il login per iniziare</p>
                    <div className="ion-text-left">
                        <label className="w-100 mb-3">
                            <span className="fw-bold">Indirizzo email</span>
                            <input value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" type="email" placeholder="email@esempio.com" />
                        </label>
                        <label className="w-100 mb-3">
                            <span className="fw-bold">Password</span>
                            <input value={password} onChange={(e) => setPsw(e.target.value)} className="form-control" type="password" placeholder="La password del tuo account Home J" />
                        </label>
                        <IonBtn disabled={!(email && password) || loading} onClick={login} color="dark" expand="block">
                            Login
                        </IonBtn>
                        <IonButton expand="block" color="none" style={{ marginTop: "8px", backgroundColor: "none" }} onClick={handleForgotPasswordClick}>
                            Password dimenticata ?
                        </IonButton>

                    </div>
                </div>
            </IonContent>

            <IonModal id="forgot-password-modal" isOpen={showForgotPasswordModal} backdropDismiss={false}>
                <IonHeader>
                    <IonToolbar >
                        <IonTitle >Password dimenticata ?</IonTitle>
                        <IonButtons slot="start">
                            <IonButton onClick={closeForgotPasswordModal}>Annulla</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-text-center" fullscreen>
                    <p className="mt-2" style={{ "textAlign": "left", "paddingLeft": "10px", "paddingTop": "10px" }}>
                        Inserisci la mail associata al tuo account Home-J
                    </p>
                    <IonItem>
                        <IonLabel position="floating">Email</IonLabel>
                        <IonInput type="email" inputmode="email" value={forgetPasswordEmail} onIonChange={(e) => setForgetPasswordEmail(e.detail.value)} />
                    </IonItem>
                    {
                        <div style={{ color: "gray", "textAlign": "left", "paddingLeft": "10px", "paddingTop": "10px" }}>
                            {emailError}
                        </div>
                    }
                </IonContent>
                <IonFooter>
                    <IonToolbar>
                        <IonButton color="primary" expand="full" onClick={handleSubmit}>
                            Invia
                        </IonButton>
                    </IonToolbar>
                </IonFooter>
            </IonModal>
        </IonPage >
    );
};
