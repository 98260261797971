import { IonSelect, IonSelectOption } from "@ionic/react";
import { useEffect, useState } from "react";

import './SurveyMultipleChoice.css';

export const SurveyMultipleChoice = ({
    id,
    title,
    answers,
    setStepValue,
    showImages,
}) => {
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        const storedValues = JSON.parse(localStorage.getItem("surveyValues")) || [];
        const flattenedValues = storedValues.flat().filter(value => value !== null);
        const storedAnswer = flattenedValues.find((value) => value.id === id);
        if (storedAnswer && storedAnswer.value !== null) {
            setSelectedValue(storedAnswer.value);
        }
    }, [id]);


    const handleImageClick = (value) => {
        setSelectedValue(value);
        setStepValue({ id, value });
    };

    const createImageUrl = (encodedString) => {
        try {
            const decodedData = atob(encodedString);
            return decodedData;
        } catch (error) {
            console.error("Error creating image URL:", error);
            return null;
        }
    };


    return (
        <div className="mb-2 pb-3 border-bottom">
            <span className="text-md fw-bold">{title}</span>
            {showImages && Boolean(answers[0]?.image_name) ? (
                <div>
                    {answers.map((answer, i) => (
                        <div
                            key={i}
                            className={`clickable-image mx-auto my-2 ${selectedValue === answer.id ? "selected" : ""
                                }`}
                            onClick={() => handleImageClick(answer.id)}
                        >
                            {answer.image_answer && answer.image_name && (
                                <>
                                    <img
                                        src={`data:image/png;base64,${createImageUrl(
                                            answer.image_answer
                                        )}`}
                                        alt={answer.image_name}
                                        style={{
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            display: "block",
                                            margin: "auto",
                                            border:
                                                selectedValue === answer.id
                                                    ? "2px solid orange"
                                                    : "none",
                                        }}
                                    />
                                    <p
                                        className="text-center label"
                                        style={{ marginTop: 4, whiteSpace: "nowrap" }}
                                    >
                                        {answer.image_name}
                                    </p>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                <IonSelect
                    className="bg-white rounded shadow-sm custom-ion-select"
                    placeholder="Select"
                    onIonChange={(ev) => {
                        const selectedValue = ev.detail.value;
                        setSelectedValue(selectedValue);
                        setStepValue({ id, value: selectedValue });
                    }}
                    value={selectedValue}
                >
                    {answers && answers.length > 0 && (
                        <>
                            {answers.map((answer, i) => (
                                <IonSelectOption key={i} value={answer.id}>
                                    {answer.value}
                                </IonSelectOption>
                            ))}
                        </>
                    )}
                </IonSelect>
            )}
        </div>
    );
};
