import { IonContent, IonPage } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { api } from "../../../api";
import { Header } from "../../../components/Header";
import { IonBtn } from "../../../components/IonBtn";
import { AuthContext } from "../../../contexts/AuthContext";
import { brandService } from "../../../services/brandService";

export default function Casa({ match }) {


    const residenceId = match.params.residenceId;
    const apartmentId = match.params.apartmentId;

    const history = useHistory();
    const { apartment, setApartment } = useContext(AuthContext)
    useEffect(() => {
        const sessionId = localStorage.getItem('sessionId');

        const url = '/mcd_apartment_api/private/apartment/' + apartmentId + '?session_id=' + sessionId;

        api.get(url, { withCredentials: true })
            .then(({ data: apartment }) => {
                brandService.setData(apartment.residence);
                setApartment(apartment);
            })

    }, []);


    return <IonPage>
        <Header />
        <IonContent color="light">

            <div className="p-3">

                <h2 className="mb-4">La tua casa</h2>

                <div className="dashboard-grid">

                    <IonBtn
                        onClick={() => history.push("/residence/" + residenceId + "/apartment/" + apartmentId + "/interior-pack")}
                        expand="block" color="white" className="shadow h-100">
                        <div className="d-flex flex-column align-items-center py-3 w-100 h-100">
                            <img src={"/assets/img/interior_pack.png"} width="100" height="100" alt=""
                                className="border border-primary rounded-pill thick" />
                            <span className="ion-text-wrap pt-2 flex-fill d-flex flex-column justify-content-center"
                                style={{ lineHeight: 1.3 }}>
                                <span>Il tuo interior pack</span>
                            </span>
                        </div>
                    </IonBtn>
                    {!apartment.residence.hide_specifications_finishings &&
                        <>
                            <IonBtn
                                onClick={() => history.push("/residence/" + residenceId + "/apartment/" + apartmentId + "/capitolati")}
                                expand="block" color="white" className="shadow h-100">
                                <div className="d-flex flex-column align-items-center py-3 w-100 h-100">
                                    <img src={"/assets/img/capitolato.png"} width="100" height="100" alt=""
                                        className="border border-primary rounded-pill thick" />
                                    <span
                                        className="ion-text-wrap pt-2 flex-fill d-flex flex-column justify-content-center"
                                        style={{ lineHeight: 1.3 }}>
                                        <span>Il tuo capitolato finiture</span>
                                    </span>
                                </div>
                            </IonBtn>
                        </>
                    }
                    {!apartment.residence.hide_brand_partners &&
                        <>
                            <IonBtn
                                onClick={() => history.push("/residence/" + residenceId + "/apartment/" + apartmentId + "/tua-casa")}
                                expand="block" color="white" className="shadow h-100">
                                <div className="d-flex flex-column align-items-center py-3 w-100 h-100">
                                    <img src={"/assets/img/cosa_trovare.png"} width="100" height="100" alt=""
                                        className="border border-primary rounded-pill thick" />
                                    <span
                                        className="ion-text-wrap pt-2 flex-fill d-flex flex-column justify-content-center"
                                        style={{ lineHeight: 1.3 }}>
                                        <span>Brand Partner</span>
                                    </span>
                                </div>
                            </IonBtn>
                        </>
                    }
                </div>
            </div>

        </IonContent>
    </IonPage>

}
