import {
    IonContent,
    IonIcon,
    IonPage,
    useIonViewWillEnter
} from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import { useState } from "react";
import { useHistory } from "react-router";
import { retrieveSurveys } from "../api";
import { Header } from "../components/Header";
import { IonBtn } from "../components/IonBtn";

export const Surveys = () => {
    const [surveys, setSurveys] = useState(null);

    useIonViewWillEnter(() => {
        const retrieveAllSurveys = async () => {
            try {
                const { data } = await retrieveSurveys();
                const sortedSurveys = (data?.rows || []).sort((a, b) => {
                    const order = {
                        'in_progress': 1,
                        null: 2,
                        'done': 3
                    };
                    return order[a.survey_completed] - order[b.survey_completed];
                });
                setSurveys(sortedSurveys);
            } catch (err) {
                setSurveys([]);
                console.log(err);
            }
        };
        retrieveAllSurveys();
    });

    return (
        <IonPage>
            <Header />
            <IonContent color="light">
                <div className="p-3">
                    <h1>Survey</h1>
                    {surveys ? (
                        <>
                            {surveys.length ? (
                                <>
                                    {surveys.map((data, i) => (
                                        <SurveyCard key={i} {...data} />
                                    ))}
                                </>
                            ) : (
                                <p className="opacity-75 text-center text-sm">
                                    Nessuna survey disponibile
                                </p>
                            )}
                        </>
                    ) : (
                        <p className="opacity-75 text-center text-sm">Carico...</p>
                    )}
                </div>
            </IonContent>
        </IonPage>
    );
};

const SurveyCard = ({ id, title, survey_completed, sale_order_id, ticket_name, ticket_id, residence, ba_name }) => {
    const history = useHistory();

    return (
        <IonBtn
            handleClick={() => {
                if (survey_completed !== "done") {
                    if (sale_order_id) {
                        history.push("/residence/surveys/" + id + '/' + sale_order_id);
                    }
                    if (ticket_id) {
                        history.push("/residence/surveys/" + id + '/' + ticket_id + '/' + ticket_id);
                    }
                }
            }}
            expand="block"
            color={survey_completed === "done" ? "light" : "white"}
            className={"shadow-sm h-auto mb-3" + (survey_completed === "done" ? "" : " opacity-75")}
        >
            <div className="d-flex align-items-center justify-content-between w-100 py-3 text-start flex-fill text-wrap">
                <div>
                    <span className="text-uppercase text-muted text-md">
                        {residence.name}
                        {ba_name && <> • {ba_name}</>}
                    </span>
                    <div className="h6 my-1">
                        {title}
                        {ticket_name && <> • {ticket_name}</>}
                    </div>
                    <span className="text-uppercase text-muted text-sm mb-3">
                        {survey_completed === "done" ? (
                            <>Grazie per il tuo feedback!</>
                        ) : (
                            <>Compila la survey</>
                        )}
                    </span>
                </div>
                {survey_completed !== "done" && (
                    <div className="flex-shrink-0">
                        <IonIcon icon={chevronForward} className="opacity-50" slot="end" />
                    </div>
                )}
            </div>
        </IonBtn>
    );
};
