import { IonTextarea } from "@ionic/react";
import { useEffect, useState } from "react";

export const SurveyTextBox = ({ id, title, setStepValue }) => {
    const [characterCount, setCharacterCount] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState("");

    useEffect(() => {
        const storedValues = JSON.parse(localStorage.getItem("surveyValues")) || [];
        // Flatten the array and filter out null values
        const flattenedValues = storedValues.flat().filter(value => value !== null);
        const storedAnswer = flattenedValues.find((value) => value.id === id);

        if (storedAnswer && storedAnswer.value !== null) {
            setSelectedAnswer(storedAnswer.value);
            setCharacterCount(storedAnswer.value.length);
        }
    }, [id]);

    const changedValue = (inputValue) => {
        setCharacterCount(inputValue.length);
        setSelectedAnswer(inputValue);

        setStepValue({ id, value: inputValue });
    }

    return (
        <div className="mb-2 pb-3 border-bottom">
            <span className={"text-md fw-bold"}>{title}</span>
            <IonTextarea
                className="rounded border px-2 bg-white"
                maxlength={100}
                placeholder="Es: un suggerimento che proporrei è di..."
                onIonChange={(ev) => changedValue(ev.detail.value)}
                value={selectedAnswer}
            />
            <div className="character-counter">
                {characterCount} / 100
            </div>
        </div>
    );
}
