import React, {useState} from "react";
import {motion, AnimatePresence} from "framer-motion"
import {IonIcon, IonSpinner} from "@ionic/react";
import {chevronForward, linkOutline} from "ionicons/icons";
import {IonBtn} from "./IonBtn";


export const Collapsable = ({title, content, documentUrl, loading}) => {

    const [open, setOpen] = useState(false);

    return (

        <div onClick={() => !documentUrl && setOpen(open => !open)}
             className={"my-2 w-100 ion-text-left overflow-hidden " + (documentUrl ? "bg-light px-3 py-2" : "btn btn-white bg-white shadow-sm cursor-pointer")}>
            <div className={"d-flex align-items-center justify-content-between"}>
                <div>
                    {
                        !documentUrl &&
                        <IonIcon icon={chevronForward} className={"me-2 op-5"} style={{transform : "rotate(" +(open ? 90 : 0)+ "deg)" }}/>
                    }
                    <span className={"font-weight-bold"}>{title}</span>
                </div>
                {
                    documentUrl &&
                    <div>
                        <div>
                            <a href={documentUrl} target={"_blank"} className={"btn btn-dark border me-2"}>
                                <IonIcon icon={linkOutline} className={"op-5"}/>
                            </a>
                            <IonBtn disabled={loading} onClick={() => setOpen(open => !open)}>
                                {
                                    loading &&
                                    <IonSpinner className={"me-2 op-5"} />
                                }
                                {open ? "Chiudi" : "Anteprima"}
                                <IonIcon icon={chevronForward} className={"ms-2 op-5"}  style={{transform : "rotate(" +(open ? 90 : 0)+ "deg)" }}/>
                            </IonBtn>
                        </div>
                    </div>
                }
            </div>
            <motion.div
                className={"overflow-hidden"}
                key="content"
                initial="collapsed"
                animate={open ? "open" : "collapsed"}
                variants={{
                    open: { opacity: 1, height: "auto", transition: { duration: 0.3} },
                    collapsed: { opacity: 0, height: 0, transition: { duration: 0.3} }
                }}>
                {
                    documentUrl ?
                        <div className={"mt-3"}>{content}</div>
                        :
                        <div className={"mt-2"} dangerouslySetInnerHTML={{__html: content}}/>

                }
            </motion.div>
        </div>
    )

}

const Faq = ({faqs}) => (
    <>
        {
            faqs && faqs.map(({name, answer}, index) =>
                <Collapsable key={index}
                             title={name}
                             content={answer}/>
            )
        }
    </>
)
export default Faq;
