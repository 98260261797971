import {
    IonAvatar,
    IonCard,
    IonContent,
    IonPage,
} from "@ionic/react";

import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { api } from "../api";
import { Header } from "../components/Header";
import { SelectProfile } from "../components/selectProfile";
import { AuthContext } from "../contexts/AuthContext";
import { brandService } from "../services/brandService";
export const logoWidth = 85;
export const logoHeight = 50;

export const SelectResidence = () => {

    const [residences, setResidences] = useState(null);
    const history = useHistory();
    const sessionId = localStorage.getItem('sessionId');
    const { setCstBrandData } = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);

    const { isOpenChangeProfile, setIsOpenChangeProfile } = useContext(AuthContext);

    const [guest, setGuest] = useState(null);

    useEffect(() => {
        const retrieveAllResidence = async () => {
            try {
                const { data } = await retrieveResidences();
                brandService.setData({});
                setResidences(data?.rows || []);
            } catch (err) {
                setResidences([]);
                console.log(err);
            }
        };

        const invitation = JSON.parse(localStorage.getItem("invitation"));
        if (invitation) {
            setGuest(invitation);

        }
        if (!invitation || (invitation && !invitation.guest_blocked)) {
            retrieveAllResidence();
        }

    }, []);


    useEffect(() => {

        if (history?.location?.pathname === "/residence") {
            document.getElementById("cstBrandStyles") && document.getElementById("cstBrandStyles").remove();
            localStorage.removeItem("cstBrand");
            brandService.setData({});
            setCstBrandData(null);
        }
    }, [history?.location]);

    const setResidence = (residence) => {
        brandService.setData(residence);
        setCstBrandData(residence);
        history.push("/residence/" + residence.id + "/selectApartment")
    }

    const changeInvitation = () => {
        setIsOpen(true);
    }


    return (
        <IonPage>
            <Header />
            <IonContent fullscreen forceOverscroll="false" color="light">

                {guest && <>
                    <div className="p-3" >
                        <p className="text-center">
                            Stai visualizzando l'account di {guest.host.name} <span className={'text-primary-account'} onClick={
                                () => {
                                    changeInvitation(true);
                                }
                            }>Cambia account</span>
                        </p>

                    </div>
                </>}

                <div className="p-3">
                    <p className="text-center">
                        Seleziona residenza
                    </p>
                    {
                        residences ?
                            <>
                                {
                                    !!residences.length ?
                                        <>
                                            {
                                                residences.map((residence, index) => (
                                                    <IonCard key={index} onClick={() => setResidence(residence)}>
                                                        <IonAvatar className={'border'} style={{
                                                            minWidth: '200px',
                                                            minHeight: "200px",
                                                            margin: "20px auto"
                                                        }}>
                                                            <img className={"avatar"} alt="Silhouette of mountains"
                                                                src={process.env.REACT_APP_API + '/mcd_residence_api/private/image/' + residence.id + "?session_id=" + sessionId} />
                                                        </IonAvatar>
                                                    </IonCard>
                                                ))
                                            }

                                        </>
                                        :
                                        <p className="opacity-75 text-center text-sm">Nessuna residenza disponibile</p>
                                }
                            </>
                            :
                            <>
                                {guest?.guest_blocked ?
                                    <p className="opacity-75 text-center text-sm">Il tuo account ospite risulta
                                        bloccato</p>
                                    :
                                    <p className="opacity-75 text-center text-sm">Carico...</p>
                                }
                            </>

                    }

                </div>
            </IonContent>
            <SelectProfile open={isOpen} onClose={
                () => {
                    setIsOpen(false);
                }
            }></SelectProfile>
        </IonPage>
    )
}


export const retrieveResidences = async (id_residence = false) => {

    let url = '/mcd_residence_api/private/residence/'
    if (id_residence) {
        url += id_residence
    }
    try {
        const sessionId = localStorage.getItem('sessionId');
        let invitation = localStorage.getItem('invitation');
        let invitationParams = '';
        if (invitation) {
            invitation = JSON.parse(invitation);
            invitationParams = '&host_id=' + invitation.host.id + '&guest_id=' + invitation.id;
        }
        return await api.get(url + '?session_id=' + sessionId + invitationParams, { withCredentials: true })
    } catch (e) {
        console.log(e)
        throw new Error(e);
    }
}
