import {
    IonButton,
    IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
    IonContent, IonFooter, IonHeader,
    IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonModal,
    IonPage, IonSelect, IonSelectOption, IonTitle, IonToggle, IonToolbar, useIonAlert, useIonToast, useIonViewWillEnter,
} from "@ionic/react";
import { chevronForward, lockClosed, lockOpen, personAdd, trash } from "ionicons/icons";
import { useEffect, useState } from "react";
import {
    addApartmentGuestCapability,
    createGuest,
    deleteGuestCapability,
    retrieveGuests,
    updateGuestCapability, updateGuestDetail
} from "../api";
import { Header } from "../components/Header";
import { IonBtn } from "../components/IonBtn";
import { retrieveResidences } from "./SelectResidence";


export const GestisciOspiti = () => {

    const [showGuestDetail, setShowGuestDetail] = useState(false);
    const [showCreateGuest, setShowCreateGuest] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [guests, setGuests] = useState(null);


    const retrieveAllGuests = async () => {
        try {
            const { data } = await retrieveGuests(true);
            setGuests(data?.rows.reverse() || []);
        } catch (err) {
            setGuests([]);
            console.log(err);
        }
    };

    useIonViewWillEnter(() => {
        retrieveAllGuests();
    }, []);


    useEffect(() => {
        if (modalData) {
            setShowGuestDetail(true);
        }
    }, [modalData]);

    return (
        <IonPage>
            <Header />
            <IonContent color="light">

                <div className="p-3">
                    <h1>Gestisci ospiti</h1>

                    <IonBtn className="mb-3" expand="block" color="dark" onClick={() => setShowCreateGuest(true)}>
                        <IonIcon icon={personAdd} style={{ fontSize: 18 }} className="me-2" />
                        Aggiungi nuovo ospite
                    </IonBtn>

                    {
                        guests?.map((data, i) =>
                            <GuestCard onClick={() => setModalData(data)} key={i} {...data} />
                        )
                    }

                </div>

                <GuestModalDetail data={modalData} show={showGuestDetail}
                    onClose={async () => {
                        await retrieveAllGuests();
                        setShowGuestDetail(false);
                        setTimeout(() => setModalData(null), 150);
                    }}
                ></GuestModalDetail>
                <CreateGuestModal data={modalData} show={showCreateGuest}
                    onClose={async () => {
                        await retrieveAllGuests();
                        setShowCreateGuest(false);
                        setTimeout(() => setModalData(null), 150);
                    }}
                />

            </IonContent>
        </IonPage>

    )
}

const defaultForm = {
    name: false,
    email: false,
    all_residence: true,
    residence_id: false,
    apartment_id: [],
    disabled: false,
    filterResidence: false,
    typology_guest: false,
    communications: false,
    your_house: false,
    document: false,
    agenda: false,
    assistance: false
};

const CreateGuestModal = ({ data, show, onClose }) => {

    const [loading, setLoading] = useState(false);
    const [present] = useIonToast();

    const [formData, setFormData] = useState(defaultForm);
    const [residences, setResidences] = useState(null);
    const [apartments, setApartments] = useState(null);

    const { name, surname, email, role, residence, disabled } = formData;

    const validateForm = () => {
        let valid = true;
        const addCheck = [
            "all_residence",
            "residence_id",
            "apartment_id",
            "filterResidence",
            "disabled"
        ];

        Object.keys(formData).forEach(key => {
            if (!addCheck.includes(key)) {
                // Skip checking keys in addCheck array

                if (key === "residence_id" && formData["all_residence"] === false) {
                    // Check residence_id only if all_residence is false
                    if (!formData["residence_id"] || formData["residence_id"].length === 0) {
                        valid = false;
                    }
                } else {
                    if (!formData[key]) {
                        valid = false;
                    }
                }
            }
        });

        return valid;
    };

    const isFormValid = validateForm();


    useEffect(() => {
        const retrieveAllResidence = async () => {
            try {
                const { data } = await retrieveResidences();
                if (data.count > 0) {
                    setResidences(data.rows);
                }
            } catch (err) {
                setResidences([]);
                console.log(err);
            }
        };
        retrieveAllResidence();
        setFormData(data || defaultForm);
    }, [data]);

    useEffect(() => {
        // Fetch apartments for all residences
        const fetchApartmentsForAllResidences = async () => {
            try {
                let combinedData = {};
                if (residences) {
                    // Iterate through all residences
                    for (const residence of residences || []) {
                        const { data } = await retrieveResidences(residence.id);

                        combinedData[residence.name] = {
                            id: residence.id,
                            name: residence.name,
                            apartments: data.apartments.map(apartment => apartment.name)
                        };
                    }
                }
                // Set the combined list of residences and apartments
                setApartments(combinedData);
            } catch (err) {
                setApartments({});
                console.log(err);
            }
        };

        // Fetch apartments when the component loads
        fetchApartmentsForAllResidences();
    }, [residences]);

    const handleFormChange = (field, value) => {
        setFormData(curr => ({ ...curr, [field]: value }));
    }

    const sendForm = async () => {
        setLoading(false);
        onClose();

        let residenceIds = [];
        let apartmentIds = [];

        if (formData.all_residence) {
            // If user has access to all residences, set residenceIds to all residence ids
            residenceIds = residences.map(residence => residence.id);
            // Set apartmentIds to null since the user has access to all apartments
            apartmentIds = Array(residenceIds.length).fill(null);
        }
        else {

            [residenceIds, apartmentIds] = formData.residence_id.reduce(
                (acc, id) => {
                    let residence, apartment;
                    if (id.includes(" - ")) {
                        [residence, apartment] = id.split(" - ");
                    } else {
                        residence = id;
                        apartment = null;
                    }
                    acc[0].push(parseInt(residence));
                    acc[1].push(parseInt(apartment));
                    return acc;
                },
                [[], []]
            );
        }

        try {
            for (let i = 0; i < residenceIds.length; i++) {
                const singleFormData = {
                    ...formData,
                    residence_id: residenceIds[i],
                    apartment_id: [apartmentIds[i]],
                };

                await createGuest(singleFormData);
            }

            await present("Ospite creato con successo", 4000);
            setFormData(defaultForm);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };




    return (
        <IonModal isOpen={show} onDidDismiss={onClose}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Aggiungi nuovo ospite
                    </IonTitle>

                    <IonButtons slot="end">
                        <IonButton onClick={onClose}>
                            Chiudi
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent>

                <div className="p-3">

                    <div className="row">

                        <div className="col-12">
                            <label className="w-100 mb-3">
                                <div className="mb-1">Nome e cognome</div>
                                <input value={formData.name ? formData.name : ''}
                                    onChange={e => handleFormChange("name", e.target.value)}
                                    className="form-control" type="text" placeholder="Es: Luca" />
                            </label>
                        </div>

                    </div>

                    <label className="w-100 mb-3">
                        <div className="mb-1">Indirizzo email</div>
                        <input value={formData.email ? formData.email : ''}
                            onChange={e => handleFormChange("email", e.target.value)}
                            className="form-control" type="email" placeholder="es: email@example.it" />
                    </label>

                    <label className="w-100 mb-3">
                        <div className="mb-1">
                            L'ospite che voglio invitare è
                        </div>

                        <IonSelect placeholder="Seleziona..." className="form-control d-flex"
                            onIonChange={e => handleFormChange("typology_guest", e.detail.value)}
                            value={formData.typology_guest} required>
                            <IonSelectOption value="architect_interior_designer">Architetto/Interior
                                Designer</IonSelectOption>
                            <IonSelectOption value="friend">Amico</IonSelectOption>
                            <IonSelectOption value="relative">Parente</IonSelectOption>
                            <IonSelectOption value="other">Altro</IonSelectOption>
                        </IonSelect>
                    </label>

                    <label className="w-100 mb-3">
                        <div className="mb-1">
                            Concedi accesso alle unità immobiliari:
                        </div>
                        <IonSelect placeholder="Seleziona..." className="form-control d-flex"
                            onIonChange={e => {
                                handleFormChange("all_residence", e.detail.value === 'choice' ? false : true)
                                handleFormChange("filterResidence", e.detail.value === 'choice')
                            }}
                            value={formData.filterResidence ? 'choice' : 'all'}
                            required
                        >
                            <IonSelectOption value={'all'}>Tutte le unità</IonSelectOption>
                            <IonSelectOption value={'choice'}>Solo alcune</IonSelectOption>


                        </IonSelect>
                    </label>

                    {
                        formData.filterResidence && (
                            <label className="w-100 mb-3">
                                <div className="mb-1">
                                    Residenza/Unità immobiliare
                                </div>

                                <IonSelect
                                    placeholder="Seleziona..."
                                    className="form-control d-flex"
                                    multiple
                                    onIonChange={e => handleFormChange("residence_id", e.detail.value)}
                                    value={formData.residence_id}
                                    interfaceOptions={{
                                        header: 'Seleziona le Unità Immobiliari'
                                    }}
                                >
                                    {Object.values(apartments || {}).map(residence => (
                                        residence.apartments.map((apartment, index) => (
                                            <IonSelectOption
                                                key={`${residence.name} - ${index}`}
                                                value={`${residence.id} - ${index}`}
                                            >
                                                {`${residence.name} - ${apartment}`}
                                            </IonSelectOption>
                                        ))
                                    ))}
                                </IonSelect>
                            </label>
                        )
                    }

                    <IonList>
                        <IonLabel>
                            Il mio ospite può
                        </IonLabel>


                        <IonItem>
                            <IonLabel>Comunicazioni</IonLabel>
                            <IonSelect placeholder="Seleziona..."
                                className="form-control d-flex"
                                onIonChange={e => handleFormChange("communications", e.detail.value)}
                            >
                                <IonSelectOption value="all" text-wrap >Visualizzare e rispondere ai messaggi</IonSelectOption>
                                <IonSelectOption value="follower" text-wrap>Visualizzare i messaggi</IonSelectOption>
                                <IonSelectOption value="none" text-wrap>Non visualizzare i messaggi</IonSelectOption>
                            </IonSelect>
                        </IonItem>


                        <IonItem>
                            <IonLabel>La tua casa</IonLabel>
                            <IonSelect placeholder="Seleziona..." className="form-control d-flex"
                                onIonChange={e => handleFormChange("your_house", e.detail.value)}
                            >
                                <IonSelectOption value="visible" text-wrap>Visualizzare i cataloghi e Int,
                                    Pack</IonSelectOption>
                                <IonSelectOption value="invisible" text-wrap>Non visualizzare i cataloghi e Int,
                                    Pack</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                        <IonItem>
                            <IonLabel>Documenti</IonLabel>
                            <IonSelect placeholder="Seleziona..." className="form-control d-flex"
                                onIonChange={e => handleFormChange("document", e.detail.value)}

                            >
                                <IonSelectOption value="visible" text-wrap>Consultare i documenti</IonSelectOption>
                                <IonSelectOption value="invisible" text-wrap>Non consultare i
                                    documenti</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                        <IonItem>
                            <IonLabel>Assistenza</IonLabel>
                            <IonSelect placeholder="Seleziona..." className="form-control d-flex"
                                onIonChange={e => handleFormChange("assistance", e.detail.value)}

                            >
                                <IonSelectOption value="possible_entry" text-wrap>Aprire e gestire le
                                    segnalazioni</IonSelectOption>
                                <IonSelectOption value="invisible" text-wrap>Non visualizzare le
                                    segnalazioni</IonSelectOption>
                                <IonSelectOption value="in_reading" text-wrap>Visualizzare le segnalazioni
                                    aperte</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                        <IonItem>
                            <IonLabel>Agenda</IonLabel>
                            <IonSelect placeholder="Seleziona..." className="form-control d-flex"
                                onIonChange={e => handleFormChange("agenda", e.detail.value)}

                            >
                                <IonSelectOption value="everyone" text-wrap>Visualizzare gli
                                    appuntamenti</IonSelectOption>
                                <IonSelectOption value="nobody" text-wrap>Non visualizzare gli
                                    appuntamenti</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonList>
                </div>
            </IonContent>
            <IonFooter>
                <IonToolbar className="p-3">
                    <IonBtn onClick={sendForm} color="dark" expand="block" disabled={!isFormValid}>Invita
                        utente</IonBtn>
                </IonToolbar>
            </IonFooter>
        </IonModal >

    )
}

const GuestModalDetail = ({ data, show, onClose }) => {
    const [present] = useIonToast();
    const [presentAlert] = useIonAlert();
    const [capabilities, setCapabilities] = useState([]);
    const [openApartment, setOpenApartment] = useState(false);


    useEffect(() => {
        if (data && data.guest_capability_ids) {
            setCapabilities(data.guest_capability_ids)
        }
    }, [data]);


    const presentToast = (message, color) => {
        present({
            message: message,
            duration: 1000,
            position: 'top',
            color: color
        });
    };

    async function formGuestAccess(blocked) {
        try {
            data.guest_blocked = blocked;
            presentToast("Modifiche salvate!", "success");
        } catch (err) {
            presentToast("Errore salvataggio. Riprovare", "danger");
        }
    };


    async function formGuestCapability(formData, capabilityId) {
        try {
            await updateGuestCapability(formData, capabilityId);
            presentToast("Modifiche salvate!", "success");
        } catch (err) {
            presentToast("Errore salvataggio. Riprovare", "danger");
        }
    }

    async function removeApartmentAccess(guestCapabilityId, index) {

        try {
            await deleteGuestCapability(guestCapabilityId);
            data?.guest_capability_ids.splice(index, 1)
            setCapabilities([...data?.guest_capability_ids])
            presentToast("Modifiche salvate!", "success");
        } catch (err) {
            presentToast("Errore salvataggio. Riprovare", "danger");
        }
    }

    const updateGuest = async (formData) => {

        if (formData === undefined) return;

        try {
            await updateGuestDetail(data.id, { guest_blocked: formData });
            presentToast("Modifiche salvate!", "success");
        } catch (err) {
            presentToast("Errore salvataggio. Riprovare", "danger");
        }
    };


    return (
        <IonModal isOpen={show} onDidDismiss={onClose}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Impostazioni utente
                    </IonTitle>

                    <IonButtons slot="end">
                        <IonButton onClick={onClose}>
                            Chiudi
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonCard>
                    <IonCardContent>
                        <div className="d-flex align-items-center">
                            <strong>Nome</strong>: {data?.guest_name}
                        </div>
                        <div className="d-flex align-items-center">
                            <strong>Email</strong>: {data?.guest_email}
                        </div>
                        <div className="d-flex align-items-center">
                            <strong>Ruolo</strong>: {data?.typology_guest}
                        </div>
                        <label className="w-100 mb-3 d-flex justify-content-between align-items-center">
                            <div className="mb-1">
                                Stato:
                                <span className="fw-bolder ps-1">{data?.guest_blocked ? "Bloccato" : "Abilitato"}</span>

                                {
                                    data?.guest_blocked &&
                                    <p className="text-md text-muted mb-0 mt-1">Questo utente non potrà effettuare
                                        login</p>
                                }
                            </div>

                            <div className="d-flex align-items-center flex-shrink-0">
                                <IonIcon icon={lockOpen} />
                                <IonToggle
                                    onIonChange={e => {
                                        if (e.detail.checked === undefined) return;
                                        updateGuest(e.detail.checked);
                                        data.guest_blocked = e.detail.checked;
                                    }}
                                    checked={data?.guest_blocked} />
                                <IonIcon icon={lockClosed} />
                            </div>

                        </label>
                    </IonCardContent>
                </IonCard>
                {capabilities?.map((item, index) => (
                    <IonCard key={index}>
                        <IonCardHeader>
                            <IonIcon icon={trash} onClick={async () => {

                                presentAlert({
                                    header: 'Attenzione!',
                                    message: 'Sei sicuro di voler rimuovere l\'accesso a questo appartamento?',
                                    buttons: [
                                        {
                                            text: 'Cancel',
                                            role: 'cancel'
                                        },
                                        {
                                            text: 'OK',
                                            role: 'confirm',
                                            handler: async () => {
                                                await removeApartmentAccess(item.id, index)
                                            },
                                        },
                                    ],
                                })
                            }} />
                            <IonCardTitle>{item.residence_name}</IonCardTitle>
                            <IonCardSubtitle>{item.apartment_name}</IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonList>
                                <IonItem>
                                    <IonLabel>Comunicazioni</IonLabel>
                                    <IonSelect placeholder="Seleziona..." className="form-control d-flex"
                                        value={item.communications}
                                        onIonChange={e => {
                                            item.communications = e.detail.value;
                                            formGuestCapability({ communications: e.detail.value }, item.id)
                                        }}
                                    >
                                        <IonSelectOption value="all" text-wrap>Visualizzare e rispondere ai
                                            messaggi</IonSelectOption>
                                        <IonSelectOption value="follower" text-wrap>Visualizzare i
                                            messaggi</IonSelectOption>
                                        <IonSelectOption value="none" text-wrap>Non visualizzare i
                                            messaggi</IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>La tua casa</IonLabel>
                                    <IonSelect placeholder="Seleziona..." className="form-control d-flex"
                                        value={item.your_house}
                                        onIonChange={e => {
                                            item.your_house = e.detail.value;
                                            formGuestCapability({ your_house: e.detail.value }, item.id)
                                        }}
                                    >
                                        <IonSelectOption value="visible" text-wrap>Visualizzare i cataloghi e Int,
                                            Pack</IonSelectOption>
                                        <IonSelectOption value="invisible" text-wrap>Non visualizzare i cataloghi e Int,
                                            Pack</IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Documenti</IonLabel>
                                    <IonSelect placeholder="Seleziona..." className="form-control d-flex"
                                        value={item.document}
                                        onIonChange={e => {
                                            item.document = e.detail.value;
                                            formGuestCapability({ document: e.detail.value }, item.id)
                                        }}
                                    >
                                        <IonSelectOption value="visible" text-wrap>Consultare i
                                            documenti</IonSelectOption>
                                        <IonSelectOption value="invisible" text-wrap>Non consultare i
                                            documenti</IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Assistenza</IonLabel>
                                    <IonSelect placeholder="Seleziona..." className="form-control d-flex"
                                        value={item.assistance}
                                        onIonChange={e => {
                                            item.assistance = e.detail.value;
                                            formGuestCapability({ assistance: e.detail.value }, item.id)
                                        }}>
                                        <IonSelectOption value="possible_entry" text-wrap>Aprire e gestire le
                                            segnalazioni</IonSelectOption>
                                        <IonSelectOption value="invisible" text-wrap>Non visualizzare le
                                            segnalazioni</IonSelectOption>
                                        <IonSelectOption value="in_reading" text-wrap>Visualizzare le segnalazioni
                                            aperte</IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Agenda</IonLabel>
                                    <IonSelect placeholder="Seleziona..." className="form-control d-flex"
                                        value={item.agenda}
                                        onIonChange={e => {
                                            item.agenda = e.detail.value;
                                            formGuestCapability({ agenda: e.detail.value }, item.id)
                                        }}
                                    >
                                        <IonSelectOption value="everyone" text-wrap>Visualizzare gli
                                            appuntamenti</IonSelectOption>
                                        <IonSelectOption value="nobody" text-wrap>Non visualizzare gli
                                            appuntamenti</IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                            </IonList>
                        </IonCardContent>
                    </IonCard>
                ))}
            </IonContent>
            <IonFooter>
                <IonButton expand="block" className={'bg-primary-force'} onClick={() => {
                    setOpenApartment(true);
                }}>Aggiungi nuova residenza</IonButton>
            </IonFooter>
            <AddApartmentModal isOpen={openApartment} onClose={() => {
                setOpenApartment(false);
            }} guest_id={data?.id}></AddApartmentModal>
        </IonModal>

    )
}


const AddApartmentModal = ({ isOpen, onDidDismiss, guest_id, onClose, guest_name, guest_email, guest_blocked }) => {

    const defaultNewCap = {
        agenda: false,
        real_estate_unit_id: false,
        assistance: false,
        communications: false,
        document: false,
        residence_id: false,
        your_house: false,
        guest_id
    }
    const [residences, setResidences] = useState([]);
    const [apartments, setApartments] = useState(null);
    const [formData, setFormData] = useState(defaultNewCap);

    const validateForm = () => {
        // check if all fromData fields are different from false
        return Object.values(formData).filter(v => v === false).length !== 0;
    }
    const isFormValid = validateForm();


    const handleFormChange = (field, value) => {
        setFormData(curr => ({ ...curr, [field]: value }));
    }

    async function handleFormSubmit() {
        await addApartmentGuestCapability(formData)
        setFormData(defaultNewCap);
        onClose();
    }


    useEffect(() => {

        const retrieveAllResidence = async () => {
            try {
                const { data } = await retrieveResidences();
                if (data.count > 0) {
                    setResidences(prevResidences => [...prevResidences, ...data.rows]);
                }
            } catch (err) {
                setResidences([]);
                console.log(err);
            }
        };

        if (!residences || residences.length === 0) {
            retrieveAllResidence();
        }

        //setFormData(data || defaultForm);
    }
    );


    const selectResidence = async (residenceId) => {
        setFormData(curr => ({ ...curr, 'residence_id': residenceId }));
        setFormData(curr => ({ ...curr, 'real_estate_unit_id': false }));
        setApartments(null);
        try {
            const { data } = await retrieveResidences(residenceId);
            if (data.apartments.length > 0) {
                setApartments([...data.apartments])
            }
        } catch (err) {
            setApartments([]);
            console.log(err);
        }
    }

    return (
        <IonModal isOpen={isOpen} onDidDismiss={onDidDismiss}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Aggiungi residenza
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => {
                            setFormData(defaultNewCap);
                            onClose()
                        }}>
                            Chiudi
                        </IonButton>
                    </IonButtons>
                </IonToolbar>

            </IonHeader>
            <IonContent>
                {formData &&
                    <>
                        <IonList lines="none">
                            <IonItem>
                                <IonLabel position="floating">Residenza</IonLabel>
                                <IonSelect placeholder="Seleziona..." className="form-control d-flex"
                                    disabled={!residences}
                                    onIonChange={e => {
                                        selectResidence(e.detail.value)
                                    }}
                                    value={formData?.residence_id}>
                                    {
                                        residences?.map((item, index) => (
                                            <IonSelectOption value={item.id}>{item.name}</IonSelectOption>))
                                    }


                                </IonSelect>
                            </IonItem>
                            <IonItem>
                                {
                                    formData.residence_id && (
                                        <div className="w-100 mb-3">
                                            <IonLabel position="floating">Unità immobiliare</IonLabel>


                                            <IonSelect placeholder="Seleziona..." className="form-control d-flex"
                                                disabled={!apartments}
                                                onIonChange={e => handleFormChange("real_estate_unit_id", e.detail.value)}
                                                value={formData?.real_estate_unit_id}

                                            >
                                                {
                                                    apartments?.map((item, index) => (
                                                        <IonSelectOption value={item.id}>{item.name}</IonSelectOption>))
                                                }


                                            </IonSelect>
                                        </div>
                                    )
                                }
                            </IonItem>
                            <IonItemDivider className={'mb-3 mt-3'}>
                                Permessi
                            </IonItemDivider>
                            <IonItem>
                                <IonLabel>Comunicazioni</IonLabel>
                                <IonSelect placeholder="Seleziona..." className="form-control d-flex"
                                    onIonChange={e => handleFormChange("communications", e.detail.value)}

                                >
                                    <IonSelectOption value="all" text-wrap>Visualizzare e rispondere ai
                                        messaggi</IonSelectOption>
                                    <IonSelectOption value="follower" text-wrap>Visualizzare i
                                        messaggi</IonSelectOption>
                                    <IonSelectOption value="none" text-wrap>Non visualizzare i
                                        messaggi</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonItem>
                                <IonLabel>La tua casa</IonLabel>
                                <IonSelect placeholder="Seleziona..." className="form-control d-flex"
                                    onIonChange={e => handleFormChange("your_house", e.detail.value)}
                                >
                                    <IonSelectOption value="visible" text-wrap>Visualizzare i cataloghi e Int,
                                        Pack</IonSelectOption>
                                    <IonSelectOption value="invisible" text-wrap>Non visualizzare i cataloghi e Int,
                                        Pack</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Documenti</IonLabel>
                                <IonSelect placeholder="Seleziona..." className="form-control d-flex"
                                    onIonChange={e => handleFormChange("document", e.detail.value)}

                                >
                                    <IonSelectOption value="visible" text-wrap>Consultare i documenti</IonSelectOption>
                                    <IonSelectOption value="invisible" text-wrap>Non consultare i
                                        documenti</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Assistenza</IonLabel>
                                <IonSelect placeholder="Seleziona..." className="form-control d-flex"
                                    onIonChange={e => handleFormChange("assistance", e.detail.value)}

                                >
                                    <IonSelectOption value="possible_entry" text-wrap>Aprire e gestire le
                                        segnalazioni</IonSelectOption>
                                    <IonSelectOption value="invisible" text-wrap>Non visualizzare le
                                        segnalazioni</IonSelectOption>
                                    <IonSelectOption value="in_reading" text-wrap>Visualizzare le segnalazioni
                                        aperte</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Agenda</IonLabel>
                                <IonSelect placeholder="Seleziona..." className="form-control d-flex"
                                    onIonChange={e => handleFormChange("agenda", e.detail.value)}

                                >
                                    <IonSelectOption value="everyone" text-wrap>Visualizzare gli
                                        appuntamenti</IonSelectOption>
                                    <IonSelectOption value="nobody" text-wrap>Non visualizzare gli
                                        appuntamenti</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        </IonList>

                    </>
                }
            </IonContent>
            <IonFooter>
                <IonButton expand={'block'} disabled={isFormValid} onClick={() => {
                    handleFormSubmit()
                }}>
                    Salva
                </IonButton>
            </IonFooter>
        </IonModal>
    )

}
    ;


const GuestCard = ({
    onClick,
    guest_name,
    typology_guest,
    guest_email,
    guest_blocked,
    disabled,
    guest_capability_ids
}) => {

    const labelTypology = (typology) => {

        switch (typology) {
            case 'friend':
                return 'Amico';
            case 'architect_interior_designer':
                return 'Architetto/Interior Designer';
            case 'relative':
                return 'Parente';
            case 'other':
                return 'Altro';
            default:
                return typology;
        }
    }


    return (
        <IonBtn onClick={onClick} expand="block" color={(guest_blocked ? "light" : "white")}
            className={"shadow-sm h-auto mb-3" + (guest_blocked ? " opacity-75" : "")}>

            <div
                className="d-flex align-items-center justify-content-between w-100 py-3 text-start flex-fill text-wrap">

                <div>

                    <div className="h5 my-1">
                        {
                            disabled &&
                            <IonIcon icon={lockClosed} style={{ fontSize: 16 }} className="me-1" />
                        }
                        {guest_name}
                    </div>

                    <span className="text-muted text-md mb-3">
                        {guest_email}
                    </span>


                    <div className="d-flex gap-2 pt-2">
                        <div className="rounded-pill text-md bg-primary px-3 py-1 bg-primary-force"
                            style={{ backgroundColor: '#ffc409 !important' }}>{labelTypology(typology_guest)}</div>
                        {
                            guest_capability_ids.map((capability, index) => () => {
                                return (
                                    <div id={index}
                                        className="rounded-pill text-md bg-dark text-white px-3 py-1">{capability.residence_name}</div>
                                )
                            })
                        }
                    </div>

                </div>

                <div className="flex-shrink-0">
                    <IonIcon icon={chevronForward} className="opacity-50" slot="end" />
                </div>

            </div>

        </IonBtn>

    )
}