import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../contexts/AuthContext";
import {api} from "../../../api";
import {IonContent, IonPage} from "@ionic/react";
import LocationCard from "../../../components/location-card";
import {Header} from "../../../components/Header";
import {brandService} from "../../../services/brandService";

export default function InteriorPack({match}) {


    const residenceId = match.params.residenceId;
    const apartmentId = match.params.apartmentId;

    const [categories, setCategories] = useState(null)
    const [interiorPack, setInteriorPack] = useState(null)

    const {apartment, setApartment} = useContext(AuthContext);

    const session_id = localStorage.getItem('sessionId');

    useEffect(() => {
        const sessionId = localStorage.getItem('sessionId');

        const url = '/mcd_apartment_api/private/apartment/' + apartmentId + '?session_id=' + sessionId;

        api.get(url, {withCredentials: true})
            .then(({data: apartment}) => {
                brandService.setData(apartment.residence);
                setApartment(apartment);

                if (apartment.interior_pack_attachment) {
                    setInteriorPack(apartment.interior_pack_attachment.map((item) => {
                        return {
                            id: item.id,
                            name: "Il tuo interior pack " + item.template_name,
                            customPdfUrl: "/mcd_apartment_api/private/interior-pack-attachment/" + item.id
                        }
                    }))
                } else {
                    setInteriorPack([{
                        id: apartment.id,
                        name: "Il tuo interior pack",
                        customPdfUrl: "/mcd_apartment_api/private/interior-pack/" + apartment.id
                    }])
                }


            })

    }, []);


    return <IonPage>
        <Header/>
        <IonContent color="light">

            <div className="p-3">

                <h2 className="mb-4">Il tuo interior pack</h2>

                <div className="dashboard-grid">
                    {
                        interiorPack &&
                        <>
                            <LocationCard name={"Il tuo interior pack"} image={"/assets/img/interior_pack.png"}
                                          catalogues={interiorPack}/>
                        </>
                    }


                    {
                        (apartment.residence && apartment.residence.catalogues) && apartment.residence.catalogues.map(({
                                                                                                                           supplier_id,
                                                                                                                           supplier_description,
                                                                                                                           image,
                                                                                                                           supplier_catalogues
                                                                                                                       }, i) =>
                            <LocationCard name={supplier_description}
                                          image={process.env.REACT_APP_API + "/mcd_profile_api/private/image/" + supplier_id + '?session_id=' + session_id}
                                          catalogues={supplier_catalogues}/>
                        )
                    }


                </div>
            </div>
        </IonContent>
    </IonPage>
}
