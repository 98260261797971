import {
    IonContent,
    IonIcon,
    IonPage,
} from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Header } from "../../components/Header";
import { IonBtn } from "../../components/IonBtn";
import { brandService } from "../../services/brandService";
import { retrieveResidences } from "../SelectResidence";

export const logoWidth = 85;
export const logoHeight = 50;

export const SelectApartment = ({ match }) => {

    const [apartments, setApartments] = useState(null);
    const history = useHistory();
    const residenceId = match.params.residenceId;

    useEffect(() => {
        const retrieveApartments = async () => {
            try {
                const { data } = await retrieveResidences(residenceId);
                brandService.setData(data);
                setApartments(data?.apartments || []);
            } catch (err) {
                setApartments([]);
                console.log(err);
            }
        };

        retrieveApartments();

    }, []);

    return (
        <IonPage>
            <Header backPath={"/residence"} defaultHref={"/residence"} />
            <IonContent fullscreen forceOverscroll="false" color="light">
                <div className="p-3">
                    <p className="text-center">
                        Seleziona l'unità immobiliare
                    </p>
                    {
                        apartments ?
                            <>
                                {
                                    !!apartments.length ?
                                        <>
                                            {
                                                apartments.map(({ name, id }, index) => (

                                                    <IonBtn key={index} handleClick={() => history.push("/residence/" + residenceId + "/apartment/" + id)} expand="block" color="white" className="shadow-sm mb-3">
                                                        {name}
                                                        <IonIcon icon={chevronForward} className="opacity-50" slot="end" />
                                                    </IonBtn>
                                                ))
                                            }
                                        </>
                                        :
                                        <p className="opacity-75 text-center text-sm">Nessun appartamento disponibile</p>
                                }
                            </>
                            :
                            <p className="opacity-75 text-center text-sm">Carico...</p>
                    }
                </div>
            </IonContent>
        </IonPage>
    )
}
