import { IonContent, IonFooter, IonPage, IonSpinner } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { api } from "../../../api";
import { Header } from "../../../components/Header";
import IconCard from "../../../components/icon-card";
import { AuthContext } from "../../../contexts/AuthContext";
import { brandService } from "../../../services/brandService";

export const Dashboard = ({ match }) => {
    const residenceId = match.params.residenceId;
    const apartmentId = match.params.apartmentId;
    const { apartment, setApartment } = useContext(AuthContext);
    const [completedSaleOrders, setCompletedSaleOrders] = useState([]);
    const [capability, setCapability] = useState(false);
    const history = useHistory();
    const [hasDocuments, setHasDocuments] = useState(false);

    useEffect(() => {
        const sessionId = localStorage.getItem('sessionId');
        const invitation = JSON.parse(localStorage.getItem('invitation') || '{}');
        const invitationParams = invitation.host ? `&host_id=${invitation.host.id}&guest_id=${invitation.id}` : '';

        if (invitation.capabilities) {
            const capabilityIndex = invitation.capabilities.findIndex(cap => cap.apartment_id === apartmentId);
            if (capabilityIndex >= 0) {
                setCapability(invitation.capabilities[capabilityIndex]);
            }
        }

        const url = `/mcd_apartment_api/private/apartment/${apartmentId}?session_id=${sessionId}&exclude_signed_document=True${invitationParams}`;

        api.get(url, { withCredentials: true })
            .then(({ data }) => {
                if (data?.sale_order?.length) {
                    const filteredSaleOrder = data.sale_order.filter(order =>
                        order.so_typology?.trim() === "Standard" &&
                        ["Interior Pack", "Extra Furniture", "IP Plus"].includes(order.home_j_type?.trim())
                    );
                    const filteredApartment = { ...data, sale_order: filteredSaleOrder };

                    brandService.setData(filteredApartment.residence);
                    setApartment(filteredApartment);
                }
            });
    }, [apartmentId]);

    useEffect(() => {
        if (apartment?.sale_order?.length) {
            const hasDocs = apartment.sale_order.some(order => order.documents && Object.keys(order.documents).length);
            setHasDocuments(hasDocs);

            const completedOrders = apartment.sale_order.filter(order => order.state >= 5);
            setCompletedSaleOrders(completedOrders);
        }
    }, [apartment]);

    const renderIconCard = (name, path, disabled = false) => (
        <IconCard
            name={name}
            onClick={() => history.push(path)}
            disabled={disabled}
        />
    );

    return (
        <IonPage>
            <Header backPath={`/residence/${residenceId}/selectApartment`} />
            <IonContent forceOverscroll="false" color="light">
                {apartment ? (
                    <div className="p-3 dashboard-grid">
                        {renderIconCard("casa", `/residence/${residenceId}/apartment/${apartmentId}/casa`)}
                        {(!capability || capability.agenda === 'everyone') &&
                            renderIconCard("agenda", `/residence/${residenceId}/apartment/${apartmentId}/agenda`)}
                        {(!capability || capability.document === 'visible') &&
                            renderIconCard("documenti", `/residence/${residenceId}/apartment/${apartmentId}/documenti`, !hasDocuments)}
                        {(!capability || capability.communications !== 'none') &&
                            renderIconCard("ticket", `/residence/${residenceId}/apartment/${apartmentId}/comunicazioni`)}
                        {renderIconCard("tracker", `/residence/${residenceId}/apartment/${apartmentId}/home-tracker`, !apartment.sale_order.some(order => order.home_j_type === "Interior Pack"))}
                        {(!capability || capability.assistance !== 'invisible') &&
                            renderIconCard("support", `/residence/${residenceId}/apartment/${apartmentId}/post-vendita`, !apartment.worksheet_status)}
                    </div>
                ) : (
                    <IonSpinner />
                )}
            </IonContent>
            <IonFooter>
                <div className="position-fixed left-0 w-100 py-4 bg-primary"
                    style={{ bottom: "-1.5rem", left: 0, zIndex: 0, borderRadius: "50%" }} />
            </IonFooter>
        </IonPage>
    );
};
