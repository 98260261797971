import { IonContent, IonPage, IonSpinner } from "@ionic/react";

export const LoadingPage = () => {
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100vh'
    };

    const spinnerContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
    };

    const imageStyle = {
        marginTop: '20px'
    };

    return (
        <IonPage>
            <IonContent fullscreen color="light">
                <div style={containerStyle}>
                    <img
                        src="/assets/home_j-logo.svg"
                        style={imageStyle}
                        alt="spinner"
                        className="cst-img-margin"
                        width={100}
                        height={100}
                    />
                    <div style={spinnerContainerStyle}>
                        <IonSpinner />
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}
