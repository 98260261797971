import { IonBackButton, IonButtons, IonHeader, IonMenuButton, IonRow, IonToolbar } from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AuthContext, applyColor } from "../contexts/AuthContext";
import { logoHeight, logoWidth } from "../pages/residence/selectApartment";
import { brandService } from "../services/brandService";

export const Header = ({ defaultHref, backPath }) => {
    const history = useHistory();
    const [logo, setLogo] = useState("/assets/logo-mcd.png");
    const [showLogo, setShowLogo] = useState(true);
    const [cstBrandData, setCstBrandData] = useState(AuthContext);

    let session_id = localStorage.getItem("sessionId");

    useEffect(() => {
        brandService.getData().subscribe((data) => {
            if (data && data.logo_brand && data.brand_id) {
                setLogo(process.env.REACT_APP_API + "/mcd_residence_api/private/brand-image/" + data.brand_id + "?session_id=" + session_id);
            }
        });

        if (
            history?.location?.pathname === "/residence" ||
            history?.location?.pathname.search("residence/documents") >= 0 ||
            history?.location?.pathname === "/residence/surveys" ||
            history?.location?.pathname.search("residence/profile") >= 0 ||
            history?.location?.pathname.search("residence/faq") >= 0 ||
            history?.location?.pathname.search("residence/notifications") >= 0 ||
            history?.location?.pathname === "/residence/guests"
        ) {
            localStorage.removeItem("cstBrand");

            const el = document.getElementById("cstBrandStyles");
            if (el !== null) {
                el.remove();
            }
            applyColor();
            setCstBrandData();
            setShowLogo(false);
        } else {
            setShowLogo(true);
        }

    }, [history?.location?.pathname, session_id, showLogo]);

    return (
        <IonHeader color="white">
            <IonToolbar color="white">
                <IonRow>
                    <div className="header-logo">
                        <img className="cst-img-margin" width={logoWidth} height={logoHeight} src={"/assets/home_j-logo.svg"} alt="" />
                    </div>
                    <img className="cst-img-margin" style={{ visibility: "hidden" }} width={logoWidth} height={logoHeight} alt="" />
                    {showLogo && (
                        <>
                            <img
                                style={{ objectFit: "contain", objectPosition: "center right", maxWidth: 115, marginTop: "5px" }}
                                height="40"
                                id={"logo_brand"}
                                className="me-3 cst-img-margin"
                                src={logo}
                                alt=""
                            />
                        </>
                    )}
                </IonRow>
                <IonButtons slot="start">
                    <IonMenuButton />
                    <IonBackButton
                        onClick={() => {
                            if (backPath) {
                                history.replace(backPath);
                            } else {
                                history.goBack();
                            }
                        }}
                        defaultHref={defaultHref}
                        text=""
                        hidden={history?.location.pathname === "/residence"}
                    />
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    );
};
