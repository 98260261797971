import {IonContent, IonFooter, IonIcon, IonInput, IonPage, IonSpinner, IonToolbar, useIonToast} from "@ionic/react";
import {useContext, useEffect, useRef, useState} from "react";
import {AuthContext} from "../../../../contexts/AuthContext";
import {IonBtn} from "../../../../components/IonBtn";
import {attach, download} from "ionicons/icons";
import {api} from "../../../../api";
import {PhotoViewer} from "@awesome-cordova-plugins/photo-viewer";
import {Header} from "../../../../components/Header";
import {brandService} from "../../../../services/brandService";

export const Chat = ({match}) => {


    const residenceId = match.params.residenceId;
    const apartmentId = match.params.apartmentId;
    const chatId = match.params.chatId;
    const sessionId = localStorage.getItem('sessionId');
    const {apartment, setApartment} = useContext(AuthContext);
    const [ticketData, setTicketData] = useState();
    const [activeTicketMessages, setActiveTicketMessages] = useState({});
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [capability, setCapability] = useState(false);


    useEffect(() => {
        let invitation = localStorage.getItem('invitation');
        if (invitation) {
            invitation = JSON.parse(invitation);
            let capabilityIndex = invitation.capabilities?.findIndex((capability) => capability.apartment_id == apartmentId);
            if (capabilityIndex >= 0) {
                setCapability({...invitation.capabilities[capabilityIndex]});
            }
        }
        if (apartmentId) {
            retrieveTicketData();
        }
    }, [apartmentId]);

    const retrieveTicketData = async () => {
        let url = '/mcd_helpdesk_api/private/v2/helpdesk/' + chatId;
        try {
            const {data} = await api.get(url + '?session_id=' + sessionId, {withCredentials: true})
            if (data) {
                if (data.description){
                    const openingMessage = {
                        body: data.description.replace(/\n/g, "<br />"),
                        date: data.created_at,
                        reply: data.reply
                    }
                    setActiveTicketMessages([openingMessage, ...data.messages.row]);
                } else {
                    setActiveTicketMessages([...data.messages.row]);
                }

                brandService.setData(data.residence);

            } else {
                setActiveTicketMessages([]);
            }
            setTicketData(data);

        } catch (e) {
            console.log(e)
            return e;
        }
    };


    useEffect(() => {
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }, [ticketData]);

    const [present, dismiss] = useIonToast();

    const sendMessage = async () => {


        setLoading(true);

        try {

            let files;
            if (base64Image) {
                files = [base64Image.replace(/^data:image\/[a-z]+;base64,/, "").replace(/^data:application\/[a-z]+;base64,/, "")];
            }


            let url = '/mcd_helpdesk_api/private/message/create';
            const {data} = await api.post(url + '?session_id=' + sessionId,
                {
                    body: message,
                    res_id: Number(chatId),
                    files
                },
                {withCredentials: true}
            );

            retrieveTicketData();
            setMessage('');
            setBase64Image(null);
        } catch (err) {
            console.log(err);


            present({
                position: 'top',
                duration: 4000,
                color: 'danger',
                buttons: [{text: 'Chiudi', handler: () => dismiss()}],
                message: 'Errore nell\'invio del messaggio',
            })
        } finally {
            setLoading(false);
        }
    }

    const chatRef = useRef();


    const [base64Image, setBase64Image] = useState(null);

    const fileInput = useRef();

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleFileInput = async (e) => {
        setBase64Image(await toBase64(e.target.files[0]));
    }


    return (
        <IonPage>
            <Header/>
            <IonContent color="light">
                <div className="pt-3 grid-a-1fr h-100">


                    <div className="px-3 pb-1 border-bottom">
                        <div className="d-flex align-items-center">

                            <img
                                onError={(e) => {
                                    e.target.src = "/assets/img/icons/user-placeholder.png"
                                }}
                                src={process.env.REACT_APP_API + "/mcd_profile_api/private/image/" + (ticketData?.consultant.id) + "?session_id=" + sessionId}
                                width="40" height="40"/>


                            <div className="px-2 ion-text-wrap">
                                <div className={"h5 font-weight-bold mb-1"}>
                                    {ticketData?.name}
                                </div>
                                <p className="mb-0 opacity-50">{ticketData?.consultant?.name}</p>
                            </div>
                        </div>


                        {
                            (!!ticketData?.followers?.length && !ticketData.worksheet_id) &&
                            <div
                                className={"d-flex align-items-center flex-wrap mb-1 overflow-auto flex-nowrap text-nowrap mt-2"}>
                                <span className={"pr-3 pb-2 opacity-50 me-2"}>CC:</span>
                                {
                                    ticketData.followers.map(({id, name}) =>
                                        <div key={id}
                                             className={"rounded-pill bg-primary pe-2 me-2 mb-2 d-flex align-items-center text-sm flex-shrink-0"}>
                                            <img className={"rounded-circle cover me-2"} width={30}
                                                 height={30}
                                                 onError={(e) => {
                                                     e.target.src = "/assets/img/icons/user-placeholder.png"
                                                 }}
                                                 src={process.env.REACT_APP_API + "/mcd_profile_api/private/image/" + id + "?session_id=" + sessionId}/>
                                            <div>{name}</div>
                                        </div>
                                    )
                                }
                            </div>
                        }
                    </div>
                    <div ref={chatRef} className="overflow-auto px-3 py-2">
                        <p className="text-sm mb-3 opacity-50">Le comunicazioni presenti in questa sezione sono
                            accessibili a Contract District Group e alla committenza della Residenza</p>


                        {
                            !!activeTicketMessages?.length && activeTicketMessages.map(({
                                                                                            reply,
                                                                                            body,
                                                                                            date,
                                                                                            author,
                                                                                            attachments
                                                                                        }, index) =>
                                <div className={"d-flex mb-3 justify-content-" + (reply ? 'start' : 'end')} key={index}>
                                    <div className={"rounded bg-white shadow p-3 " + (reply ? '' : ' text-right')}>

                                        <div dangerouslySetInnerHTML={{__html: body}}/>
                                        <div className={"text-small text-muted text-sm"}>{date}</div>
                                        <div className="d-flex flex-column">
                                            {
                                                !!attachments?.length && attachments.map((attachment) => (
                                                        (attachment.mimetype.indexOf('image') !== -1) ?
                                                            <img className={"rounded mb-2 bg-light"}

                                                                 onClick={() => PhotoViewer.show(process.env.REACT_APP_API + '/mcd_helpdesk_api/private/attachment/' + attachment.id + "/?session_id=" + sessionId)}

                                                                 src={process.env.REACT_APP_API + '/mcd_helpdesk_api/private/attachment/' + attachment.id + "?session_id=" + sessionId}/>
                                                            :
                                                            <a href={process.env.REACT_APP_API + '/mcd_helpdesk_api/private/attachment/' + attachment.id + "?download=true&session_id=" + sessionId}
                                                               target={"_blank"} className={"btn btn-dark btn-sm mb-2"}>
                                                                <IonIcon icon={download}/>
                                                                Scarica Pdf
                                                            </a>
                                                    )
                                                )
                                            }
                                        </div>

                                    </div>
                                </div>
                            )
                        }
                        {

                            ticketData && ticketData.closed === true && ticketData.reason_to_close_ticket === ""  &&
                            <>
                                <div className={"d-flex mb-3 justify-content-start"} key={90000}>
                                    <div className={"rounded bg-white shadow p-3 "}>


                                        <div>
                                            Gentile Cliente,<br/>
                                            questa comunicazione è stata chiusa, per qualsiasi richiesta la invito ad aprire una
                                            nuova comunicazione.<br/>
                                            Grazie<br/>
                                            Un cordiale saluto
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                    </div>


                </div>




            </IonContent>
            {
                ticketData && ticketData.closed === false && (!capability || capability.communications === 'all' ) &&
                <>
                    <IonFooter color="white">
                        <IonToolbar color="white">

                            <div className="p-2 d-flex align-items-center">

                                <input type={"file"} onChange={handleFileInput} className={"d-none"} ref={fileInput}/>

                                <div onClick={() => fileInput.current.click()} className="bg-light rounded p-2 text-lg">
                                    {
                                        base64Image &&
                                        <span className={"mr-2 text-nowrap"}>1 file</span>
                                    }
                                    <IonIcon icon={attach}/>
                                </div>
                                <IonInput disabled={loading} value={message}
                                          onIonChange={e => setMessage(e.detail.value)}
                                          className="rounded-pill border px-3 ms-1 me-2" placeholder="Scrivi qui..."/>
                                <IonBtn disabled={!message || loading} onClick={sendMessage} color="dark">
                                    {
                                        loading ?
                                            <IonSpinner/>
                                            :
                                            <span>Invia</span>
                                    }
                                </IonBtn>
                            </div>
                        </IonToolbar>
                    </IonFooter>

                </>
            }
        </IonPage>
    )
}
