import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonModal,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { AnimatePresence, motion } from "framer-motion";
import { chevronForward } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { api } from "../../../api";
import { Header } from "../../../components/Header";
import { IonBtn } from "../../../components/IonBtn";
import { AuthContext } from "../../../contexts/AuthContext";
import { brandService } from "../../../services/brandService";

const months = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"]

export const Agenda = ({ match }) => {

    const apartmentId = match.params.apartmentId;
    const { apartment, setApartment } = useContext(AuthContext);

    useEffect(() => {
        const sessionId = localStorage.getItem('sessionId');
        const url = '/mcd_apartment_api/private/apartment/' + apartmentId + '?session_id=' + sessionId;

        api.get(url, { withCredentials: true })
            .then(({ data: apartment }) => {
                brandService.setData(apartment.residence);
                setApartment(apartment);
            })

    }, []);

    const [period, setPeriod] = useState("future");
    const [appointments, setAppointments] = useState();
    const [pastAppointments, setPastAppointments] = useState();
    const [filterableOrders, setFilterableOrders] = useState(null);
    const [filterableTags, setFilterableTags] = useState(null);
    const [pastFilterableOrders, setPastFilterableOrders] = useState(null);
    const [pastFilterableTags, setPastFilterableTags] = useState(null);

    useEffect(() => {

        if (apartment) {
            if (!!apartment.appointments?.length) {

                setAppointments(apartment.appointments);
            } else {
                setAppointments([]);
            }
        }

    }, [apartment]);

    useEffect(() => {
        apartment && getPastApartmentData(apartment.id);
    }, [apartment]);

    const getPastApartmentData = async (id) => {
        try {
            const sessionId = localStorage.getItem('sessionId');
            const url = '/mcd_apartment_api/private/apartment/' + id + '?session_id=' + sessionId + "&past=true";
            const { data: apartment } = await api.get(url, { withCredentials: true })
            setPastAppointments(apartment.appointments);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (!!appointments?.length) {

            let filterableOrders = {};
            let filterableTags = {};
            for (let sale_order of apartment.sale_order) {
                filterableOrders[sale_order.id] = {
                    name: sale_order.home_j_type,
                    so_name: sale_order.name,
                    show: true
                }
            }
            setFilterableOrders(filterableOrders);

            for (let appointment of appointments) {

                if (appointment.tags && appointment.tags.length > 0) {
                    for (let tag of appointment.tags) {
                        filterableTags[tag] = {
                            show: true
                        }
                    }
                }

                setFilterableTags(filterableTags);

            }
        }
    }, [appointments]);

    useEffect(() => {
        if (!!pastAppointments?.length) {


            let filterableOrders = {};
            let filterableTags = {};
            for (let sale_order of apartment.sale_order) {
                filterableOrders[sale_order.id] = {
                    name: sale_order.home_j_type,
                    show: true
                }
            }
            setPastFilterableOrders(filterableOrders);

            for (let appointment of pastAppointments) {

                if (appointment.tags && appointment.tags.length > 0) {
                    for (let tag of appointment.tags) {
                        filterableTags[tag] = {
                            show: true
                        }
                    }
                }

                setPastFilterableTags(filterableTags);

            }
        }
    }, [pastAppointments]);

    const [orderToShow, setOrderToShow] = useState("all");
    const [tagToShow, setTagToShow] = useState("all");

    const [pastOrderToShow, setPastOrderToShow] = useState("all");
    const [pastTagToShow, setPastTagToShow] = useState("all");

    return (
        <IonPage>
            <Header />
            <IonContent color="light">

                <div className="p-3">
                    <div className="d-flex align-items-end justify-content-between mb-3">
                        <h2 className="mb-0">Agenda</h2>
                        <div className="w-50 d-flex align-items-center">
                            <IonSegment mode="ios" value={period} onIonChange={e => setPeriod(e.detail.value)}>
                                <IonSegmentButton value="future">Prossimi</IonSegmentButton>
                                <IonSegmentButton value="past">Passati</IonSegmentButton>
                            </IonSegment>
                        </div>
                    </div>

                    {
                        period === "future" ?
                            (!!appointments?.length ?
                                <div>
                                    <div className={"mb-4 d-flex"}>
                                        <div className="w-50 pe-2">
                                            <span className={"text-md fw-bold"}>Fornitura</span>
                                            <IonSelect size="small" className="bg-white rounded shadow-sm" placeholder="Seleziona" value={orderToShow} onIonChange={e => setOrderToShow(e.detail.value)}>

                                                <IonSelectOption value="all">Tutte</IonSelectOption>
                                                {
                                                    filterableOrders &&
                                                    Object.keys(filterableOrders).map((key, index) =>
                                                        <IonSelectOption value={key} key={index}>
                                                            {filterableOrders[key].name.trim() === "Extra Furniture" ? "Arredo Extra" : filterableOrders[key].name} - {filterableOrders[key].so_name}
                                                        </IonSelectOption>
                                                    )
                                                }
                                            </IonSelect>
                                        </div>

                                        <div className="w-50 ps-2">
                                            <span className={"text-md fw-bold"}>Tipologia</span>

                                            <IonSelect className="bg-white rounded shadow-sm" placeholder="Seleziona" value={tagToShow} onIonChange={e => setTagToShow(e.detail.value)}>

                                                <IonSelectOption value="all">Tutte</IonSelectOption>
                                                {
                                                    filterableOrders &&
                                                    Object.keys(filterableTags).map((key, index) =>
                                                        <IonSelectOption value={key} key={index}>
                                                            {key}
                                                        </IonSelectOption>
                                                    )
                                                }
                                            </IonSelect>
                                        </div>

                                    </div>
                                    <div>
                                        {
                                            (appointments.length && filterableOrders) ?
                                                appointments.map(({
                                                    name,
                                                    start_datetime,
                                                    stop_datetime,
                                                    description,
                                                    so,
                                                    so_name,
                                                    tags,
                                                    location
                                                }, key) => (

                                                    <div key={key}>
                                                        {
                                                            ((["all", String(so)].indexOf(orderToShow) !== -1) && (["all", ...tags].indexOf(tagToShow) !== -1)) &&
                                                            <EventItem
                                                                location={location}
                                                                so={so}
                                                                so_name={so_name}
                                                                tags={tags}
                                                                filterableOrders={filterableOrders}
                                                                filterableTags={filterableTags}
                                                                key={key} title={name}
                                                                startTime={start_datetime}
                                                                endTime={stop_datetime}
                                                                tooltipContent={description} />
                                                        }
                                                    </div>

                                                ))
                                                :
                                                <IonSpinner />
                                        }

                                    </div>
                                </div>
                                :
                                <div className="container my-md-5 text-center">
                                    Nessun appuntamento presente.
                                </div>)
                            :
                            (!!pastAppointments?.length ?
                                <div>

                                    <div className={"mb-4 d-flex"}>
                                        <div className="w-50 pe-2">
                                            <span className={"text-md fw-bold"}>Fornitura</span>
                                            <IonSelect size="small" className="bg-white rounded shadow-sm" placeholder="Seleziona" value={pastOrderToShow} onIonChange={e => setPastOrderToShow(e.detail.value)}>

                                                <IonSelectOption value="all">Tutte</IonSelectOption>
                                                {
                                                    filterableOrders &&
                                                    Object.keys(pastFilterableOrders).map((key, index) =>
                                                        <IonSelectOption value={key} key={index}>
                                                            {pastFilterableOrders[key].name}
                                                        </IonSelectOption>
                                                    )
                                                }
                                            </IonSelect>
                                        </div>

                                        <div className="w-50 ps-2">
                                            <span className={"text-md fw-bold"}>Tipologia</span>

                                            <IonSelect className="bg-white rounded shadow-sm" placeholder="Seleziona" value={pastTagToShow} onIonChange={e => setPastTagToShow(e.detail.value)}>

                                                <IonSelectOption value="all">Tutte</IonSelectOption>
                                                {
                                                    pastFilterableOrders &&
                                                    Object.keys(pastFilterableTags).map((key, index) =>
                                                        <IonSelectOption value={key} key={index}>
                                                            {key}
                                                        </IonSelectOption>
                                                    )
                                                }
                                            </IonSelect>
                                        </div>
                                    </div>
                                    <div>
                                        {
                                            (pastFilterableOrders) ?
                                                pastAppointments.map(({
                                                    id,
                                                    name,
                                                    start_datetime,
                                                    stop_datetime,
                                                    description,
                                                    so,
                                                    so_name,
                                                    tags,
                                                    location
                                                }, key) => (

                                                    <div key={key}>
                                                        {
                                                            ((["all", String(so)].indexOf(pastOrderToShow) !== -1) && (["all", ...tags].indexOf(pastTagToShow) !== -1)) &&
                                                            <EventItem
                                                                location={location}
                                                                so={so}
                                                                so_name={so_name}
                                                                tags={tags}
                                                                filterableOrders={pastFilterableOrders}
                                                                filterableTags={pastFilterableTags} key={key}
                                                                title={name}
                                                                startTime={start_datetime}
                                                                endTime={stop_datetime}
                                                                tooltipContent={description} />
                                                        }
                                                    </div>

                                                ))
                                                :
                                                <IonSpinner />
                                        }

                                    </div>

                                </div>
                                :
                                <div className="container my-md-5 text-center">
                                    Nessun appuntamento presente.
                                </div>)
                    }
                </div>

            </IonContent>
        </IonPage>
    )
}

const EventItem = ({
    startTime: startTimeToParse,
    endTime: endTimeToParse,
    tooltipContent,
    so,
    so_name,
    tags,
    filterableOrders,
    filterableTags,
    location
}) => {


    const startTime = startTimeToParse.split(" ")[0];
    const startHours = startTimeToParse.split(" ")[1];

    const endTime = endTimeToParse.split(" ")[0];
    const endHours = endTimeToParse.split(" ")[1];

    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    startDate.setHours(startHours.split(":")[0], startHours.split(":")[1]);
    endDate.setHours(endHours.split(":")[0], endHours.split(":")[1]);

    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {

        for (let tag of tags) {
            setShow(filterableTags[tag]?.show && filterableOrders[so]?.show);
        }
    }, [filterableOrders, filterableTags]);

    return (

        <AnimatePresence>
            {
                show &&
                <motion.div
                    key="content" initial={{ opacity: 0, translateY: 10 }} animate={{ opacity: 1, translateY: 0 }}
                    exit={{ opacity: 0, translateY: 10 }}>
                    <IonBtn onClick={() => setShowModal(true)} color="white" expand="block"
                        className="d-flex shadow-sm mb-3 px-0 h-100">
                        <div
                            className="d-flex justify-content-between ion-text-left w-100 my-3 d-block align-items-center">
                            <div>
                                {
                                    !!tags?.length &&
                                    <div className="mb-1 text-md text-uppercase opacity-75">{so_name} • {tags[0]}</div>
                                }
                                <div className={"h5 font-weight-bold mb-1"}>{startDate.getDate()}&nbsp;
                                    {months[startDate.getMonth()]}&nbsp;
                                    {startDate.getFullYear()}
                                </div>
                                <div className="opacity-50">
                                    dalle
                                    ore {(startDate.getHours())}:{(startDate.getMinutes() < 10 ? '0' : '') + startDate.getMinutes()}&nbsp;alle
                                    ore {(endDate.getHours())}:{(endDate.getMinutes() < 10 ? '0' : '') + endDate.getMinutes()}
                                </div>

                            </div>

                            <IonIcon icon={chevronForward} className="opacity-50" slot="end" />
                        </div>
                    </IonBtn>
                </motion.div>
            }


            <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)} title={'Descrizione Appuntamento'}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Dettaglio appuntamento</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setShowModal(false)}>
                                Chiudi
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <div className={"font-weight-bold my-2 p-3"}>
                    <h3 className="mb-0">
                        {startDate.getDate()}&nbsp;
                        {months[startDate.getMonth()]}&nbsp;
                        {startDate.getFullYear()}
                    </h3>

                    <div className="mb-2">
                        dalle
                        ore {(startDate.getHours())}:{(startDate.getMinutes() < 10 ? '0' : '') + startDate.getMinutes()}&nbsp;alle
                        ore {(endDate.getHours())}:{(endDate.getMinutes() < 10 ? '0' : '') + endDate.getMinutes()}
                    </div>

                    {
                        (location && location !== 'False') &&
                        <div className="mb-3">{
                            (location.substring(0, 4) === 'http' || location.substring(0, 4) === 'www.' || location.substring(0, 4) === 'meet') ?
                                <div>
                                    <div>In video call al link:</div>
                                    <a className={"text-blue"} target={"_blank"} href={`https://${location}`} rel="noreferrer">
                                        {location}
                                    </a>

                                </div>
                                :
                                <div>
                                    Presso&nbsp;
                                    {location}
                                </div>
                        }</div>
                    }
                    <div>Descrizione</div>
                    <p href={tooltipContent} dangerouslySetInnerHTML={{ __html: tooltipContent.replace(/\n/g, "<br />") }}></p>
                </div>
            </IonModal>
        </AnimatePresence>
    )
}
