import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {App, URLOpenListenerEvent} from '@capacitor/app';


const AppUrlListener: React.FC<any> = () => {
    let history = useHistory();

    useEffect(() => {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            // Example url: https://beerswift.app/tabs/tab2
            // slug = /tabs/tab2
            const slug = event.url.split('home-j-deeplink/').pop();

            if (slug) {
                window.location.href = slug;
            }
            // If no match, do nothing - let regular routing
            // logic take over
        });
    }, [history]);

    return null;
};

export default AppUrlListener;



