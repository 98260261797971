import { IonIcon } from "@ionic/react";
import { radioButtonOff, radioButtonOn } from "ionicons/icons";
import { useEffect, useState } from "react";

export const IconRange = ({
    id,
    title,
    answers,
    isDot,
    onChange,
    setStepValue
}) => {
    const [active, setActive] = useState(null);

    useEffect(() => {
        onChange && onChange(active);
    }, [active, onChange]);

    useEffect(() => {
        const storedValues = JSON.parse(localStorage.getItem("surveyValues")) || [];
        const flattenedValues = storedValues.flat().filter(value => value !== null);
        const storedAnswer = flattenedValues.find((value) => value.id === id);

        if (storedAnswer && storedAnswer.value !== null) {
            setActive(storedAnswer.value);
        }
    }, [id]);

    const changedValue = (index) => {
        const answerId = answers[index].id;
        setActive(answerId);

        setTimeout(() => {
            setStepValue({ id, value: answerId });
        }, 0);
    };

    return (
        <div className="mb-2 pb-3 border-bottom text-center">
            <span className={"text-md fw-bold"}>{title}</span>
            <div className={isDot ? "" : " bg-white rounded shadow-sm py-3 mt-2"}>
                <div className="d-flex justify-content-around mt-2 pt-1 px-5 mx-3" style={{ color: "#ffc900" }}>
                    {isDot ? (
                        <>
                            {[...new Array(7)].map((el, index) => (
                                <HomeIcon
                                    key={index}
                                    isDot
                                    onClick={() => changedValue(index)}
                                    active={active > index}
                                />
                            ))}
                        </>
                    ) : (
                        <>
                            {answers.map((answer, index) => (
                                <HomeIcon
                                    key={index}
                                    index={index}
                                    onClick={() => changedValue(index)}
                                    active={active === answer.id}
                                />
                            ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export const HomeIcon = ({ active, onClick, isDot, index }) => {
    return (
        <>
            {isDot ? (
                <div className="px-1" onClick={onClick} style={{ fontSize: 36 }}>
                    {active ? <IonIcon icon={radioButtonOn} /> : <IonIcon icon={radioButtonOff} />}
                </div>
            ) : (
                <div onClick={onClick} className="px-2 pb-1 pt-2">
                    <img
                        style={active ? {} : { filter: "saturate(0)" }}
                        src={`/assets/icon/home_icon-step-${index + 1}.png`}
                        alt={`icon-${index + 1}`}
                    />
                </div>
            )}
        </>
    );
};
