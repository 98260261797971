import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import OneSignal from 'onesignal-cordova-plugin';
import {AuthProvider} from "./contexts/AuthContext";
import {isPlatform} from '@ionic/react';
import {PushNavLink} from "./helpers/helpers";
import { defineCustomElements } from '@ionic/pwa-elements/loader';
if (isPlatform('cordova')) {
    OneSignalInit();
}

ReactDOM.render(
    <React.StrictMode>
        <AuthProvider>
            <App/>
        </AuthProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Call this function when your app starts
function OneSignalInit(): void {

    // Uncomment to set OneSignal device logging to VERBOSE
    OneSignal.Debug.setLogLevel(6);

    OneSignal.initialize("d0700847-102b-4650-bb4f-db2eb84b2d08");

    OneSignal.Notifications.addEventListener('click', async (e) => {
        let clickData = await e.notification;
        PushNavLink(clickData)
    })
}
defineCustomElements(window);
