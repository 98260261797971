import React, {useContext, useState} from "react";
import {AuthContext} from "../contexts/AuthContext";
import {IonButton, IonButtons, IonHeader, IonMenuButton, IonModal, IonTitle, IonToolbar} from "@ionic/react";
import {IonBtn} from "./IonBtn";

export const Tutorial = () => {

    const {showTutorial, setShowTutorial} = useContext(AuthContext);

    const [currentStep, setCurrentStep] = useState(0);

    const nextStep = () => {
        if (currentStep < tutorialData.length - 1) {
            setCurrentStep(currentStep => currentStep + 1);
        } else {
            closeTutorial();
        }
    }
    const prevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep => currentStep - 1);
        }
    }

    const closeTutorial = () => {
        setCurrentStep(0);
        setShowTutorial(false);
    }

    const tutorialData = [
        {
            title: "Benvenuto in Home-J",
            body: "L’innovativa Mobile App Home-J è disponibile su Apple e Play Store.<br><br>" +
                "Questo breve tutorial ti giuderà all’utilizzo" +
                " dell’applicazione<br><br>" +
                "Clicca “Avanti” per iniziare",
            imgSrc: "/assets/img/tutorial/step_1.jpg",
            youtubeId: ""
        },
        {
            title: "Tutorial sempre con te",
            body: "In qualsiasi momento potrai consultare questo tutorial disponibile nel menu a tendina.",
            imgSrc: "/assets/img/tutorial/step_2.jpg",
            youtubeId: "",
        },
        {
            title: "La tua casa",
            body: "I migliori Brand di Interior Design sono a tua disposizione.<br>" +
                "Scoprili qui per personalizzare la tua nuova casa.",
            youtubeId: "F3bnbbJA-eQ",
        },
        {
            title: "Agenda",
            body: "I tuoi appuntamenti sono sempre consultabili.<br><br>Usa i filtri!<br>" +
                "Scopri i dettagli e le modalità di svolgimento.<br/><br/>Riceverai una notifica ogni volta che un nuovo appuntamento sarà convocato.",
            youtubeId: "JjLlhrAiFjY",
        },
        {
            title: "Documenti",
            body: "Un archivio condiviso a portata di click.<br/>" +
                "Contratti, progetti e verbali consultabili e scaricabili in formato pdf.<br/><br/>" +
                "Riceverai una notifica ogni volta che un nuovo documento sarà caricato nella tua Area Riservata",
            youtubeId: "Co6QP22we4E",
        },
        {
            title: "Documenti da firmare",
            body: "Firma i tuoi documenti direttamente dall’App.<br/>" +
                "<br/>" +
                "Una volta firmato il documento, lo potrai trovare (firmato) all’interno dell’Area “Documenti”.<br/>" +
                "<br/>" +
                "Riceverai una notifica ogni volta che un nuovo documento da firmare sarà caricato nella tua Area Riservata (cfr. sezione “Documenti da Firmare”)",
            youtubeId: "anVMyYN2iss",
        },
        {
            title: "Comunicazioni",
            body: "Un canale di comunicazione a te riservato.<br><br>" +
                "Contatta i nostri uffici per avere tutte le informazioni di cui hai bisogno. Inserisci:<br>" +
                "Destinatario<br>" +
                "CC<br>" +
                "Altre mail (e.g. nome.cognome@gmail.com)\n",
            youtubeId: "7meJWanAz54",
        },
        {
            title: "Home Tracker",
            body: "Tieni sotto controllo i tuoi ordini!<br><br>Consulta le FAQ dedicate ad ogni step.",
            youtubeId: "XGN2ABHAUOA",
        },
        {
            title: "Assistenza Post Vendita",
            body: "Home-J resterà con te!<br><br>" +
                "Dopo la consegna potrai consultare il servizio post vendita per attivare una richiesta di assistenza.",
            youtubeId: "jK1WTW8BETo",
        },
        {
            title: "Survey",
            body: "Compila le Survey per aiutarci a misurare la qualità del nostro servizio, con l’obiettivo di renderlo sempre migliore.<br><br>" +
                "Riceverai una notifica ogni volta che una nuova Survey verrà aggiunta alla Sezione \"Survey\"",
            youtubeId: "OphJeRwZMN4",
        },
        {
            title: "Notifiche",
            body: "Una sezione dedicata per consultare le Notifiche in-App",
            imgSrc: "/assets/img/tutorial/step_3.jpg",
        },
    ]

    return (
        <IonModal title={"Tutorial MCD"} isOpen={showTutorial} onDidDismiss={closeTutorial}>

            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Tutorial
                    </IonTitle>

                    <IonButtons slot="end" >
                        <IonButton onClick={closeTutorial}>
                            Chiudi
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <div className={"p-3"} style={{minHeight: 250}}>
                <div>
                    <div className={"text-muted text-medium text-uppercase"}>
                        Step {currentStep + 1} di {tutorialData.length}
                    </div>
                    <div className={"h4 font-weight-bold mt-2 mb-3"}>{tutorialData[currentStep] && tutorialData[currentStep].title}</div>
                    <p dangerouslySetInnerHTML={{__html: tutorialData[currentStep].body}}></p>
                </div>
                <div className={"px-0 d-flex align-items-center"}>
                    {
                        tutorialData[currentStep].youtubeId ?
                            <iframe width="560" height="300"
                                    src={"https://www.youtube.com/embed/" + tutorialData[currentStep].youtubeId}
                                    frameBorder="0"
                                    className={"w-100"}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            :
                            <img src={tutorialData[currentStep].imgSrc} className={"w-100"}/>
                    }
                </div>
            </div>
            <div className={"border-top pt-3 mt-3 mx-3 d-flex justify-content-between"}>
                <div>
                    {
                        (currentStep !== 0) &&
                        <IonBtn onClick={prevStep} color="light">Indietro</IonBtn>
                    }
                </div>

                <IonBtn onClick={nextStep} color="dark">
                    {
                        (currentStep === tutorialData.length - 1) ?
                            "Chiudi"
                            :
                            "Avanti"
                    }
                </IonBtn>

            </div>
        </IonModal>
    )
}
