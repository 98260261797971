import {
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonItem,
    IonLabel,
    IonLoading,
    IonModal,
    IonPage,
    IonRow,
    IonToggle,
    IonToolbar,
    useIonViewWillEnter
} from "@ionic/react";
import { useState } from "react";
import SignatureCanvas from 'react-signature-canvas';
import { retrieveDocumentsToBeSigned, uploadSignature } from "../api";
import { Collapsable } from "../components/Collapsable";
import { Header } from "../components/Header";
import { logoHeight, logoWidth } from "./residence/selectApartment";

import "./signatureCanvas.css";
export const DocumentiDaFirmare = () => {

    const [documents, setDocuments] = useState(null);

    const retrieveAllDocument = async () => {
        try {
            const { data } = await retrieveDocumentsToBeSigned();
            setDocuments(data?.rows || []);
        } catch (err) {
            setDocuments([]);
            console.log(err);
        }
    };

    useIonViewWillEnter(() => {
        retrieveAllDocument();
    }, []);

    const unsetDocument = (index) => {
        setTimeout(() => {
            retrieveAllDocument()
        }, 500)

    }
    return (
        <IonPage>
            <Header />
            <IonContent color="light">

                <div className="p-3">
                    <h1>Documenti da firmare</h1>


                    {
                        documents ?
                            <>
                                {
                                    !!documents.length ?
                                        <div className={"row"}>
                                            <>{
                                                documents.map((data, i) => {
                                                    return (
                                                        <DocumentCard key={i}
                                                            index={i}
                                                            unsetDocument={unsetDocument}
                                                            id={data.id}
                                                            name={data.name}
                                                            category_name={data.category_name}
                                                            residence_name={data.residence_name}
                                                            apartment_name={data.apartment_name}
                                                            document_to_sign={data.document_to_sign}
                                                            residence={data.residence}
                                                        />
                                                    )
                                                }
                                                )
                                            }
                                            </>
                                        </div>
                                        :
                                        <p className="opacity-75 text-center text-sm">Nessun documento disponibile</p>
                                }
                            </>
                            :
                            <p className="opacity-75 text-center text-sm">Carico...</p>
                    }

                </div>


            </IonContent>
        </IonPage>

    )
}

const DocumentCard = ({
    index,
    id,
    name,
    category_name,
    residence_name,
    apartment_name,
    document_to_sign,
    unsetDocument,
    app_color_brand,
    residence
}) => {
    const sessionId = localStorage.getItem('sessionId');
    const [showModal, setShowModal] = useState(false);
    const [sigPad, setSigPad] = useState({});
    const [documentViewed, setDocumentViewed] = useState(false);
    const [policyAccepted, setPolicyAccepted] = useState(false);
    const [signature, setSignature] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    let session_id = localStorage.getItem('sessionId');

    const retrieveImage = async () => {
        setSignature(sigPad.getTrimmedCanvas().toDataURL('image/png').replace(/^data:image\/[a-z]+;base64,/, "").replace(/^data:application\/[a-z]+;base64,/, ""));
    }

    const sendSignature = () => {
        setShowLoading(true);
        try {
            uploadSignature(id, { signature }).then((data) => {
                if (showModal) setShowModal(false);
                if (showModal) setShowLoading(false);

                if (unsetDocument) {
                    unsetDocument(index);
                }
            });
        } catch (err) {
            setShowLoading(false);
            console.log(err);
        }
    }

    const openedDocument = (opened) => {
        setDocumentViewed(opened);
    }

    const canSingDocument = () => {
        return !localStorage.getItem('invitation');
    }

    const resetState = () => {
        setSigPad({});
        setDocumentViewed(false);
        setPolicyAccepted(false);
        setSignature(false);
        setShowLoading(false);
    }

    return (
        <>
            {document_to_sign ?
                <div className={"col-6 col-md-4 mb-4"} onClick={() => setShowModal(true)}>
                    <div
                        className="d-flex flex-column bg-white rounded border-lg px-3 pb-3 pt-4 cursor-pointer shadow rounded-xl h-100">
                        <div className="d-flex gap-2 pt-2">
                            <div className="rounded-pill text-md bg-dark text-white px-3 py-1 mx-auto"
                                style={{ fontSize: "0.6rem" }}>{residence_name} - {apartment_name}</div>
                        </div>
                        <div className={"px-md-4 pt-2"}>
                            <img onError={(e) => {
                                e.target.src = "/assets/img/icons/documenti/altro.png"
                            }}
                                alt={'Docuemnto'}
                                className="max-w-100"
                                width="200"
                                height="150"
                                style={{ objectFit: "contain" }}
                                src={'/assets/img/icons/documenti/' + category_name + '.png'} />
                        </div>
                        <div
                            className={"d-flex justify-content-center flex-fill align-items-center mt-2 px-3"}>
                            <div
                                className="text-center font-weight-bold">{name}</div>
                        </div>
                    </div>
                </div>
                :
                <div></div>
            }

            <IonModal isOpen={showModal} onDidDismiss={() => {
                resetState();
                setShowModal(false);
            }}>

                <IonHeader>
                    <IonToolbar>
                        <IonRow>
                            <div className="header-logo">
                                <img className="cst-img-margin" width={logoWidth} height={logoHeight}
                                    onError={(e) => {
                                        e.target.src = "/assets/logo-mcd.png"
                                    }}
                                    src={'/assets/home_j-logo.svg'} />
                            </div>
                            <img className="cst-img-margin" style={{ visibility: "hidden" }} width={logoWidth}
                                height={logoHeight} />
                        </IonRow>

                        <IonButtons slot="end">
                            <IonButton onClick={() => setShowModal(false)}>
                                Chiudi
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <Collapsable
                        loading={false}
                        documentUrl={"/mcd_residence_api/private/document/" + id + "?session_id=" + sessionId}
                        title={name}
                        openedDocument={openedDocument}
                        canSingDocument={canSingDocument}
                    />
                    {canSingDocument() && (
                        <>
                            <IonItem className={'mt-3 mb-3'}>
                                <IonLabel text-wrap>
                                    Conferma di aver preso visione del documento nella sua interezza e di voler procedere alla firma dello stesso
                                </IonLabel>
                                <IonToggle slot="start" onIonChange={() => {
                                    setPolicyAccepted(!policyAccepted);
                                }}></IonToggle>
                            </IonItem>
                        </>
                    )}
                    {canSingDocument() && (
                        <>
                            {policyAccepted && documentViewed ? (
                                <>
                                    <p style={{ textAlign: "center" }} className={"mt-4"}>Inserisci qui la tua firma</p>
                                    <div style={{ border: "1px solid #ffc409" }} className={"mx-2 my-2"}>
                                        <SignatureCanvas
                                            penColor='black'
                                            canvasProps={{ className: "signatureCanvas" }}
                                            ref={(ref) => { setSigPad(ref) }}
                                            onEnd={retrieveImage}
                                        />
                                        <IonButton expand="block" disabled={!signature}
                                            color={"primary"}
                                            onClick={() => {
                                                sigPad.clear();
                                                setSignature(false);
                                            }}>
                                            Reset
                                        </IonButton>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <p style={{ textAlign: "center" }} className={"mt-4"}>
                                        Per inserire la firma è necessario<br />
                                        visualizzare il pdf e accettare le condizioni
                                    </p>
                                </>
                            )}
                        </>
                    )}
                </IonContent>
                {canSingDocument() && (
                    <IonFooter>
                        <IonButton expand="block" disabled={!signature}
                            color={"primary"}
                            onClick={async () => {
                                setShowLoading(true);
                                await sendSignature();
                            }}>
                            Salva
                        </IonButton>
                    </IonFooter>
                )}
            </IonModal>
            <IonLoading
                isOpen={showLoading}
                message={'Attendere la firma del documento...'}
            />
        </>
    );
}
