import React from "react";
import { IonBtn } from "./IonBtn";

const IconCard = ({ name, disabled, onClick }) => {

    const cardTypes = {
        casa: {
            cardTitle: "La tua casa",
            iconUrl: '/assets/img/icons/home-icon.png'
        },
        agenda: {
            cardTitle: "Agenda",
            iconUrl: '/assets/img/icons/agenda-icon.png'
        },
        tracker: {
            cardTitle: "Home Trackers",
            iconUrl: '/assets/img/icons/home-tracker-icon.png'
        },
        ticket: {
            cardTitle: "Comunicazioni",
            iconUrl: '/assets/img/icons/ticket-icon.png'
        },
        documenti: {
            cardTitle: "Documenti",
            iconUrl: '/assets/img/icons/documenti-icon.png'
        },
        support: {
            cardTitle: "Assistenza Post Vendita",
            iconUrl: '/assets/img/icons/support-icon.png'
        }
    }

    return (
        <IonBtn style={{ minHeight: 202 }} onClick={() => (!disabled && onClick) && onClick()} disabled={disabled} expand="block" color="white" className="shadow h-100">
            <div className="d-flex flex-column align-items-center py-3 w-100 h-100">
                <img src={cardTypes[name].iconUrl} width="100" height="100" className="border border-primary rounded-pill thick" />
                <span className="ion-text-wrap pt-2 flex-fill d-flex flex-column justify-content-center" style={{ lineHeight: 1.3 }}>
                    <span>{cardTypes[name].cardTitle}</span>
                </span>
            </div>
        </IonBtn>
    )
}
export default IconCard;
