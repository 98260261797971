import {IonButton} from "@ionic/react";
import {motion} from "framer-motion";
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import {Capacitor} from "@capacitor/core";

export const IonBtn = (props) => {

    const {children, disabled, handleClick, className, size, expand} = props;

    return <motion.div
        onClick={() => !disabled && Capacitor.isNativePlatform() && Haptics.impact({style: ImpactStyle.Light})}
        transition={{scaleX: {type: "spring", bounce: 0.7}, scaleY: {type: "spring", bounce: 0.7}}} whileTap={!disabled && {scaleY: 0.95, scaleX: 0.97, filter: "brightness(0.9)"}}>
        <IonButton onClick={() => handleClick && handleClick()} {...props} mode="ios" className={"m-0 rounded " + className + (size === "small" ? " text-small" : "")}>
            {children}
        </IonButton>
    </motion.div>
}
