import { IonIcon } from "@ionic/react";
import { thumbsDownOutline, thumbsUpOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { HomeIcon } from "./IconRange";

export const SurveyMatrix = ({
    id,
    title,
    answers,
    matrixs,
    setStepValue
}) => {

    const [val, setVal] = useState(null);
    const [active, setActive] = useState(null);

    useEffect(() => {
        const storedValues = JSON.parse(localStorage.getItem("surveyValues")) || [];
        const flattenedValues = storedValues.flat().filter(value => value !== null);
        const storedAnswer = flattenedValues.find((value) => value.id === id);

        if (storedAnswer && storedAnswer.value !== null) {
            setActive(storedAnswer.value);
        }
    }, [id]);

    const changedValue = (event) => {
        setActive(event);

        setTimeout(() => {
            setStepValue({ id, value: event });
        }, 0);
    };

    const addToActive = (im, i) => {
        let values = active ? [...active] : [];
        const searchIndex = values.findIndex((value) => value.im === im);
        if (searchIndex >= 0) {
            values.splice(searchIndex, 1);
        }
        values.push({
            im,
            answer: i + 1,
            matrix_id: matrixs[im].id,
            answer_id: answers[i].id,
            answer_label: answers[i].value
        });

        setActive(values);
        return values;
    };

    return (
        <div className="mb-2 pb-3 border-bottom">
            <span className={"text-md fw-bold"}>{title}</span>

            {matrixs.map((matrix, im) => (
                <div key={im}>
                    <div className="rounded bg-white py-3 px-1 shadow-sm mt-2">
                        <div className="text-dark text-md fw-bold d-flex justify-content-center">
                            <span>{matrix.value}</span>
                        </div>
                        {answers.length > 2 && (
                            <div className="text-dark text-md fw-bold d-flex justify-content-center">
                                <span>
                                    {val && val.findIndex((value) => value.im === im) >= 0 ? (
                                        val[
                                            active.findIndex((value) => value.im === im)
                                        ].answer_label
                                    ) : (
                                        <span className="opacity-50 fw-normal">
                                            Nessuna selezione
                                        </span>
                                    )}
                                </span>
                            </div>
                        )}

                        <div
                            className={`mb-2 text-center ${answers.length > 2 ? " pb-3 border-bottom" : ""
                                }`}
                        >
                            <div>
                                <div
                                    className="d-flex justify-content-around mt-2 pt-1 px-5 mx-3"
                                    style={{ color: "#ffc900" }}
                                >
                                    {answers.length > 2 &&
                                        answers.map((answer, i) => (
                                            <HomeIcon
                                                key={i}
                                                id={im}
                                                isDot={true}
                                                onClick={() => {
                                                    const values = addToActive(im, i);
                                                    if (values.length === matrixs.length)
                                                        changedValue(values);
                                                    setVal([...values]);
                                                }}
                                                active={
                                                    active &&
                                                    active.findIndex((value) => value.im === im) >= 0 &&
                                                    active[
                                                        active.findIndex((value) => value.im === im)
                                                    ].answer > i
                                                }
                                            />
                                        ))}

                                    {answers.length <= 2 &&
                                        answers.map((answer, i) => {
                                            const iconType = i === 0 ? thumbsDownOutline : thumbsUpOutline;
                                            let color = "medium";
                                            if (
                                                active &&
                                                active.findIndex(
                                                    (value) => value.answer_id === answer.id && value.im === im
                                                ) >= 0
                                            ) {
                                                color = "primary";
                                            }

                                            return (
                                                <IonIcon
                                                    key={i}
                                                    id={im}
                                                    slot="start"
                                                    className={"icon-lg"}
                                                    color={color}
                                                    ios={iconType}
                                                    md={iconType}
                                                    onClick={() => {
                                                        const values = addToActive(im, i);
                                                        if (values.length === matrixs.length) changedValue(values);
                                                        setVal([...values]);
                                                    }}
                                                />
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                        {answers.length > 2 && (
                            <>
                                <div className="px-2 mx-1 text-sm d-flex justify-content-between align-items-center opacity-75">
                                    <span>{answers[0].value}</span>
                                    <span className="ion-text-right">
                                        {answers[answers.length - 1].value}
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};
