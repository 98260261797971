export const PushNavLink = (jsonData) => {
    if(jsonData.notification && jsonData.notification.launchURL){

        const slug = jsonData.notification.launchURL.split('home-j-deeplink/').pop();
        if (slug) {
            window.location.href = slug
            // this.props.history.push(slug);
        }
    }
    return null;
}
