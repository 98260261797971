import {
    IonApp,
    IonPage,
    IonRouterOutlet,
    IonSplitPane,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import SideMenu from './components/SideMenu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import { useContext } from "react";
import { AuthContext } from "./contexts/AuthContext";
import { LoadingPage } from "./pages/LoadingPage";
import { Login } from "./pages/Login";
import './theme/index.scss';
import './theme/variables.css';

import { setupIonicReact } from '@ionic/react';
import { Tutorial } from "./components/Tutorial";
import { FirstTimeAccess } from './components/firstTimeAccess';
import { ForgotPasswordAccess } from './components/forgotPasswordAccess';
import { PrivacyModal } from "./components/privacy-modal";
import { ResetPasswordModal } from './components/resetPasswordModal';
import AppUrlListener from './pages/AppUrlListener';
import { DocumentiDaFirmare } from "./pages/DocumentiDaFirmare";
import { GestisciOspiti } from "./pages/Gestisci-Ospiti";
import { Notifications } from "./pages/Notifications";
import { SelectResidence } from "./pages/SelectResidence";
import { Survey } from "./pages/Survey";
import { Surveys } from "./pages/Surveys";
import { FaqPage } from "./pages/faq-page";
import { Profile } from "./pages/profile";
import { Agenda } from "./pages/residence/apartment/agenda";
import Capitolati from "./pages/residence/apartment/capitolati";
import Casa from "./pages/residence/apartment/casa";
import { Comunicazioni } from "./pages/residence/apartment/comunicazioni";
import { Chat } from "./pages/residence/apartment/comunicazioni/chat";
import { Dashboard } from "./pages/residence/apartment/dashboard";
import { Documenti } from "./pages/residence/apartment/documenti";
import { HomeTracker } from "./pages/residence/apartment/home-tracker";
import InteriorPack from "./pages/residence/apartment/interior-pack";
import { PostVendita } from "./pages/residence/apartment/post-vendita";
import SupplierCards from "./pages/residence/apartment/supplier_cards";
import TagsCards from "./pages/residence/apartment/tags-cards";
import TuaCasa from "./pages/residence/apartment/tua-casa";
import { SelectApartment } from "./pages/residence/selectApartment";


setupIonicReact({
    swipeBackEnabled: false,
});

const App = () => {

    const { user } = useContext(AuthContext);

    if (!user) {
        return <LoadingPage />
    }

    return (
        <IonApp>
            <IonReactRouter>
                <AppUrlListener></AppUrlListener>
                <IonRouterOutlet>


                    <Route
                        exact
                        path="/"
                        render={() => (
                            user?.email ?
                                <Redirect to="/residence" />
                                :
                                <Redirect to="/login" />
                        )}
                    />

                    <Route
                        exact
                        path="/login"
                        render={() => (
                            user?.email ?
                                <Redirect to="/residence" />
                                :
                                <Login />
                        )}
                    />


                    <Route
                        path="/residence"
                        render={() => (
                            user?.email ?
                                <IonSplitPane contentId="main">
                                    <SideMenu />

                                    <IonPage id="main">

                                        <IonRouterOutlet id="main">


                                            <Route path="/residence/:residenceId/apartment/:apartmentId/casa"
                                                exact={true}
                                                render={props => <Casa {...props} />} />

                                            <Route path="/residence/:residenceId/apartment/:apartmentId/tua-casa"
                                                exact={true}
                                                render={props => <TuaCasa {...props} />} />

                                            <Route path="/residence/:residenceId/apartment/:apartmentId/capitolati"
                                                exact={true}
                                                render={props => <Capitolati {...props} />} />

                                            <Route
                                                path="/residence/:residenceId/apartment/:apartmentId/tua-casa/:tagId"
                                                exact={true}
                                                render={props => <TagsCards {...props} />} />


                                            <Route
                                                path="/residence/:residenceId/apartment/:apartmentId/tua-casa/:tagId/supplier/:supplierId"
                                                exact={true}
                                                render={props => <SupplierCards {...props} />} />

                                            <Route
                                                path="/residence/:residenceId/apartment/:apartmentId/interior-pack"
                                                exact={true}
                                                render={props => <InteriorPack {...props} />} />

                                            <Route path="/residence/:residenceId/apartment/:apartmentId/agenda"
                                                exact={true}
                                                render={props => <Agenda {...props} />} />

                                            <Route path="/residence/:residenceId/apartment/:apartmentId/documenti"
                                                exact={true}
                                                render={props => <Documenti {...props} />} />

                                            <Route
                                                path="/residence/:residenceId/apartment/:apartmentId/home-tracker"
                                                exact={true}
                                                render={props => <HomeTracker {...props} />} />

                                            <Route
                                                path="/residence/:residenceId/apartment/:apartmentId/post-vendita"
                                                exact={true}
                                                render={props => <PostVendita {...props} />} />

                                            <Route path="/residence/:residenceId/apartment/:apartmentId"
                                                exact={true}
                                                render={props => <Dashboard {...props} />} />

                                            <Route path="/residence/:residenceId/selectApartment" exact={true}
                                                render={props => <SelectApartment {...props} />} />


                                            <Route
                                                path="/residence/:residenceId/apartment/:apartmentId/comunicazioni"
                                                exact={true}
                                                render={props => <Comunicazioni {...props} />} />

                                            <Route
                                                path="/residence/:residenceId/apartment/:apartmentId/comunicazioni/:chatId"
                                                exact={true}
                                                render={props => <Chat {...props} />} />


                                            <Route path="/residence/:residenceId/" exact={true}
                                                render={({ match }) => {
                                                    return <Redirect
                                                        to={"/residence/" + match.params.residenceId + "/selectApartment"} />
                                                }}>
                                            </Route>
                                            <Route path="/residence/surveys/:surveyId/:saleOrderId/:ticketId?"
                                                exact={true} render={(props) => <Survey {...props} />} >
                                            </Route>
                                            <Route path="/residence/surveys" exact={true}>
                                                <Surveys />
                                            </Route>

                                            <Route path="/residence" exact={true}>
                                                <SelectResidence />
                                            </Route>


                                            <Route path="/residence/faq" exact={true}>
                                                <FaqPage />
                                            </Route>

                                            <Route path="/residence/profile" exact={true}>
                                                <Profile />
                                            </Route>


                                            <Route path="/residence/notifications" exact={true}>
                                                <Notifications />
                                            </Route>

                                            <Route path="/residence/documents" exact={true}>
                                                <DocumentiDaFirmare />
                                            </Route>

                                            {<Route path="/residence/guests" exact={true}>
                                                <GestisciOspiti />
                                            </Route>}

                                        </IonRouterOutlet>
                                        <PrivacyModal />
                                        <Tutorial />
                                        <ResetPasswordModal />
                                        <FirstTimeAccess />
                                        <ForgotPasswordAccess />

                                    </IonPage>


                                </IonSplitPane>
                                :
                                <Redirect to="/login" />
                        )}
                    />
                </IonRouterOutlet>

            </IonReactRouter>
        </IonApp>
    );
};

export default App;
