import {
    IonCheckbox,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { privacyPolicyAccepted, retrievePrivacyPolicy } from "../api";
import { AuthContext } from "../contexts/AuthContext";
import { IonBtn } from "./IonBtn";

export const PrivacyModal = () => {
    const { user, setUser, showPrivacyModal, setShowPrivacyModal, setShowTutorial } = useContext(AuthContext);
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [canAccept, setCanAccept] = useState(false);

    useEffect(() => {
        const fetchPrivacyPolicy = async () => {
            const policy = await retrievePrivacyPolicy();
            if (policy && policy.privacy_policy) {
                setPrivacyPolicy(policy.privacy_policy);
            }
        };
        fetchPrivacyPolicy();
    }, []);

    const closePrivacyModal = async () => {
        await privacyPolicyAccepted(user.id);
        setUser(prevUser => ({ ...prevUser, privacy_accepted: true }));
        setShowPrivacyModal(false);
        if (!localStorage.getItem(user.id)) {
            setShowTutorial(true);
            localStorage.setItem(user.id, 'Privacy and tutorial have been shown for this user');
        }
    };

    return (
        <IonModal isOpen={showPrivacyModal} onDidDismiss={closePrivacyModal} backdropDismiss={false}>
            <IonHeader>
                <IonToolbar className={'ion-text-center'}>
                    <IonTitle mode="md">Informativa sulla privacy</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <div className="px-3">
                    {privacyPolicy && (
                        <div className="pt-3" style={{ maxHeight: 400, whiteSpace: "pre-line" }}>
                            <span dangerouslySetInnerHTML={{ __html: privacyPolicy }}></span>
                        </div>
                    )}
                </div>
            </IonContent>

            <IonFooter>
                {(!user.privacy_accepted) && (
                    <>
                        <IonItem>
                            <IonCheckbox slot="start" onIonChange={(e) => setCanAccept(e.detail.checked)}></IonCheckbox>
                            <IonLabel text-wrap style={{ fontSize: "10px" }}>
                                Ho preso visione dell'Informativa Privacy sul trattamento dei dati personali
                            </IonLabel>
                        </IonItem>
                        {canAccept && (
                            <div className="d-flex justify-content-center pt-3 pb-3">
                                <IonBtn color="dark" onClick={closePrivacyModal}>
                                    Accetto l'informativa sulla privacy
                                    <IonIcon icon={chevronForward} className="ms-2" />
                                </IonBtn>
                            </div>
                        )}
                    </>
                )}
                {(user.privacy_accepted) && (
                    <div className="d-flex justify-content-center pt-3 pb-3">
                        <IonBtn color="light" onClick={closePrivacyModal}>
                            Annulla
                        </IonBtn>
                    </div>
                )}
            </IonFooter>

        </IonModal>
    );
};
