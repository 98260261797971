import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonModal,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { informationCircle } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { api, retrieveHomeTrackingBySaleOrder } from "../../../api";
import { Header } from "../../../components/Header";
import Faq from "../../../components/faq";
import { AuthContext } from "../../../contexts/AuthContext";
import { brandService } from "../../../services/brandService";

const stepsInfos = [
    {
        title: "BENVENUTO IN HOME-J.",
        description: "I nostri consulenti sono a disposizione per personalizzare la sua nuova casa."
    },
    {
        title: "OFFERTA ACCETTATA!",
        description: "Lo staff di Home-J ha trasferito gli impianti e gli esecutivi progettuali in cantiere. Non appena lo stato di avanzamento lavori lo permetterà, un team dedicato effettuerà le verifiche presso il suo appartamento."
    },
    {
        title: "I SUOI PROGETTI SONO STATI VERIFICATI IN CANTIERE!",
        description: "Il team di supervisori di cantiere ha verificato la fattibilità dei progetti in cantiere, riceverà a breve una comuniocazione dai consulenti per eventuali adeguamenti progettuali. La informiamo che, per non gravare sui tempi di inoltro degli ordini, necessiteremo di un suo riscontro nel giro di pochi giorni, chiediamo quindi la sua collaborazione al fine di rispettare i tempi di consegna richiesti."
    },
    {
        description: "Siamo impegnati in verifiche amministrative, sviluppo degli ordini e produzione della merce. Ricevuta la merce, le nostre squadre di tecnici sono impegnate in attività di consegna, sollevamento al piano e montaggio dell’arredo."
    },
    {
        description: "Stiamo verificando la congruità della merce consegnata e montata rispetto al contratto di fornitura"
    },
];

export const HomeTracker = ({ match }) => {
    const apartmentId = match.params.apartmentId;
    const { apartment, setApartment } = useContext(AuthContext);
    const [currentSO, setCurrentSO] = useState();
    const [activeStep, setActiveStep] = useState();
    const [activeFaqs, setActiveFaqs] = useState();
    const [showFaqModal, setShowFaqModal] = useState(false);

    useEffect(() => {
        const retrieveHomeTrackerStep = async () => {
            try {
                const { data } = await retrieveHomeTrackingBySaleOrder(currentSO);
                const { home_tracker_state, faqs } = data;
                setActiveStep(home_tracker_state);
                setActiveFaqs(faqs);
            } catch (err) {
                setActiveStep(null);
                console.log(err);
            }
        };

        if (currentSO) retrieveHomeTrackerStep();
    }, [currentSO]);

    useEffect(() => {
        if (apartment && apartment.sale_order && !!apartment.sale_order.length && !currentSO) {
            const filteredSaleOrder = apartment.sale_order.filter(order => {
                return order.so_typology === "Standard" && (order.home_j_type === "Interior Pack" || order.home_j_type === "Extra Furniture" || order.home_j_type === "IP Plus");
            });
            if (filteredSaleOrder.length > 0) {
                setCurrentSO(filteredSaleOrder[0].id);
            }
        }
    }, [apartment, currentSO]);

    useEffect(() => {
        const sessionId = localStorage.getItem('sessionId');
        let invitation = localStorage.getItem('invitation');
        let invitationParams = '';
        if (invitation) {
            invitation = JSON.parse(invitation);
            invitationParams = '&host_id=' + invitation.host.id + '&guest_id=' + invitation.id
        }

        const url = '/mcd_apartment_api/private/apartment/' + apartmentId + '?session_id=' + sessionId + invitationParams;

        api.get(url, { withCredentials: true })
            .then(({ data: apartment }) => {
                const filteredSaleOrder = apartment.sale_order.filter(order => {
                    return order.so_typology.trim() === "Standard" && (order.home_j_type.trim() === "Interior Pack" || order.home_j_type.trim() === "Extra Furniture" || order.home_j_type.trim() === "IP Plus");

                });
                const filteredApartment = { ...apartment, sale_order: filteredSaleOrder };

                brandService.setData(filteredApartment.residence);
                setApartment(filteredApartment);
            });
    }, []);

    return (
        <IonPage>
            <Header />
            <IonContent>
                <IonText>
                    <h2 className="mb-0 ion-text-center ion-padding-top">Home Tracker</h2>
                </IonText>

                {
                    activeStep ?
                        <>
                            <div className="pt-3 px-3">
                                <div className="w-50 pe-2 mb-4">
                                    <span className={"text-md fw-bold"}>Ordine</span>
                                    <IonSelect size="small" className="bg-white rounded shadow-sm" placeholder="Seleziona" defaultValue={currentSO} value={currentSO} onIonChange={e => setCurrentSO(e.detail.value)}>
                                        {
                                            !!apartment?.sale_order?.length && apartment.sale_order.map(({
                                                id,
                                                home_j_type
                                            }) =>
                                                <IonSelectOption key={id} value={id}>
                                                    {home_j_type}
                                                </IonSelectOption>
                                            )
                                        }
                                    </IonSelect>
                                </div>
                            </div>

                            <div className={"d-flex flex-column flex-md-row h-90"}>
                                <div className={"d-flex flex-md-column overflow-auto overflow-md-unset px-2"}
                                    style={{ minHeight: "100px" }}>
                                    <div
                                        className={"border border-light rounded px-3 py-3 mb-3 me-2" + (activeStep > 0 ? " bg-primary" : " bg-light")}>
                                        <img style={{ minWidth: 50 }} src={"/assets/img/icons/home-tracker/step-1.png"} width={50} height={50} alt=""
                                            className={"mx-auto d-block"} />
                                    </div>
                                    <div
                                        className={"border border-light rounded px-3 py-3 mb-3 me-2" + (activeStep > 1 ? " bg-primary" : " bg-light")}>
                                        <img style={{ minWidth: 50 }} src={"/assets/img/icons/home-tracker/step-2.png"} width={50} height={50} alt=""
                                            className={"mx-auto d-block"} />
                                    </div>
                                    <div
                                        className={"border border-light rounded px-3 py-3 mb-3 me-2" + (activeStep > 2 ? " bg-primary" : " bg-light")}>
                                        <img style={{ minWidth: 50 }} src={"/assets/img/icons/home-tracker/step-3.png"} width={50} height={50} alt=""
                                            className={"mx-auto d-block"} />
                                    </div>
                                    <div
                                        className={"border border-light rounded px-3 py-3 mb-3 me-2" + (activeStep > 3 ? " bg-primary" : " bg-light")}>
                                        <img style={{ minWidth: 50 }} src={"/assets/img/icons/home-tracker/step-4.png"} width={50} height={50} alt=""
                                            className={"mx-auto d-block"} />
                                    </div>
                                    <div
                                        className={"border border-light rounded px-3 py-3 mb-3 me-2" + (activeStep > 4 ? " bg-primary" : " bg-light")}>
                                        <img style={{ minWidth: 50 }} src={"/assets/img/icons/documenti/fatture.png"} width={50} height={50} alt=""
                                            className={"mx-auto d-block"} />
                                    </div>
                                    <div
                                        className={"border border-light rounded px-3 py-3 mb-3 me-2" + (activeStep > 5 ? " bg-primary" : " bg-light")}>
                                        <img style={{ minWidth: 50 }} src={"/assets/img/icons/home-tracker/step-6.png"} width={50} height={50} alt=""
                                            className={"mx-auto d-block"} />
                                    </div>
                                </div>
                                <div
                                    className={"position-relative w-100 h-100" + (activeStep === 6 ? " d-flex flex-column justify-content-center align-items-center pt-4" : "")}>
                                    {
                                        activeStep === 6 ?

                                            <>
                                                <div
                                                    className={"border border-primary rounded-circle p-4 bg-primary mx-auto d-inline-block"}>
                                                    <img src={"/assets/img/icons/home-tracker/step-6.png"} width={150}
                                                        height={150}
                                                        alt=""
                                                        className={"mx-auto d-block"} />
                                                </div>
                                                <div className={"border p-3 mt-3 rounded h4 mb-5"}>Benvenuto nella sua nuova
                                                    casa!
                                                </div>
                                            </>
                                            :
                                            <img className={"w-100 contain"} alt=""
                                                src={"/assets/img/tracking-step_" + (activeStep) + ".png"} />
                                    }
                                    {
                                        activeStep < 6 &&
                                        <>
                                            <div className={"info-box"}>
                                                <div className={"bg-white border rounded-lg p-3"} >
                                                    {
                                                        (stepsInfos[activeStep - 1] && stepsInfos[activeStep - 1].title) &&
                                                        <div className={"font-weight-bold text-uppercase"}>
                                                            {stepsInfos[activeStep - 1].title}
                                                        </div>
                                                    }
                                                    {
                                                        (stepsInfos[activeStep - 1] && stepsInfos[activeStep - 1].description) &&
                                                        <div className={"font-italic"}>
                                                            {stepsInfos[activeStep - 1].description}
                                                        </div>
                                                    }
                                                </div>
                                                <div className={"mt-2 text-medium btn btn-light border mb-5 mx-3"}
                                                    onClick={() => setShowFaqModal(true)}>
                                                    <IonIcon icon={informationCircle}
                                                        className={"text-primary mr-2"} />
                                                    <span>Hai qualche domanda su questa fase?</span>
                                                </div>
                                            </div>

                                            <IonModal isOpen={showFaqModal} onDidDismiss={() => setShowFaqModal(false)}>
                                                <IonHeader>
                                                    <IonToolbar>
                                                        <IonButtons slot="end">
                                                            <IonButton onClick={() => setShowFaqModal(false)}>
                                                                Chiudi
                                                            </IonButton>
                                                        </IonButtons>
                                                        <IonTitle>FAQ</IonTitle>
                                                    </IonToolbar>
                                                </IonHeader>
                                                <IonContent>
                                                    {
                                                        activeFaqs ?
                                                            <Faq faqs={activeFaqs} />
                                                            :
                                                            <IonSpinner />
                                                    }
                                                </IonContent>
                                            </IonModal>
                                        </>
                                    }
                                </div>
                            </div>
                        </>
                        :
                        <div class="ion-text-center ion-padding-top">Nessuno step selezionato.</div>
                }
            </IonContent>
        </IonPage >
    )
}
