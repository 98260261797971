import { IonContent, IonPage } from "@ionic/react";
import { useEffect, useState } from "react";
import { retrieveCapitolati } from "../../../api";
import { Header } from "../../../components/Header";
import LocationCard from "../../../components/location-card";

export default function Capitolati({ match }) {

    const residenceId = match.params.residenceId;
    const [capitolati, setCapitolati] = useState(null)

    useEffect(() => {
        const retrieveAllResidence = async () => {
            try {
                const { data: capitolati } = await retrieveCapitolati(residenceId);
                capitolati.rows && setCapitolati(capitolati.rows);
            } catch (err) {
                setCapitolati([]);
                console.log(err);
            }
        };

        retrieveAllResidence();

    }, []);



    return <IonPage>
        <Header />
        <IonContent color="light">

            <div className="p-3">

                <h2 className="mb-4">Il tuo capitolato finiture</h2>

                <div className="dashboard-grid">
                    {
                        !!capitolati?.length && capitolati.map(({ id, name, image }, i) =>
                            <LocationCard isCapitolato name={name} image={image} catalogues={[{
                                id,
                                name,
                                customPdfUrl: "/mcd_residence_api/private/catalogue/" + id
                            }]} />
                        )
                    }
                </div>
            </div>
        </IonContent>
    </IonPage>
}
